import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { ReactComponent as CloseWhiteIcon } from '../../images/icon/cross-white.svg';
import { useStyles } from '../Templates/CommonStyle';
import { ProgressDataProps } from '../../hooks/useBulkActionSetup';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  open: boolean;
  onClose: () => void;
  progressData: ProgressDataProps;
  totalGroup: number;
  progressErrorMessage: string;
  processStartTime: string;
  timeDifference: number;
  // isCallingApi: boolean;
};

const BulkActionProgressDialog = React.memo<Props>((props): JSX.Element => {
  const {
    open,
    onClose,
    progressData,
    totalGroup,
    progressErrorMessage,
    processStartTime,
    timeDifference,
  } = props;
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();
  const [isErrorCard, setIsErrorCard] = useState(false);

  useEffect(() => {
    if (progressErrorMessage) {
      setIsErrorCard(true);
    }
  }, [progressErrorMessage]);

  return (
    <Dialog open={open} className={classes.bulkActionProgressDialog}>
      <Box sx={{ width: '100%' }}>
        <DialogTitle className="res-dialog-title">
          <Box className="res-dialog-title-text">
            {' '}
            {t('bulkActionProgress.heading')}
          </Box>
        </DialogTitle>
        <DialogContent
          className={`${classes.bulkActionProgressDialogContent} res-dialog-text`}
        >
          <Box sx={{ border: '1px solid #D9D9D9', padding: '6px' }}>
            {isErrorCard && (
              <Box className={classes.errorCardBulkAction}>
                <Box className={classes.errorCardBulkActionBox}>
                  <ErrorSign />
                  <Typography className={classes.errorCardBulkActionBoxText}>
                    {progressErrorMessage}
                  </Typography>
                </Box>

                <IconButton
                  sx={{ m: '0', height: '28px' }}
                  onClick={() => setIsErrorCard(false)}
                >
                  <CloseWhiteIcon />
                </IconButton>
              </Box>
            )}
            <Box sx={{ padding: '14px' }}>
              <Typography
                className={classes.bulkActionProgressDialogUpdatingText}
              >
                {t('bulkActionProgress.updating', {
                  updatedgroup: progressData.groupCount,
                  totalgroups: totalGroup,
                })}
              </Typography>
              <Box style={{ position: 'relative', width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={progressData.percentage}
                  sx={{
                    height: '40px',
                    border: '1px solid #D9D9D9',
                    backgroundColor: '#F8F8F8',
                    '.MuiLinearProgress-bar': {
                      backgroundColor: progressErrorMessage
                        ? '#E1341E4D'
                        : '#009DE1',
                    },
                  }}
                />
                <span
                  style={{
                    position: 'absolute',
                    left: `${progressData.percentage - 6}%`,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#FFFFFF',
                  }}
                >
                  {progressData.percentage}%
                </span>
              </Box>
              <Typography
                sx={{ fontSize: '14px', marginTop: '10px', lineHeight: '19px' }}
              >
                {t('bulkActionProgress.time', {
                  intialTime: processStartTime,
                  sec: timeDifference,
                  device: progressData.deviceCount,
                })}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        {progressErrorMessage && (
          <DialogActions
            sx={{ marginTop: '20px !important' }}
            className={`${classes.dialogActionsCommon} res-dialog-btns`}
          >
            <Button
              className={classes.btnAction}
              onClick={() => {
                setIsErrorCard(false);
                onClose();
              }}
              disableRipple
              sx={{ minWidth: '82px', padding: '10px 29.5px !important' }}
            >
              {t('bulkActionProgress.closeButton')}
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
});

export default BulkActionProgressDialog;
