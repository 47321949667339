import { useForm } from 'react-hook-form';
import React, {
  Dispatch,
  useRef,
  useState,
  SetStateAction,
  useEffect,
} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ArrowRightIcon } from '../../images/icon/right-arrow.svg';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import RemoteDeviceSetupContainer from './RemoteDeviceSetupContainer';
import BulkActionOverwriteDialog from '../Atoms/BulkActionOverwriteDialog';
import BulkActionProgressDialog from '../Atoms/BulkActionProgressDialog';
import useBulkActionSetup from '../../hooks/useBulkActionSetup';
import { BulkActionSetupApiRequestParam } from '../../types/apis/remoteFeatureSetupApi.d';
import { useAppDispatch } from '../../state/store';
import { remoteSetupErrorSlice } from '../../state/slice/remoteSetupErrorSlice';
import ApplyButton from '../Atoms/ApplyButton';
import { WifiSettingsType } from '../../types/remote/remoteDeviceSetup.d';
import { sendGAEvent } from '../../hooks/useGAEvent';

type Props = {
  onClickBackButton: () => void;
  selectedGroupIds: (number | string)[];
  selectedDeviceCount: number;
  setIsBulkActionSuccess: Dispatch<SetStateAction<boolean>>;
  isBulkActionSuccess: boolean;
};

const GroupListBulkActionsSetAction = React.memo<Props>(
  (props): JSX.Element => {
    const [applyButtonDisable, setApplyButtonDisable] = useState<boolean>(true);
    const [openBulkActionOverwriteDialog, setOpenBulkActionOverwriteDialog] =
      useState(false);

    // common style
    const classes = useStyles();
    const { t } = useSwitchLocaleLanguage();
    const myComponentRef = useRef<HTMLDivElement>(null);
    const { setIsBulkActionSuccess, isBulkActionSuccess } = props;
    const dispatch = useAppDispatch();

    const {
      onClickBulkActionOverwrite,
      setOpenBulkProgressDialog,
      setprogressEventMessage,
      setResponseMessage,
      setProgressErrorMessage,
      openBulkProgressDialog,
      progressErrorMessage,
      progressData,
      processStartTime,
      timeDifference,
      setProgressData,
      setTimeDifference,
    } = useBulkActionSetup({ setIsBulkActionSuccess });

    const { control, handleSubmit, setValue } = useForm({
      mode: 'onChange',
      defaultValues: {
        pinCodeEnable: false,
        groupPin: null,
        sleepTimeEnable: false,
        sleepTime: 0,
        dataDeleteTimeEnable: false,
        dataDeleteTime: 0,
        remoteSettingModeEnable: false,
        remoteSettingModeId: 1,
        saveInLocalFlag: false,
        advanceOpt: false,
        saveWifiSettings: false,
        wifiSettings: [],
      },
    });

    const handleScroll = () => {
      setTimeout(() => {
        if (myComponentRef.current) {
          myComponentRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
          });
        }
      }, 0);
    };

    const handleApplyButtonDisable = () => {
      /* eslint-disable */
      if (
        !control._formValues.pinCodeEnable &&
        !control._formValues.sleepTimeEnable &&
        !control._formValues.dataDeleteTimeEnable &&
        !control._formValues.remoteSettingModeEnable &&
        !control._formValues.saveWifiSettings
      ) {
        setApplyButtonDisable(true);
      } else {
        setApplyButtonDisable(false);
      }
      /* eslint-enable */
    };

    const submitRemoteDeviceSetup = () => {
      sendGAEvent('press_btn', 'btn_name', 'apply');
      setOpenBulkActionOverwriteDialog(true);
    };

    const onCloseBulkActionOverwriteDialog = () => {
      setOpenBulkActionOverwriteDialog(false);
    };
    const onCloseBulkProgressDialog = () => {
      setOpenBulkProgressDialog(false);
      setTimeout(() => {
        setprogressEventMessage('');
        setResponseMessage('');
        setProgressErrorMessage('');
        // eslint-disable-next-line
        setProgressData({
          percentage: 0,
          groupCount: 0,
          deviceCount: 0,
        });
        // eslint-disable-next-line
        setTimeDifference(0);
      }, 1000);
      
    };

    const onClickOverwriteBtn = () => {
      sendGAEvent('press_btn', 'btn_name', 'overwrite');
      /* eslint-disable */
      const payload: BulkActionSetupApiRequestParam = {
        groupIds: props.selectedGroupIds,
        saveRemoteSettingModeId: control._formValues.remoteSettingModeEnable,
        remoteSettingModeId: control._formValues.remoteSettingModeId,
        commonSettings: {
          saveGroupPin: control._formValues.pinCodeEnable,
          groupPin:
            control._formValues.groupPin?.length > 0 &&
            control._formValues.pinCodeEnable
              ? control._formValues.groupPin
              : null,
          saveSleepTime: control._formValues.sleepTimeEnable,
          sleepTime: control._formValues.sleepTime,
          saveDataDeleteTime: control._formValues.dataDeleteTimeEnable,
          dataDeleteTime: control._formValues.dataDeleteTime,
          saveInLocalFlag: control._formValues.saveInLocalFlag,
          saveWifiSettings: control._formValues.saveWifiSettings,
          wifiSettings: control._formValues.saveWifiSettings
            ? control._formValues
                .wifiSettings!.filter(
                  (item: WifiSettingsType) => item.isDelete === false,
                )
                ?.map(
                  ({
                    id,
                    isDelete,
                    advanceOpt,
                    ...rest
                  }: {
                    settingType: string;
                    [key: string]: any;
                  }) => rest,
                )
            : [],
        },
      };

      /* eslint-enable */
      onClickBulkActionOverwrite(payload);
      onCloseBulkActionOverwriteDialog();
      setOpenBulkProgressDialog(true);
    };

    useEffect(() => {
      if (isBulkActionSuccess) {
        onCloseBulkProgressDialog();

        setValue('pinCodeEnable', false);
        setValue('groupPin', null);
        setValue('sleepTimeEnable', false);
        setValue('sleepTime', 0);
        setValue('dataDeleteTimeEnable', false);
        setValue('dataDeleteTime', 0);
        setValue('remoteSettingModeEnable', false);
        setValue('remoteSettingModeId', 1);
        setValue('saveInLocalFlag', false);
        setValue('advanceOpt', false);
        setValue('saveWifiSettings', false);
        setValue('wifiSettings', []);

        setApplyButtonDisable(true);
        dispatch(remoteSetupErrorSlice.actions.setResetState(true));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBulkActionSuccess]);

    return (
      <Box ref={myComponentRef}>
        <Box sx={{ marginBottom: '30px' }}>
          <Box className={classes.bulkActionNavCard}>
            <Typography
              className={classes.bulkActionNavTextBlue}
              onClick={props.onClickBackButton}
            >
              {t('bulkActionPage.selectTargets')}
            </Typography>
            <ArrowRightIcon className={classes.arrowExpandIconActive} />
            <Typography className={classes.bulkActionNavTextBold}>
              {t('bulkActionPage.setActions')}
            </Typography>
          </Box>
          <Typography className={classes.customInputLabel}>
            {t('bulkActionPage.setActionsTextOne')}
            <br />
            {t('bulkActionPage.setActionsTextTwo')}
          </Typography>
        </Box>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(submitRemoteDeviceSetup)}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ display: 'flex', marginRight: '16px', gap: '4px' }}>
              <Typography className={classes.bulkActionCountShow}>
                {props.selectedGroupIds?.length}
              </Typography>
              <Typography className={classes.bulkActionCountShowText}>
                {t('bulkActionPage.groups')}
              </Typography>
              <Typography className={classes.bulkActionCountShow}>
                {props.selectedDeviceCount}
              </Typography>
              <Typography className={classes.bulkActionCountShowText}>
                {t('bulkActionPage.devicesSelected')}
              </Typography>
            </Box>

            <Button
              id="backButton"
              variant="outlined"
              className={classes.bulkActionOutlineBtn}
              onClick={props.onClickBackButton}
            >
              {t('bulkActionPage.back')}
            </Button>
            <ApplyButton
              id="applyButton"
              className={classes.bulkActionContainedBtn}
              applyButtonDisable={applyButtonDisable}
              text={t('bulkActionPage.apply')}
              onClick={submitRemoteDeviceSetup}
            />
          </Box>
          {/* eslint-disable */}
          <RemoteDeviceSetupContainer
            handleScroll={handleScroll}
            control={control}
            setValue={setValue}
            handleApplyButtonDisable={handleApplyButtonDisable}
          />
        </form>
        <BulkActionOverwriteDialog
          open={openBulkActionOverwriteDialog}
          onClose={onCloseBulkActionOverwriteDialog}
          onClickOverwriteBtn={onClickOverwriteBtn}
          isCallingApi={false}
        />
        <BulkActionProgressDialog
          open={openBulkProgressDialog}
          onClose={onCloseBulkProgressDialog}
          progressErrorMessage={progressErrorMessage}
          progressData={progressData}
          totalGroup={props.selectedGroupIds?.length}
          processStartTime={processStartTime}
          timeDifference={timeDifference}
        />
      </Box>
    );
  },
);
export default GroupListBulkActionsSetAction;
