import React from 'react';
import SimpleBarReact from 'simplebar-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Box, Skeleton, Typography, IconButton } from '@mui/material';
import { useStyles } from './DialogStyle';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import useRemoteFeatureLimitList from '../../hooks/useRemoteFeatureLimitList';
import useRemoteFeatureSetupInfo from '../../hooks/useRemoteFeatureSetupInfo';

import { REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE } from '../../apis/callRemoteFeatureSetupInfoApi';
import NewWifi from '../Modecules/NewWifi';
import { sendGAEvent } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';
import { FEATURE_PERMISSION } from '../../constants/constants';

dayjs.extend(utc);

type Props = {
  open: boolean;
  onClose: () => void;
};

const RemoteDeviceSetupDialog = React.memo<Props>((props): JSX.Element => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { accountInfo } = useAccountInfo();

  /*eslint-disable*/
  const {
    featureSetupData,
    featureSetupInfoErrorType,
    t,
    // onClickGroupList,
    isCallingRemoteFeatureSetupInfoApi,
  } = useRemoteFeatureSetupInfo();

  const { featureLimitListData } = useRemoteFeatureLimitList();

  return (
    <Dialog
      open={open}
      className={`${classes.remotDeviceSetupDialog} ${'remove-device-setup'}`}
    >
      <SimpleBarReact
        // autoHide={false}
        className={`${
          classes.remotDeviceSetupDialogScroll
        } ${'remove-device-setup-scroll'}`}
      >
        {featureSetupInfoErrorType ===
          REMOTE_FEATURE_SETUP_INFO_API_RESULT_CODE.WARN_INVALID_AUTH &&
          onClose()}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '14px',
          }}
        >
          <Typography className={classes.remotDeviceSetupTitle}>
            {t('remoteDevice.label.title')}
          </Typography>
          <IconButton
            sx={{ p: '7px', mr: '-7px' }}
            onClick={() => {
              onClose();
              // GA tag
              sendGAEvent('press_btn', 'btn_name', 'exit');
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontWeight: 'light',
            lineHeight: '24px',
            fontSize: '13px',
            height: '19px',
            color: '#939598',
            display: 'flex',
          }}
        >
          {t('remoteDevice.label.lastUpdate')}{' '}
          {isCallingRemoteFeatureSetupInfoApi ? (
            <Skeleton style={{ width: '90px', margin: '5px 0px 0px 5px' }} />
          ) : featureSetupData?.updateDt ? (
            dayjs(featureSetupData?.updateDt).format('YYYY/MM/DD HH:mm')
          ) : (
            t('remoteDevice.label.notYet')
          )}
        </Typography>
        {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION
            .VIEWDETAILS,
        ) && (
          <Typography
            sx={{
              fontWeight: 'bold',
              lineHeight: '20px',
              fontSize: '14px',
              color: '#313133',
              margin: '20px 0px 12px',
              letterSpacing: '0.09px',
            }}
          >
            {isCallingRemoteFeatureSetupInfoApi ? (
              <Skeleton
                style={{
                  width: '90px',
                  height: '25px',
                  // margin: '5px 0px 0px 0px',
                }}
              />
            ) : (
              t('remoteDevice.label.dataProtection')
            )}
          </Typography>
        )}
        {isCallingRemoteFeatureSetupInfoApi ? (
          <>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            ></Box>

            {/* remote input field */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                columnGap: '30px',
                rowGap: '20px',
                marginBottom: '20px',
              }}
            >
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Skeleton sx={{ width: '60px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '100px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '200px', height: '20px' }} />
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Box>
            </Box>

            <Box className={`${classes.noWifiBox} ${'no-wifi-box'}`}>
              <Typography
                sx={{
                  fontWeight: 'medium',
                  lineHeight: '24px',
                  fontSize: '16px',
                  color: '#313133',
                  marginBottom: '16px',
                }}
              >
                <Skeleton sx={{ width: '200px', height: '38px' }} />
              </Typography>
              <Button
                aria-label="Update"
                disabled
                sx={{
                  p: '9px 16px',
                  height: '38px',
                  background: 'grey',
                  borderRadius: '6px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#007EB4',
                  },
                  '&.Mui-disabled': {
                    color: 'white',
                    background: '#009DE1',
                    opacity: 0.5,
                  },
                }}
                disableRipple
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                  }}
                >
                  {/* <Skeleton
                  style={{ width: '90px', margin: '5px 0px 0px 5px' }}
                /> */}
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <NewWifi
            // @ts-ignore:next-line
            formField={featureSetupData}
            onClose={onClose}
            featureLimitListData={featureLimitListData!}
          />
        )}

        {/* add new wifi settings end  */}
      </SimpleBarReact>
    </Dialog>
  );
});

export default RemoteDeviceSetupDialog;
