import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { featureLimitList } from '../utils/remote/featureLimitList';
// import useAccessKey from './useAccessKey';
import type { remoteFeatureLimitList } from '../types/remote/featureLimitList';
import {
  REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE,
  REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE,
} from '../apis/callRemoteFeatureLimitListApi';
import { isRemoteFeatureLimitListErrorType } from '../types/apis/apiErrorTypeGuard';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

type IDParams = {
  groupId: string;
};

const useRemoteFeatureLimitList = () => {
  const { groupId } = useParams<IDParams>();
  const { t } = useSwitchLocaleLanguage();

  const [
    isCallingRemoteFeatureLimitListApi,
    setIsCallingRemoteFeatureLimitListApi,
  ] = useState<boolean>(false);
  const featureLimitListData = useRef<remoteFeatureLimitList[] | null>([]);
  const [featureLimitListErrorType, setFeatureLimitListErrorType] =
    useState<REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE | null>(null);
  // const { accessKey } = useAccessKey();

  const onClickRemoteFeatureLimitList = (): void => {
    setIsCallingRemoteFeatureLimitListApi(true);
    featureLimitList(groupId ? +groupId : null)
      .then((result) => {
        switch (result.resultCode) {
          case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.OK: {
            // Success Message
            featureLimitListData.current = result.modeList;

            break;
          }
          case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
          case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST: {
            // Set an error code in setUserDeleteERrortype for logout dialog displaylogout dialog display
            setIsCallingRemoteFeatureLimitListApi(false);
            throw result.resultCode;
          }
          default:
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureLimitListApi(false);
      })
      .catch((error: REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE | unknown) => {
        if (isRemoteFeatureLimitListErrorType(error)) {
          setFeatureLimitListErrorType(error);
        } else {
          setFeatureLimitListErrorType(
            REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureLimitListApi(false);
      });
  };

  const errorMessageRemoteFeatureLimitList = useMemo((): string => {
    if (!featureLimitListErrorType) {
      return '';
    }
    switch (featureLimitListErrorType) {
      case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        return t('remoteDevice.message.noAccess');
      case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        return t('remoteDevice.message.invalidURL');
      default:
        return t('common.error.serverErr');
    }
  }, [featureLimitListErrorType, t]);

  /*eslint-disable*/
  useEffect(() => {
    onClickRemoteFeatureLimitList();
  }, []);

  return {
    errorMessageRemoteFeatureLimitList,
    isCallingRemoteFeatureLimitListApi,
    featureLimitListData: featureLimitListData.current,
    featureLimitListErrorType,
    onClickRemoteFeatureLimitList,
  };
};

export default useRemoteFeatureLimitList;
