/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import type { LoginInput } from '../../types/inputs/loginInput';
import useLogin from '../../hooks/useLogin';
import '../../locales/locales';
import { useStyles } from '../Templates/CommonStyle';
import { EMAIL_CHECK_PATTERN } from '../../constants/constants';
import Loader from '../Atoms/Loader';
// icons
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';

/**
 * ログイン画面コンポーネント
 * 画面内に「エラーメッセージコンポーネント」を表示
 *
 * @returns
 */
const Login = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const inputRef = React.useRef<any>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  // ログイン処理hooks
  const {
    onClickLogin,
    t,
    isCallingApi,
    onClickPasswordReminder,
    // onClickRegisterBtn,
  } = useLogin();

  // バリデーションフォーム作成
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<LoginInput>({
    criteriaMode: 'all', // 全てのエラーを返す
    shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
  });

  /*eslint-disable*/
  React.useEffect(() => {
    // Moving cursor to the end
    inputRef.current?.focus();
  }, [showPassword]);

  // Toggle Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);

    if (showPassword === false) {
      // GA tag
      sendGAEvent('press_btn', 'btn_name', 'display_icon');
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  // remove error on Click Away
  const handleClickAwayEmailField = () => {
    clearErrors('email');
  };

  const handleClickAwayPasswordField = () => {
    clearErrors('password');
  };

  const validateEmail = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('common.validationError.email.maxLength');
    }

    return true;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      <Box className={classes.loginCard}>
        <Typography className={classes.loginCardTitle}>
          {t('login.welcomeMessage')}
        </Typography>
        <form onSubmit={handleSubmit(onClickLogin)}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.loginCardLevel}>
              {t('login.label.email')}
            </Typography>
            <Typography className={classes.loginCardLevelMarker}>*</Typography>
          </Box>
          <ClickAwayListener onClickAway={handleClickAwayEmailField}>
            <Box sx={{ position: 'relative' }}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  validate: validateEmail,
                  pattern: EMAIL_CHECK_PATTERN,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    value={field.value || ''}
                    fullWidth
                    margin="normal"
                    placeholder={t('login.placeholder.email')}
                    error={Boolean(errors.email)}
                    inputProps={{ maxLength: 257 }}
                    onFocus={() => {
                      //GA tag
                      sendGAEvent('edit_text_char', 'txt_name', 'input_email');
                    }}
                    autoFocus
                    sx={{
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor:
                          errors.email?.types?.required ||
                          errors.email?.types?.pattern ||
                          errors.email?.types?.validate
                            ? '#E1341E !important'
                            : '#939598 !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '6px',
                        '&.Mui-focused fieldset': {
                          border:
                            errors.email?.types?.required ||
                            errors.email?.types?.pattern ||
                            errors.email?.types?.validate
                              ? '1px solid #E1341E !important'
                              : '1px solid #939598 !important',
                        },
                      },
                    }}
                    className={classes.loginFormInput}
                  />
                )}
              />
              {(errors.email?.types?.required ||
                errors.email?.types?.pattern ||
                errors.email?.types?.validate) && (
                <Box className={classes.errorCard}>
                  <p style={{ padding: '0', margin: '0' }}>
                    {(errors.email?.types?.required &&
                      t('common.validationError.email.empty')) ||
                      (errors.email?.types?.validate &&
                        t('common.validationError.email.maxLength')) ||
                      (errors.email?.types?.pattern &&
                        t('common.validationError.email.invalid'))}
                  </p>
                </Box>
              )}
            </Box>
          </ClickAwayListener>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.loginCardLevel}>
              {t('login.label.password')}
            </Typography>
            <Typography className={classes.loginCardLevelMarker}>*</Typography>
          </Box>
          <ClickAwayListener onClickAway={handleClickAwayPasswordField}>
            <Box sx={{ position: 'relative' }}>
              <Controller
                control={control}
                name="password"
                rules={{
                  required: true,
                  minLength: 8,
                  maxLength: 16,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onFocus={() => {
                      //GA tag
                      sendGAEvent('edit_text_pass', 'txt_name', 'passsword');
                    }}
                    // sx={{ position: 'relative' }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disableRipple
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={field.value || ''}
                    fullWidth
                    margin="normal"
                    placeholder={t('login.placeholder.password')}
                    autoComplete="off"
                    error={Boolean(errors.password)}
                    sx={{
                      'input::-ms-reveal,input::-ms-clear': {
                        display: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor:
                          errors.password?.types?.required ||
                          errors.password?.types?.minLength ||
                          errors.password?.types?.maxLength
                            ? '#E1341E !important'
                            : '#939598 !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '6px',
                        '&.Mui-focused fieldset': {
                          border:
                            errors.password?.types?.required ||
                            errors.password?.types?.minLength ||
                            errors.password?.types?.maxLength
                              ? '1px solid #E1341E !important'
                              : '1px solid #939598 !important',
                        },
                      },
                    }}
                    className={classes.loginFormInput}
                  />
                )}
              />
              {(errors.password?.types?.required ||
                errors.password?.types?.minLength ||
                errors.password?.types?.maxLength) && (
                <Box className={classes.errorCard}>
                  <p style={{ padding: '0', margin: '0' }}>
                    {(errors.password?.types?.required &&
                      t('common.validationError.password.empty')) ||
                      ((errors.password?.types?.minLength ||
                        errors.password?.types?.maxLength) &&
                        t('common.validationError.password.invalid'))}
                  </p>
                </Box>
              )}
            </Box>
          </ClickAwayListener>

          <div className={classes.forgotPassword}>
            <button
              type="button"
              onClick={() => {
                // GA tag
                sendGAEvent('press_btn_link', 'link_name', 'forgot_password');
                onClickPasswordReminder();
              }}
            >
              {t('login.label.passwordReminder')}
            </button>
          </div>
          <Button
            className={classes.submitButtonLogin}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={isCallingApi}
          >
            {isCallingApi ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {t('login.label.loginButton')}
                <Loader colorCode="#fff" />
              </Box>
            ) : (
              t('login.label.loginButton')
            )}
          </Button>
        </form>
        {/* <Box className={classes.linkBtnBox}>
          <Typography className={classes.loginCardLevel}>
            {t('login.noAccount')}
          </Typography>
          <Button
            variant="text"
            className={classes.btnLink}
            onClick={onClickRegisterBtn}
          >
            {t('login.register')}
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Login;
