import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
} from 'react-select';
import { Controller } from 'react-hook-form';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';

interface ICategory {
  value: number;
  label: string;
}

/*eslint-disable*/
interface CustomSelectProps {
  ControllerName: string;
  control: Control<FieldValues, any>;
  options: Array<ICategory> | undefined;
  isSearchable: boolean;
  className: string;
  classNamePrefix: string;
  styles: any;
  isDisabled: boolean;
  onChangeValue?: any;
}

const CommonSelect: React.FC<CustomSelectProps> = ({
  ControllerName,
  control,
  options,
  isSearchable,
  className,
  classNamePrefix,
  styles,
  isDisabled,
  onChangeValue,
}) => {
  const classes = useStyles();

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown className={classes.selectArrow} />
      </components.DropdownIndicator>
    );
  };

  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <>
        <components.Option {...props}>
          {props.children}
          {props.isSelected && <CheckIcon />}
        </components.Option>
      </>
    );
  };

  return (
    <Controller
      name={ControllerName}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <Select
          value={options?.find((c) => c.value === value)}
          name={name}
          // @ts-ignore:next-line
          onChange={(selectedOption: ICategory) => {
            onChange(selectedOption?.value);
            if (onChangeValue) {
              onChangeValue(selectedOption?.value);
            }
          }}
          options={options}
          isSearchable={isSearchable}
          components={{ DropdownIndicator, Option }}
          className={className}
          classNamePrefix={classNamePrefix}
          styles={styles}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};

export default CommonSelect;
