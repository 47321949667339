import callApiUtil from '../utils/callApiUtil';
import type { GroupListBulkActionApiResponse } from '../types/apis/groupListApi';
import { API_URL } from '../constants/constants';
import { TableDataBulkActionPayload } from './callGroupListApi';

const callBulkActionGroupListApi = (
  tableDataPayload: TableDataBulkActionPayload,
): Promise<GroupListBulkActionApiResponse> => {
  const {
    searchText,
    sortDirection,
    colName,
    rowLimitPerPage,
    currentPageNumber,
    selectedGroupIds,
  } = tableDataPayload;

  const sortD = sortDirection === 'none' ? '' : sortDirection;
  /* eslint-disable */

  const response = callApiUtil
    .get<GroupListBulkActionApiResponse>(
      `${
        API_URL.BULK_SET_UP_GROUP_LIST_API
      }?searchText=${searchText}&sortDirection=${sortD.toLowerCase()}&colName=${colName}&rowLimitPerPage=${rowLimitPerPage}&currentPageNumber=${
        currentPageNumber - 1
      }&selectedGroupIds=${selectedGroupIds}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return { message: error.message };
    });

  return response;
};

export default callBulkActionGroupListApi;
