/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import CommonSelect from '../Atoms/CommonSelect';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as DeleteIcon } from '../../images/icon/delete.svg';
import WifiSettingsAdvanceOpt from './WifiSettingsAdvanceOpt';
import { createValidationRules } from '../../utils/remote/inputRules';
import createDropdownValues from '../../utils/remote/dropdownText';
import { WifiSettingsType } from '../../types/remote/remoteDeviceSetup.d';
import TextFieldInput from '../Atoms/TextFieldInput';
import DeleteDialog from '../Atoms/DeleteDialog';

type IProps = {
  control: any;
  item: WifiSettingsType;
  index: number;
  setValue: any;
  deleteFunction: (index: number, id: number) => void;
};

const WifiSettingsCard = ({
  control,
  item,
  index,
  setValue,
  deleteFunction,
}: IProps) => {
  const [deleteDialogOpen, setDeleteDialog] = useState<boolean>(false);

  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();

  const validationRules = createValidationRules(t);
  const dropDownValues = createDropdownValues(t);

  const saveWifiSettings = useSelector(
    (state: RootState) => state.remoteSetupError.saveWifiSettings,
  );

  const customSelectStyles = {
    control: (provided: any, state: { isFocused: any, isDisabled: any }) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#F8F8F8' : '#fff',
      minHeight: '34px',
      height: '34px',
      borderRadius: '6px',
      boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
      border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        borderColor: '#939598',
      },
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
      marginTop: '-1px',
    }),

    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: '34px',
      marginTop: '-1px',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '6px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      height: '33px',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      color: '#313133',
      ':active': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      },
      ':first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      ':last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
    }),
  };


  return (
    <Box
      className={classes.newWifiBox}
      // eslint-disable-next-line
      key={`${item.ssid} ${index}`}
      style={{
        width: '100%',
        padding: '8px 8px 15px 40px',
        backgroundColor: !saveWifiSettings ? '#F8F8F8' : 'transparent',
      }}
    >
      {/* delete button start */}
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        {/* <DeleteIcon
          className={classes.deleteIcon}
          onClick={() => {
            deleteFunction(index, item.id!);
          }}
        /> */}
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -9],
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                minWidth: '58px',
                height: '26px',
                textAlign: 'center',
                fontWeight: 300,
                fontSize: '12px',
              },
            },
          }}
          /*eslint-disable*/
          title={
            saveWifiSettings ? (t('remoteDevice.tooltip.delete') as string) : ''
          }
          placement="top"
          arrow
        >
          <DeleteIcon
            className={
              saveWifiSettings ? classes.deleteIcon : classes.deleteIconDisable
            }
            onClick={() => {
              if (saveWifiSettings) {
                setDeleteDialog(true);
              }
            }}
          />
        </Tooltip>
      </Box>
      {/* delete button end */}
      {/* fields start */}
      <Box
        className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
        sx={{ marginTop: '-12px', marginRight: '32px' }}
      >
        {/* ssid start */}
        <Box className={classes.inputBox}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.customInputLabel}>
              {t('remoteDevice.label.netSSD')}
            </Typography>
            <Typography className={classes.loginCardLevelMarker}>*</Typography>
          </Box>

          <TextFieldInput
            // eslint-disable-next-line
            control={control}
            classes={classes}
            // eslint-disable-next-line
            name={`wifiSettings.${item.id}.ssid`}
            rules={validationRules.ssidRules}
            maxLength={33}
            placeholder={t('remoteDevice.placeholder.netName')}
            // eslint-disable-next-line
            // isError={errors.wifiSettings?.[index]?.ssid}
            errorMargin={7}
            isDisable={!saveWifiSettings}
          />
        </Box>
        {/* ssid end */}

        {/* password start */}
        <Box className={classes.inputBox}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.customInputLabel}>
              {t('remoteDevice.label.password')}
            </Typography>
            <Typography className={classes.loginCardLevelMarker}>*</Typography>
          </Box>
          <TextFieldInput
            // eslint-disable-next-line
            control={control}
            classes={classes}
            // eslint-disable-next-line
            name={`wifiSettings.${item.id}.password`}
            rules={validationRules.passwordRules}
            maxLength={65}
            placeholder={t('remoteDevice.label.password')}
            isPassword
            // eslint-disable-next-line
            // isError={errors.wifiSettings?.[index]?.ssid}
            errorMargin={7}
            isDisable={!saveWifiSettings}
          />
        </Box>
        {/* password start */}

        {/* security start */}
        <Box className={classes.inputBox}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.customInputLabel}>
              {t('remoteDevice.label.security')}
            </Typography>
          </Box>
          <CommonSelect
            // eslint-disable-next-line
            ControllerName={`wifiSettings.${item.id}.securityType`}
            // eslint-disable-next-line
            control={control}
            options={dropDownValues.securityOpt}
            isSearchable={false}
            className={classes.reactSelect}
            classNamePrefix="react-select"
            styles={customSelectStyles}
            isDisabled={!saveWifiSettings}
            // onChangeValue={handleSecurityChange}
          />
        </Box>
        {/* security end */}
      </Box>
      {/* fields end */}
      <WifiSettingsAdvanceOpt
        // eslint-disable-next-line
        control={control}
        // eslint-disable-next-line
        id={item.id!}
        // eslint-disable-next-line
        setValue={setValue}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialog(false);
        }}
        onSubmit={() => {
          deleteFunction(index, item.id!);
        }}
        icon={'helllos'}
        title={t('remoteDevice.message.deleteConfirmation')}
        subTitle={t('groupDetail.label.deleteCaution')}
        btnBgColor="#E1341E"
        btnBgColorHover="#CC240F"
        btnTextNegative={t('groupDetail.label.cancelBtn')}
        btnTextPositive={t('groupDetail.label.deleteBtn')}
      />
    </Box>
  );
};

export default WifiSettingsCard;
