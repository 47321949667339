/* eslint-disable import/prefer-default-export */
import { NavigateFunction } from 'react-router-dom';
import callRemoteFeatureLimitList, {
  REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE,
  REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE,
} from '../../apis/callRemoteFeatureLimitListApi';
// import { PAGE_PATH_NAME } from '../../constants/constants';
import { isRemoteFeatureLimitListErrorType } from '../../types/apis/apiErrorTypeGuard';

import { RemoteFeatureLimitListApiResponse, RemoteFeatureLimitListResponse } from '../../types/apis/remoteFeatureLimitListApi.d';
// import { GroupDetailResult } from '../../types/Group/groupDetail.d';

export const featureLimitList = async (
  groupId: number | null,
): Promise<RemoteFeatureLimitListApiResponse> => {
  // User list acquisition API call
  try {
    const apiResponse: RemoteFeatureLimitListResponse =
      await callRemoteFeatureLimitList(groupId);
    switch (apiResponse.message) {
      // success
      case 'SUCCESSFULLY_FETCHED':
        return {
          resultCode: REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.OK,
          /*eslint-disable*/
          modeList: apiResponse.details.modeList,
        };
      // Failure
      case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY:
        throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NOTHING_KEY;
      case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_NO_AUTH_LIST;
      //   case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM:
      //     throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.WARN_INPUT_PARAM;
      case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NO_GROUP:
        throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.INFO_NO_GROUP;
        case REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.CORPORATION_ACCESS_DENIED:
          throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.CORPORATION_ACCESS_DENIED;
      default:
        throw REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error) {
    let resultCode: REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE =
      REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isRemoteFeatureLimitListErrorType(error)) {
      resultCode = error;
    }
    return {
      resultCode,
      modeList: [],
    };
  }
};

// .catch((error: GROUP_DETAIL_API_ERROR_TYPE | unknown) => {
//   if (isGroupDetailErrorType(error)) {
//     setGroupDetailErrorType(error);
//   } else {
//     setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
//   }

export const transToGroups = (navigate: NavigateFunction): void => {
  // Invalid groupId forward to Group list page
  navigate(`/groups`, { replace: true });
};
