/* eslint-disable */
const createDropdownValues = (t: any) => {
  return {
    proxyMenuOpt: [
      { value: 0, label: t('remoteDevice.proxyMenuOpt.none') },
      { value: 1, label: t('remoteDevice.proxyMenuOpt.manual') },
      { value: 2, label: t('remoteDevice.proxyMenuOpt.proxyAutoConfig') },
    ],
    ipAddressOpt: [
      { value: 0, label: t('remoteDevice.ipAddressOpt.dHCP') },
      { value: 1, label: t('remoteDevice.ipAddressOpt.static') },
    ],
    securityOpt: [
      { value: 0, label: t('remoteDevice.securityOpt.none') },
      { value: 1, label: t('remoteDevice.securityOpt.wep') },
      { value: 2, label: t('remoteDevice.securityOpt.wpa') },
    ],
    sleepTimeOpt: [
      { value: 0, label: t('remoteDevice.sleepTimeOpt.doNotSet') },
      { value: 15, label: t('remoteDevice.sleepTimeOpt.15Seconds') },
      { value: 30, label: t('remoteDevice.sleepTimeOpt.30Seconds') },
      { value: 60, label: t('remoteDevice.sleepTimeOpt.1Minute') },
      { value: 600, label: t('remoteDevice.sleepTimeOpt.10Minutes') },
    ],
    deleteScreenTime: [
      { value: 0, label: t('remoteDevice.deleteScreenTime.off') },
      { value: 10, label: t('remoteDevice.deleteScreenTime.10Minutes') },
      { value: 30, label: t('remoteDevice.deleteScreenTime.30Minutes') },
      { value: 60, label: t('remoteDevice.deleteScreenTime.1Hour') },
      { value: 720, label: t('remoteDevice.deleteScreenTime.12Hours') },
      { value: 1440, label: t('remoteDevice.deleteScreenTime.24Hours') },
    ],
  };
};

export default createDropdownValues;
/* eslint-enable */
