import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import type { RemoteFeatureLimitListResponse } from '../types/apis/remoteFeatureLimitListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

/**
 * User List Acquisition API Result Code
 */
export const REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE = {
  // OK
  OK: 'OK',
  // Failed to get authorization list
  WARN_NO_AUTH_LIST: 'WARN_NO_AUTH_LIST',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // No Access Key
  INFO_NOTHING_KEY: 'INFO_NOTHING_KEY',
  // No Group Access
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
  INFO_NO_GROUP:'INFO_NO_GROUP',
  CORPORATION_ACCESS_DENIED:'CORPORATION_ACCESS_DENIED'
} as const;

/**
 * User list acquisition error type
 */
export type REMOTE_FEATURE_LIMIT_LIST_API_ERROR_TYPE =
  (typeof REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE)[keyof typeof REMOTE_FEATURE_LIMIT_LIST_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callRemoteFeatureLimitListApi = (
  // accessKey: string,
  groupId: number | null,
): Promise<RemoteFeatureLimitListResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};
  const apiPath =
    groupId === null
      ? `${API_URL.REMOTE_FEATURE_LIST_GET_API}`
      : `${API_URL.REMOTE_FEATURE_LIST_GET_API}?groupInfoId=${groupId}`;

  // Send request (GET)
  const response = callApiUtil
    .get<RemoteFeatureLimitListResponse>(
      apiPath,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch(
      (error: AxiosError) =>
        error.response?.data as RemoteFeatureLimitListResponse,
    );

  return response;
};

export default callRemoteFeatureLimitListApi;
