import { useState } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import CommonSelect from '../Atoms/CommonSelect';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as CaretIcon } from '../../images/icon/caret-down.svg';
import { ReactComponent as CaretIconDown } from '../../images/icon/caret-down-original.svg';
import TextFieldInput from '../Atoms/TextFieldInput';
import { createValidationRules } from '../../utils/remote/inputRules';
import createDropdownValues from '../../utils/remote/dropdownText';
import { WifiSettingsType } from '../../types/remote/remoteDeviceSetup.d';
import { useAppDispatch } from '../../state/store';
import { remoteSetupErrorSlice } from '../../state/slice/remoteSetupErrorSlice';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';

type IProps = {
  control: Control<FieldValues, any>;
  id: number;
  setValue: any;
};

const WifiSettingsAdvanceOpt = ({ control, id, setValue }: IProps) => {
  // eslint-disable-next-line
  const values: WifiSettingsType = control._formValues.wifiSettings[id];

  const [advanceOpt, setAdvanceOpt] = useState<boolean>(values.advanceOpt);
  const [proxyType, setProxyType] = useState<number>(values.proxyType);
  const [ipSetting, setIpSetting] = useState<number>(values.wifiIPSettingType);

  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();
  const dispatch = useAppDispatch();

  const saveWifiSettings = useSelector(
    (state: RootState) => state.remoteSetupError.saveWifiSettings,
  );

  const validationRules = createValidationRules(t);
  const dropDownValues = createDropdownValues(t);

  const handleAdvanceOpt = () => {
    if (!saveWifiSettings && !advanceOpt) return;

    // eslint-disable-next-line
    setValue(`wifiSettings.${id}.advanceOpt`, !advanceOpt);
    setAdvanceOpt(!advanceOpt);
  };

  const changeForProxyTypeNone = () => {
    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.proxyHostName`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.proxyPort`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.bypassProxy`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.pacUrl`,
        ignore: true,
      }),
    );
  };

  const changeForProxyTypeManual = () => {
    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].proxyHostName === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.proxyHostName`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.proxyHostName`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].proxyPort === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.proxyPort`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.proxyPort`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].bypassProxy === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.bypassProxy`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.bypassProxy`,
          ignore: false,
        }),
      );
    }

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.pacUrl`,
        ignore: true,
      }),
    );
  };

  const changeForProxyTypeConfig = () => {
    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].pacUrl === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.pacUrl`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.pacUrl`,
          ignore: false,
        }),
      );
    }

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.proxyHostName`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.proxyPort`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.bypassProxy`,
        ignore: true,
      }),
    );
  };

  const changeForIpSettingDHCP = () => {
    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.ipAddress`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.gateWay`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.networkPrefixLength`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.dns1`,
        ignore: true,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.setIgnore({
        name: `wifiSettings.${id}.dns2`,
        ignore: true,
      }),
    );
  };

  const changeForIpSettingStatic = () => {
    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].ipAddress === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.ipAddress`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.ipAddress`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].gateWay === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.gateWay`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.gateWay`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].networkPrefixLength === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.networkPrefixLength`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.networkPrefixLength`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].networkPrefixLength === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.dns1`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.dns1`,
          ignore: false,
        }),
      );
    }

    // eslint-disable-next-line
    if (control._formValues.wifiSettings[id].networkPrefixLength === '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: `wifiSettings.${id}.dns2`,
          error: t('common.validationError.required'),
          ignore: false,
        }),
      );
    } else {
      dispatch(
        remoteSetupErrorSlice.actions.setIgnore({
          name: `wifiSettings.${id}.dns2`,
          ignore: false,
        }),
      );
    }
  };

  const handleProxyTypeChange = (value: number) => {
    // eslint-disable-next-line
    setValue(`wifiSettings.${id}.proxyType`, value);
    setProxyType(value);

    if (value === 0) {
      changeForProxyTypeNone();
    } else if (value === 1) {
      changeForProxyTypeManual();
    } else if (value === 2) {
      changeForProxyTypeConfig();
    }
  };

  const handleIpSettingChange = (value: number) => {
    // eslint-disable-next-line
    setValue(`wifiSettings.${id}.wifiIPSettingType`, value);
    setIpSetting(value);

    if (value === 0) {
      changeForIpSettingDHCP();
    } else if (value === 1) {
      changeForIpSettingStatic();
    }
  };

  return (
    <Box sx={{ marginRight: '32px' }}>
      {/* advance button start */}
      <Button
        variant="text"
        className={classes.advancedOptionsBtn}
        disableRipple
        onClick={() => {
          handleAdvanceOpt();
        }}
      >
        {advanceOpt && saveWifiSettings ? <CaretIconDown /> : <CaretIcon />}
        <Typography sx={{ ml: '7px' }}>
          {t('remoteDevice.button.advancedOpt')}
        </Typography>
      </Button>
      {/* advance button end */}

      {/* advance option start */}
      {advanceOpt && saveWifiSettings && (
        <Box sx={{ marginTop: '22px' }}>
          {/* proxy section start */}
          <Box
            className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
          >
            <Box>
              <Typography className={classes.customInputLabel}>
                {t('remoteDevice.label.proxy')}
              </Typography>
              <CommonSelect
                ControllerName={`wifiSettings.${id}.proxyType`}
                control={control}
                options={dropDownValues.proxyMenuOpt}
                isSearchable={false}
                className={classes.reactSelect}
                classNamePrefix="react-select"
                styles={customStylesMd}
                isDisabled={!saveWifiSettings}
                onChangeValue={handleProxyTypeChange}
              />
            </Box>
          </Box>

          {/* elements for proxy manual start */}
          {proxyType === 1 && (
            <Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#939598',
                  marginTop: '6px',
                }}
              >
                {t('remoteDevice.label.proxyUsage')}
              </Typography>

              <Box
                className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
                sx={{ marginTop: '20px' }}
              >
                {/* proxy host name start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.proxyHost')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.proxyHostName`}
                    rules={validationRules.proxyHostNameRules}
                    maxLength={257}
                    placeholder={t('remoteDevice.placeholder.proxy')}
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.proxyHostName}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* proxy host name end */}

                {/* proxy port start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.proxyPort')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.proxyPort`}
                    rules={validationRules.proxyPortRules}
                    minLength={1}
                    maxLength={5}
                    placeholder="8080"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.proxyPort}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* proxy port end */}

                {/* Bypass proxy start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.bypassProxy')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.bypassProxy`}
                    rules={validationRules.bypassProxyRules}
                    maxLength={257}
                    placeholder={t('remoteDevice.placeholder.myComp')}
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.bypassProxy}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* Bypass proxy end */}
              </Box>
            </Box>
          )}
          {/* elements for proxy manual end */}

          {/* element for proxy auto config start */}
          {proxyType === 2 && (
            <Box
              className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
              sx={{ marginTop: '20px' }}
            >
              {/* pac url start */}
              <Box className={classes.inputBox}>
                <Box sx={{ display: 'flex' }}>
                  <Typography className={classes.customInputLabel}>
                    {t('remoteDevice.label.pacURL')}
                  </Typography>
                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>
                <TextFieldInput
                  control={control}
                  classes={classes}
                  name={`wifiSettings.${id}.pacUrl`}
                  rules={validationRules.pacUrlRules}
                  maxLength={257}
                  placeholder={t('remoteDevice.placeholder.proxy')}
                  // eslint-disable-next-line
                  // isError={errors.wifiSettings?.[id]?.pacURL}
                  isDisable={!saveWifiSettings}
                  errorMargin={2}
                />
              </Box>
              {/* pac url end */}
            </Box>
          )}
          {/* element for proxy auto config end */}

          {/* proxy section end */}

          {/* ip setting section start */}
          <Box
            className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
            sx={{
              marginTop: '13px',
              marginBottom: ipSetting === 1 ? '0px' : '15px',
            }}
          >
            <Box>
              <Typography className={classes.customInputLabel}>
                {t('remoteDevice.label.ipSetting')}
              </Typography>
              <CommonSelect
                ControllerName={`wifiSettings.${id}.wifiIPSettingType`}
                control={control}
                options={dropDownValues.ipAddressOpt}
                isSearchable={false}
                className={classes.reactSelect}
                classNamePrefix="react-select"
                styles={customStylesMd}
                isDisabled={!saveWifiSettings}
                onChangeValue={handleIpSettingChange}
              />
            </Box>
          </Box>
          {ipSetting === 1 && (
            <Box>
              <Box
                className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
                sx={{ marginTop: '25px' }}
              >
                {/* IP Address Start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.ipAddress')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.ipAddress`}
                    rules={validationRules.ipAddressRules}
                    maxLength={16}
                    placeholder="192.168.1.128"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.ipAddress}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* IP Address end */}

                {/* Gateway Start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.gateWay')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.gateWay`}
                    rules={validationRules.gatewayRules}
                    maxLength={16}
                    placeholder="192.268.1.1"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.gateWay}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* Gateway end */}

                {/* Network prefix length Start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.prefixLen')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.networkPrefixLength`}
                    rules={validationRules.networkPrefixLengthRules}
                    maxLength={3}
                    placeholder="24"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.networkPrefixLength}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* Network prefix length end */}
              </Box>
              <Box
                className={`${classes.gridCol3} remove-device-setup-protection-input-form`}
                sx={{ marginTop: '20px', marginBottom: '15px' }}
              >
                {/* DNS1 Start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.dns1')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.dns1`}
                    rules={validationRules.dnsRules}
                    maxLength={16}
                    placeholder="8.8.8.8"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.dns1}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* DNS1 end */}

                {/* DNS2 Start */}
                <Box className={classes.inputBox}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography className={classes.customInputLabel}>
                      {t('remoteDevice.label.dns2')}
                    </Typography>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <TextFieldInput
                    control={control}
                    classes={classes}
                    name={`wifiSettings.${id}.dns2`}
                    rules={validationRules.dnsRules}
                    maxLength={16}
                    placeholder="8.8.4.4"
                    // eslint-disable-next-line
                    // isError={errors.wifiSettings?.[id]?.dns2}
                    isDisable={!saveWifiSettings}
                    errorMargin={2}
                  />
                </Box>
                {/* DNS2 end */}
              </Box>
            </Box>
          )}
          {/* ip setting section end */}
        </Box>
      )}
      {/* advance option end */}
    </Box>
  );
};

export default WifiSettingsAdvanceOpt;
