/* eslint-disable radix */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SimpleBarReact from 'simplebar-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import BulkActionTableHeaderContainer from '../Atoms/BulkActionTableHeaderContainer';
import BulkActionTableBodyContainer from '../Atoms/BulkActionTableBodyContainer';
import TableBodyContainerBulkActionSkeleton from '../Atoms/TableBodyContainerBulkActionSkeleton';
import {
  TableHeaderData,
  TableRowData,
  SortConfig,
} from '../../types/datatable/dataTable.d';
import styles from './DataTableContainer.module.scss';

import Loader from '../Atoms/Loader';

type Props = {
  headerColumns: TableHeaderData[];
  dataRows: TableRowData[];
  clickSortButton: (data: TableHeaderData) => void;
  sortConfig: SortConfig;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
  handleSelectClick: (id: string | number, device: number) => void;
  handleAllSelectClick: () => void;
  selectedGroupIds: (number | string)[];
  isAllSelected: boolean;
  isSomeSelected: boolean;
};

const BulkActionDataTableContainer = ({
  headerColumns,
  dataRows,
  clickSortButton,
  sortConfig,
  simpleBarRef,
  ...props
}: Props): JSX.Element => {
  const [columnsWidthSum, setColumnsWidthSum] = useState<number>(0);

  useEffect(() => {
    let allColumnsSum = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < headerColumns.length; i++) {
      let subStringWidth = parseInt(
        (headerColumns[i].width ?? '').split('px')[0],
      );
      allColumnsSum += Number(subStringWidth);
    }
    setColumnsWidthSum(allColumnsSum);
  }, [headerColumns]);

  // this states are for controlling previous data blink for few milisecond situation after skeleton loading finished
  const [dataShow, setDataShow] = useState(true);
  useEffect(() => {
    if (props.isCallingApi === true) {
      setDataShow(false);
    }
  }, [props.isCallingApi]);

  useEffect(() => {
    setDataShow(true);
  }, [dataRows]);
  // end

  return (
    <Box
      sx={{
        pointerEvents: props.isCallingApi && !dataShow ? 'none' : '',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display:
            props.isCallingApi && !dataShow && dataRows.length !== 0
              ? ''
              : 'none',
          width: '40px',
          height: '40px',
          position: 'absolute',
          top: 'calc(50% + 15px)',
          left: '50%',
          zIndex: '1',
        }}
      >
        <Loader colorCode="#939598" />
      </Box>
      <SimpleBarReact style={{ maxWidth: '100%' }}>
        <table
          className={`${
            styles['data-table']
          } table table-hover ${'bulk-action-group-list-responsive-table'}`}
        >
          <BulkActionTableHeaderContainer
            columns={headerColumns}
            clickSortButton={clickSortButton}
            sortConfig={sortConfig}
            tableSrcPage={props.tableSrcPage}
            isCallingApi={dataRows?.length === 0}
            columnsWidthSum={columnsWidthSum}
            handleAllSelectClick={props.handleAllSelectClick}
            isAllSelected={props.isAllSelected}
            isSomeSelected={props.isSomeSelected}
          />
          <tbody className={styles['data-table-body']}>
            {dataRows?.length === 0 && props.tableSrcPage === 'Group' && (
              <TableBodyContainerBulkActionSkeleton />
            )}

            <BulkActionTableBodyContainer
              simpleBarRef={simpleBarRef}
              rows={dataRows}
              columnLength={headerColumns.length}
              tableSrcPage={props.tableSrcPage}
              isCallingApi={props.isCallingApi}
              columnsWidthSum={columnsWidthSum}
              columns={headerColumns}
              dataShow={dataShow}
              handleSelectClick={props.handleSelectClick}
              selectedGroupIds={props.selectedGroupIds}
            />
          </tbody>
        </table>
      </SimpleBarReact>
    </Box>
  );
};

BulkActionDataTableContainer.defaultProps = {
  tableSrcPage: '',
  isCallingApi: false,
};

export default BulkActionDataTableContainer;
