import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WEB_STORAGE } from '../../constants/constants';

export interface RemoteSetupError {
  message: string;
  ignore: boolean;
}

export interface RemoteSetupErrorsState {
  savePinEnable: boolean;
  saveWifiSettings: boolean;
  resetState: boolean;
  errors: {
    [key: string]: RemoteSetupError;
  };
}

export const remoteSetupErrorsInitialState: RemoteSetupErrorsState = {
  savePinEnable: false,
  saveWifiSettings: false,
  resetState: false,
  errors: {},
};

export const remoteSetupErrorSlice = createSlice({
  name: WEB_STORAGE.REMOTE_SETUP_ERROR,
  initialState: remoteSetupErrorsInitialState,
  reducers: {
    addError: (
      state,
      action: PayloadAction<{ name: string; error: string; ignore: boolean }>,
    ) => {
      const { name, error, ignore } = action.payload;
      state.errors[name] = {
        message: error,
        ignore,
      };
    },
    setIgnore: (
      state,
      action: PayloadAction<{ name: string; ignore: boolean }>,
    ) => {
      const { name, ignore } = action.payload;
      if (state.errors[name]) {
        state.errors[name].ignore = ignore;
      }
    },
    removeError: (state, action: PayloadAction<string>) => {
      const nameToRemove = action.payload;
      delete state.errors[nameToRemove];
    },
    removeWifiSettingsError: (state, action: PayloadAction<string>) => {
      const index = action.payload;
      Object.keys(state.errors).forEach((key) => {
        if (key.includes(index)) {
          delete state.errors[key];
        }
      });
    },
    setSavePinEnable: (state, action: PayloadAction<boolean>) => {
      state.savePinEnable = action.payload;
    },
    setSaveWifiSettings: (state, action: PayloadAction<boolean>) => {
      state.saveWifiSettings = action.payload;
    },
    setResetState: (state, action: PayloadAction<boolean>) => {
      state.resetState = action.payload;
    },
  },
});

export const RemoteSetupErrorsActions = remoteSetupErrorSlice.actions;
export const RemoteSetupErrorsReducer = remoteSetupErrorSlice.reducer;
