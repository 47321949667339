import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as SortUpIcon } from '../../images/icon/sort-up.svg';
import { ReactComponent as SortDownIcon } from '../../images/icon/sort-down.svg';
import { ReactComponent as SortUpSelectedIcon } from '../../images/icon/sort-up-selected.svg';
import { ReactComponent as SortDownSelectedIcon } from '../../images/icon/sort-down-selected.svg';
import {
  TableHeaderData,
  SortConfig,
  DIRECTION_TYPE,
} from '../../types/datatable/dataTable.d';
import styles from './TableHeaderContainer.module.scss';
// import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import { ReactComponent as CheckboxMixed } from '../../images/icon/checkbox_mixed.svg';
import { useStyles } from '../Templates/CommonStyle';

type Props = {
  columns: TableHeaderData[];
  clickSortButton: (data: TableHeaderData) => void;
  sortConfig: SortConfig;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  columnsWidthSum?: number;
  isAllSelected: boolean;
  isSomeSelected: boolean;
  handleAllSelectClick: () => void;
};

const groupListWidth: { [key: string | number]: string | number } = {
  select: 6,
  groupName: 12,
  numberOfAccount: 16,
  numberOfDevice: 20,
  statusOfRemoteSettings: 23,
  modifiedDt: 15,
};

// export const deviceTableGAtag: {
//   [key: string]: string;
// } = {
//   groupName: 'list_group_name_sort',
//   imei: 'list_imei_sort',
//   deviceName: 'list_device_name_sort',
//   productType: 'list_device_model_sort',
//   mode: 'list_device_mode_sort',
//   status: 'list_device_status_sort',
//   simExp: 'list_sim_exp_sort',
//   lastUsed: 'list_last_used_sort',
// };

// const triggerGAEvent = (key: string, srcPage: string) => {
//   if (srcPage === 'Device') {
//     // GA tag
//     sendGAEvent('press_btn', 'btn_name', deviceTableGAtag[key]);
//   }
// };

/* eslint-disable */
const BulkActionTableHeader = React.memo<Props>((props): JSX.Element => {
  const { localeLanguage } = useLocale();
  const classes = useStyles();

  const isFirefox = /firefox|fxios/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <thead>
      <tr
        className={`${styles['table-header']} ${styles.bulkActionGroupListTable}`}
      >
        <th
          style={{
            width: `${groupListWidth.select}%`,
            textAlign: 'left',
          }}
        >
          <Checkbox
            onClick={() => {
              props.handleAllSelectClick();
            }}
            checked={props.isAllSelected && !props.isCallingApi}
            tabIndex={-1}
            icon={
              props.isSomeSelected ? (
                <CheckboxMixed
                  style={{
                    marginTop: '-2px',
                    marginRight: '-2px',
                  }}
                />
              ) : (
                <CheckBox className={classes.checkBoxIconStyle} />
              )
            }
            checkedIcon={<CheckedCheckBox />}
            className={classes.checkBoxStyle}
          />
        </th>
        {props.columns.map((data: TableHeaderData) => (
          <th
            scope="col"
            key={data.key}
            style={{
              width: `${groupListWidth[data.key]}%`,
            }}
          >
            <div>
              <div className={styles['table-header-title']}>
                <div
                  style={{
                    cursor:
                      data.isSort && data.key !== 'statusOfRemoteSettings'
                        ? 'pointer'
                        : 'default',
                    whiteSpace:
                      localeLanguage === LOCALE_CODE.EN ? 'pre' : 'pre-wrap',
                    wordBreak: 'break-word',
                    fontSize:
                      (isFirefox || isSafari) &&
                      (localeLanguage === LOCALE_CODE.FR ||
                        localeLanguage === LOCALE_CODE.DE)
                        ? isSafari
                          ? '12px'
                          : '13px'
                        : '',
                  }}
                  className={styles['table-header-title-text']}
                  aria-hidden
                  onClick={() => {
                    if (data.key === 'statusOfRemoteSettings') {
                      return;
                    }
                    props.clickSortButton(data);
                    // triggerGAEvent(
                    //   data.key,
                    //   props.tableSrcPage ? props.tableSrcPage : '',
                    // );
                  }}
                >
                  {data.value}
                </div>
                <div
                  className={styles['table-header-icon']}
                  aria-hidden
                  onClick={() => {
                    props.clickSortButton(data);
                    // triggerGAEvent(
                    //   data.key,
                    //   props.tableSrcPage ? props.tableSrcPage : '',
                    // );
                  }}
                  style={{
                    zIndex: 1,
                    display:
                      data.key === 'statusOfRemoteSettings' ? 'none' : '',
                  }}
                >
                  {props.sortConfig &&
                    data.isSort &&
                    props.sortConfig.key === data.key &&
                    props.sortConfig.direction === DIRECTION_TYPE.ascending && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpSelectedIcon />
                        <SortDownIcon />
                      </div>
                    )}
                  {props.sortConfig &&
                    data.isSort &&
                    props.sortConfig.key === data.key &&
                    props.sortConfig.direction ===
                      DIRECTION_TYPE.descending && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpIcon />
                        <SortDownSelectedIcon />
                      </div>
                    )}
                  {props.sortConfig &&
                    data.isSort &&
                    (props.sortConfig.key !== data.key ||
                      props.sortConfig.direction === DIRECTION_TYPE.none) && (
                      <div className={styles['table-header-icon-container']}>
                        <SortUpIcon />
                        <SortDownIcon />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
});

export default BulkActionTableHeader;
