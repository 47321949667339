import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { Button, Box, Typography } from '@mui/material';
import useBulkActionGroupList from '../../hooks/useBulkActionGroupList';
import BulkActionTableTemplateContainer from '../Templates/BulkActionTableTemplateContainer';
import styles from '../Pages/GroupList.module.scss';
import { ReactComponent as GroupIcon } from '../../images/icon/Group_user.svg';
import useAccountInfo from '../../hooks/useAccountInfo';
import {
  FEATURE_PERMISSION,
  SERVER_MESSAGE,
  PAGE_PATH_NAME,
} from '../../constants/constants';
import SearchField from './SearchField';
import { GROUP_LIST_API_RESULT_CODE } from '../../apis/callGroupListApi';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as ArrowRightIcon } from '../../images/icon/right-arrow.svg';

type Props = {
  onClickNextButton: () => void;
  setIsAllSelected: Dispatch<SetStateAction<boolean>>;
  setIsSomeSelected: Dispatch<SetStateAction<boolean>>;
  isAllSelected: boolean;
  isSomeSelected: boolean;
  setSelectedGroupIds: Dispatch<SetStateAction<(number | string)[]>>;
  selectedGroupIds: (number | string)[];
  setSelectedDeviceCount: Dispatch<SetStateAction<number>>;
  selectedDeviceCount: number;
};

const GroupListBulkActionsSelectTarget = React.memo<Props>(
  (props): JSX.Element => {
    // common style
    const classes = useStyles();
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState<string>('');

    const location = useLocation();
    const locationKey = location.key;

    const { selectedGroupIds, selectedDeviceCount } = props;

    const {
      currentPageDataRows,
      headerColumns,
      t,
      sortValue,
      paginationValue,
      errorType,
      isCallingApi,
      validSelectedGroupIds,
      validNumberOfSelectedDevice,
    } = useBulkActionGroupList({
      searchText,
      selectedGroupIds,
    });
    const { accountInfo } = useAccountInfo();
    // declar Ref for the table body simple scroll bar
    const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);

    const handleSelectClick = (id: string | number, device: number) => {
      // Update the selectedGroupId state
      props.setSelectedGroupIds((prevSelectedGroupId) => {
        const isAlreadySelected = prevSelectedGroupId.includes(id);

        if (isAlreadySelected) {
          // If the id is already in selectedGroupId, remove it and subtract the device count
          const updatedSelectedDeviceCount = selectedDeviceCount - device;

          props.setSelectedDeviceCount(
            updatedSelectedDeviceCount > 0 ? updatedSelectedDeviceCount : 0,
          );

          return prevSelectedGroupId.filter((groupId) => groupId !== id);
        }
        // If the id is not in selectedGroupId, add it and add the device count
        props.setSelectedDeviceCount((prevCount) => prevCount + device);

        return [...prevSelectedGroupId, id];
      });
    };

    const handleAllSelectClick = () => {
      if (props.isSomeSelected || props.isAllSelected) {
        // Remove all IDs from currentPageDataRows from selectedGroupId
        props.setSelectedGroupIds((prevSelectedGroupId) => {
          const newSelectedGroupIds = prevSelectedGroupId.filter(
            (groupId) =>
              !currentPageDataRows.some(
                (anotherItem) => anotherItem.id === groupId,
              ),
          );

          sendGAEvent('select_check', 'btn_name', 'selected_group_list');
          sendGAEvent(
            'select_check',
            'group_list_value',
            newSelectedGroupIds.length.toString(),
          );

          return newSelectedGroupIds;
        });
        // Subtract all number_of_device in currentPageDataRows from selectedDeviceCount
        const totalDevicesToRemove = currentPageDataRows.reduce(
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          (sum, item) => (sum + item.data.number_of_device) as number,
          0,
        );
        const updatedSelectedDeviceCount =
          selectedDeviceCount - totalDevicesToRemove;

        props.setSelectedDeviceCount(
          updatedSelectedDeviceCount > 0 ? updatedSelectedDeviceCount : 0,
        );
      } else {
        // Add all IDs from currentPageDataRows to selectedGroupId without duplicates
        props.setSelectedGroupIds((prevSelectedGroupId) => {
          const newIds = currentPageDataRows.map((item) => item.id);
          const newSelectedGroupIds = [
            ...prevSelectedGroupId,
            ...newIds.filter((id) => !prevSelectedGroupId.includes(id)),
          ];

          sendGAEvent('select_check', 'btn_name', 'selected_group_list');
          sendGAEvent(
            'select_check',
            'group_list_value',
            newSelectedGroupIds.length.toString(),
          );

          return newSelectedGroupIds;
        });

        // Add all number_of_device in currentPageDataRows to selectedDeviceCount
        const totalDevicesToAdd = currentPageDataRows.reduce(
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          (sum, item) => (sum + item.data.number_of_device) as number,
          0,
        );
        props.setSelectedDeviceCount(
          (prevCount) => prevCount + totalDevicesToAdd,
        );
      }
    };

    // All selected or some selected handle
    useEffect(() => {
      const allIds = currentPageDataRows.map((item) => item.id);
      const selectedIdsSet = new Set(selectedGroupIds);

      const allSelected = allIds.every((id) => selectedIdsSet.has(id));
      const someSelected = allIds.some((id) => selectedIdsSet.has(id));

      props.setIsAllSelected(allSelected);
      props.setIsSomeSelected(someSelected && !allSelected);
    }, [currentPageDataRows, props, selectedGroupIds]);

    useEffect(() => {
      props.setSelectedDeviceCount(validNumberOfSelectedDevice);
      props.setSelectedGroupIds(validSelectedGroupIds);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validSelectedGroupIds, validNumberOfSelectedDevice]);

    const onClickCencelBtn = () => {
      sendGAEvent('press_btn', 'btn_name', 'cancel');
      navigate(PAGE_PATH_NAME.GROUPS);
    };

    return (
      <>
        <Box sx={{ marginBottom: '30px' }}>
          <Box className={classes.bulkActionNavCard}>
            <Typography className={classes.bulkActionNavTextBold}>
              {t('bulkActionPage.selectTargets')}
            </Typography>
            <ArrowRightIcon className={classes.arrowExpandIconActive} />
            <Typography className={classes.bulkActionNavText}>
              {t('bulkActionPage.setActions')}
            </Typography>
          </Box>
          <Typography className={classes.bulkActionMsgText}>
            {t('bulkActionPage.selectTargetsText')}
          </Typography>
        </Box>
        <div
          className={`${styles['group-list-flex-box']} group-list-responsive-flex`}
        >
          <SearchField
            locationKey={locationKey}
            clickFilterSearch={(val) => {
              setSearchText(val);
              // GA event tag
              sendGAEvent('search_kw', 'txt_search_kw', searchText);
            }}
            placeholder={t('bulkActionPage.placeholder')}
            responsiveClass="search-input-list"
            // autoFocusValue
          />
          {/* eslint-disable */}

          <Box className={classes.bulkActionRightCard}>
            <Box sx={{ display: 'flex', marginRight: '16px', gap: '4px' }}>
              <Typography className={classes.bulkActionCountShow}>
                {selectedGroupIds?.length}
              </Typography>
              <Typography className={classes.bulkActionCountShowText}>
                {t('bulkActionPage.groups')}
              </Typography>
              <Typography className={classes.bulkActionCountShow}>
                {selectedDeviceCount}
              </Typography>
              <Typography className={classes.bulkActionCountShowText}>
                {t('bulkActionPage.devicesSelected')}
              </Typography>
            </Box>

            <Button
              id="cancelButton"
              variant="outlined"
              className={classes.bulkActionOutlineBtn}
              onClick={onClickCencelBtn}
            >
              {t('bulkActionPage.cancel')}
            </Button>
            <Button
              id="nextButton"
              variant="contained"
              className={classes.bulkActionContainedBtn}
              onClick={() => props.onClickNextButton()}
              disabled={selectedGroupIds?.length === 0}
            >
              {t('bulkActionPage.next')}
            </Button>
          </Box>
        </div>
        {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.filter(
          (x) => x === FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.VIEWLIST,
        ).length === 1 && (
          <div>
            <Box
              className={classes.bulkActionTableCard}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {/* no group found */}
              {!isCallingApi &&
                (paginationValue.totalData === 0 ||
                  errorType === GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA) && (
                  <>
                    <div>
                      <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                        {t('groupList.label.title')} (0)
                      </Typography>
                    </div>

                    <div className={classes.bulkActionTableCardInner}>
                      <div className={classes.bulkActionNoDataCard}>
                        <GroupIcon style={{ width: '67px', height: '40px' }} />
                        {errorType == SERVER_MESSAGE.NO_INTERNET ? (
                          <Typography className={classes.bulkActionNoDataText}>
                            {t('common.error.noData')}
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              variant="h4"
                              className={classes.bulkActionNoGroupText}
                            >
                              {t('groupList.errors.noGroup')}
                            </Typography>
                            <Typography
                              className={classes.bulkActionTryAgainText}
                            >
                              {t('common.error.tryAgain')}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              {!(
                !isCallingApi &&
                (paginationValue.totalData === 0 ||
                  errorType === GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA)
              ) &&
                errorType !== SERVER_MESSAGE.NO_INTERNET && (
                  <BulkActionTableTemplateContainer
                    simpleBarRef={simpleBarRef}
                    headerColumns={headerColumns}
                    dataRows={currentPageDataRows}
                    totalDataRowsLabel="Group"
                    totalDataRows={paginationValue.totalData}
                    clickSortButton={sortValue.onClickSortButton}
                    sortConfig={sortValue.sortConfig}
                    currentPage={paginationValue.currentPage}
                    totalPages={paginationValue.totalPages}
                    clickPrevPageButton={paginationValue.onClickPrevPage}
                    clickNextPageButton={paginationValue.onClickNextPage}
                    clickFirstPageButton={paginationValue.onClickFirstPage}
                    clickLastPageButton={paginationValue.onClickLastPage}
                    currentRowNumberPerPage={
                      paginationValue.currentRowNumberPerPage
                    }
                    containFirstAndLastButton
                    pageDataIndexFrom={paginationValue.pageDataIndexFrom}
                    pageDataIndexTo={paginationValue.pageDataIndexTo}
                    tableSrcPage="Group"
                    isCallingApi={isCallingApi}
                    handleSelectClick={handleSelectClick}
                    handleAllSelectClick={handleAllSelectClick}
                    selectedGroupIds={selectedGroupIds}
                    isAllSelected={props.isAllSelected}
                    isSomeSelected={props.isSomeSelected}
                  />
                )}
            </Box>
          </div>
        )}
      </>
    );
  },
);

export default GroupListBulkActionsSelectTarget;
