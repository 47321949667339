import { useEffect } from 'react';
import { Typography } from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import BulkActionDataTableContainer from '../Modecules/BulkActionDataTableContainer';
import type {
  TableHeaderData,
  TableRowData,
  SortConfig,
} from '../../types/datatable/dataTable.d';
import styles from './TableTemplateContainer.module.scss';
import BulkActionPaginationContainer from '../Modecules/BulkActionPaginationContainer';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

type Props = {
  headerColumns: TableHeaderData[];
  dataRows: TableRowData[];
  totalDataRowsLabel: string;
  totalDataRows: number;
  clickSortButton: (data: TableHeaderData) => void;
  sortConfig: SortConfig;
  currentPage: number;
  totalPages: number;
  clickPrevPageButton: () => void;
  clickNextPageButton: () => void;
  currentRowNumberPerPage: number;
  clickFirstPageButton?: () => void;
  clickLastPageButton?: () => void;
  containFirstAndLastButton?: boolean;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
  handleSelectClick: (id: string | number, device: number) => void;
  handleAllSelectClick: () => void;
  selectedGroupIds: (number | string)[];
  isAllSelected: boolean;
  isSomeSelected: boolean;
};

const BulkActionTableTemplateContainer = ({
  headerColumns,
  dataRows,
  totalDataRows,
  clickSortButton,
  sortConfig,
  currentPage,
  totalPages,
  clickPrevPageButton,
  clickNextPageButton,
  currentRowNumberPerPage,
  pageDataIndexFrom,
  pageDataIndexTo,
  simpleBarRef,
  ...props
}: Props): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();

  useEffect(() => {
    const tableBody = document.getElementsByClassName(
      'table-body-simplebar',
    )[0];
    if (tableBody) {
      const scrollContainerClass = tableBody.querySelector(
        '.simplebar-content-wrapper',
      );
      if (scrollContainerClass) {
        scrollContainerClass.scrollTop = 0;
      }
    }
  }, [dataRows]);

  return (
    <>
      <div>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
          {t('groupList.label.title')} ({totalDataRows})
        </Typography>
      </div>
      <div className={styles['data-table-wrap']}>
        <BulkActionDataTableContainer
          simpleBarRef={simpleBarRef}
          headerColumns={headerColumns}
          dataRows={dataRows}
          clickSortButton={clickSortButton}
          sortConfig={sortConfig}
          tableSrcPage={props.tableSrcPage}
          isCallingApi={props.isCallingApi}
          handleSelectClick={props.handleSelectClick}
          selectedGroupIds={props.selectedGroupIds}
          handleAllSelectClick={props.handleAllSelectClick}
          isAllSelected={props.isAllSelected}
          isSomeSelected={props.isSomeSelected}
        />
      </div>
      <div
        className={styles['data-pagination']}
        style={{
          backgroundColor: '#F9FAFB',
        }}
      >
        <BulkActionPaginationContainer
          currentPage={currentPage}
          totalPages={totalPages}
          clickPrevPageButton={clickPrevPageButton}
          clickNextPageButton={clickNextPageButton}
          currentRowNumberPerPage={currentRowNumberPerPage}
          containFirstAndLastButton={props.containFirstAndLastButton}
          clickFirstPageButton={props.clickFirstPageButton}
          clickLastPageButton={props.clickLastPageButton}
          pageDataIndexFrom={pageDataIndexFrom}
          pageDataIndexTo={pageDataIndexTo}
          totalDataRows={totalDataRows}
        />
      </div>
    </>
  );
};

BulkActionTableTemplateContainer.defaultProps = {
  clickFirstPageButton: () => undefined,
  clickLastPageButton: () => undefined,
  containFirstAndLastButton: false,
  tableSrcPage: '',
  isCallingApi: false,
};

export default BulkActionTableTemplateContainer;
