import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Control, FieldValues } from 'react-hook-form';
import { Box, Typography, IconButton, Button, Checkbox } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { useStyles as dialogStyles } from '../Dialog/DialogStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import { ReactComponent as PlusIcon } from '../../images/icon/plus_blue.svg';
import WifiSettingsCard from './WifiSettingsCard';
import { WifiSettingsType } from '../../types/remote/remoteDeviceSetup.d';
import { useAppDispatch, RootState } from '../../state/store';
import { remoteSetupErrorSlice } from '../../state/slice/remoteSetupErrorSlice';
import { sendGAEvent } from '../../hooks/useGAEvent';

type IProps = {
  handleScroll: () => void;
  control: Control<FieldValues, any>;
  append: any;
  remove: any;
  setValue: any;
  handleApplyButtonDisable: any;
};

const RemoteDeviceSetupWifiSettingsContainer = ({
  control,
  handleScroll,
  append,
  // eslint-disable-next-line
  remove,
  setValue,
  handleApplyButtonDisable,
}: IProps) => {
  const [wifiSettingsCheckbox, setWifiSettingsCheckbox] =
    useState<boolean>(false);

  const [states, setStates] = useState<boolean>(false);

  // eslint-disable-next-line
  const wifiSettings: WifiSettingsType[] = control._formValues.wifiSettings
    ? // eslint-disable-next-line
      control._formValues.wifiSettings
    : [];

  const classes = useStyles();
  const dialogClasses = dialogStyles();
  const { t } = useSwitchLocaleLanguage();
  const dispatch = useAppDispatch();

  const resetState = useSelector(
    (state: RootState) => state.remoteSetupError.resetState,
  );

  useEffect(() => {
    if (resetState) {
      setWifiSettingsCheckbox(false);
      // eslint-disable-next-line
      setValue('wifiSettings', []);
    }

    dispatch(remoteSetupErrorSlice.actions.setResetState(false));
    // eslint-disable-next-line
  }, [resetState]);

  const onClickWifiSettingsCheckboxData = () => {
    // eslint-disable-next-line
    setValue('saveWifiSettings', !wifiSettingsCheckbox);
    dispatch(
      remoteSetupErrorSlice.actions.setSaveWifiSettings(!wifiSettingsCheckbox),
    );

    setWifiSettingsCheckbox(!wifiSettingsCheckbox);
    // eslint-disable-next-line
    handleApplyButtonDisable();
  };

  const handleAddWifiSettings = () => {
    const value = {
      id: wifiSettings.length,
      ssid: '',
      password: '',
      securityType: 0,
      proxyType: 0,
      proxyHostName: '',
      proxyPort: '',
      bypassProxy: '',
      pacUrl: '',
      wifiIPSettingType: 0,
      ipAddress: '',
      gateway: '',
      networkPrefixLength: '',
      dns1: '',
      dns2: '',
      isDelete: false,
      advanceOpt: false,
    };

    dispatch(
      remoteSetupErrorSlice.actions.addError({
        // eslint-disable-next-line
        name: `wifiSettings.${wifiSettings.length}.ssid`,
        error: t('common.validationError.required'),
        ignore: false,
      }),
    );

    dispatch(
      remoteSetupErrorSlice.actions.addError({
        // eslint-disable-next-line
        name: `wifiSettings.${wifiSettings.length}.password`,
        error: t('common.validationError.required'),
        ignore: false,
      }),
    );

    // eslint-disable-next-line
    append(value);
    handleScroll();
    // eslint-disable-next-line
    handleApplyButtonDisable();
  };

  const removeWifiSettings = (index: number, id: number) => {
    // eslint-disable-next-line
    setValue(`wifiSettings.${id}.isDelete`, true);
    setStates(!states);
    // remove(index);
    // eslint-disable-next-line
    handleApplyButtonDisable();

    // eslint-disable-next-line
    dispatch(remoteSetupErrorSlice.actions.removeWifiSettingsError(`${id}`));
  };

  return (
    <Box>
      {/* add wifi start */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}
      >
        <Typography className={dialogClasses.fieldTitle}>
          {t('remoteDevice.label.wifiSet')}
        </Typography>
        <IconButton
          type="button"
          aria-label="add wifi"
          className={dialogClasses.addIconStyle}
          // disabled={!EditAccessForWifi || isAddButtonDisable}
          disableRipple
          onClick={() => {
            sendGAEvent('press_btn', 'btn_name', 'add_new_wifi');
            handleAddWifiSettings();
          }}
          disabled={!wifiSettingsCheckbox}
        >
          <PlusIcon className={classes.addIcon} />
          <Typography
            fontSize="14px"
            lineHeight="20px"
            height="20px"
            pl="3px"
            sx={{
              letterSpacing: '0.09px',
            }}
          >
            {t('remoteDevice.button.newWifi')}
          </Typography>
        </IconButton>
      </Box>
      {/* add wifi end */}

      {/* main container start */}
      <Box
        className={classes.wifiBoxWrapper}
        style={{ minHeight: '0px', display: 'flex', alignItems: 'start' }}
      >
        <Checkbox
          onClick={onClickWifiSettingsCheckboxData}
          checked={wifiSettingsCheckbox}
          tabIndex={-1}
          icon={<CheckBox className={classes.checkBoxIconStyle} />}
          checkedIcon={<CheckedCheckBox />}
          className={dialogClasses.bulkCheckBoxStyle}
        />
        <Box
          style={{
            width: '100%',
          }}
        >
          {/* No wifi Box start */}
          {
            // eslint-disable-next-line
            wifiSettings!.filter((item) => item.isDelete === false).length ===
            0 ? (
              <Box
                sx={{
                  padding: '30px',
                  border: '1px solid #d9d9d9',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  className={classes.customInputLabel}
                  paragraph
                  align="center"
                >
                  {t('remoteDevice.label.caution')}
                  <br />
                  {t('remoteDevice.label.cautionText')}
                </Typography>
                <Button
                  aria-label="Update"
                  onClick={() => {
                    sendGAEvent('press_btn', 'btn_name', 'add_wifi');
                    handleAddWifiSettings();
                  }}
                  className={dialogClasses.addButton}
                  disableRipple
                  disabled={!wifiSettingsCheckbox}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    {t('remoteDevice.button.addWifi')}
                  </Typography>
                </Button>
              </Box>
            ) : (
              // eslint-disable-next-line
              wifiSettings.map((item: WifiSettingsType, index: number) => {
                // eslint-disable-next-line
                if (item.isDelete == false) {
                  return (
                    <WifiSettingsCard
                      // eslint-disable-next-line
                      key={`${item.ssid} ${index}`}
                      control={control}
                      item={item}
                      index={index}
                      deleteFunction={removeWifiSettings}
                      // eslint-disable-next-line
                      setValue={setValue}
                    />
                  );
                }

                return null;
              })
            )
          }
        </Box>
      </Box>
      {/* main container end */}
    </Box>
  );
};

export default RemoteDeviceSetupWifiSettingsContainer;
