import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Box, Typography, Checkbox, Button, Tooltip } from '@mui/material';
import InputPinCode from '../Atoms/InputPinCode';
import CommonSelect from '../Atoms/CommonSelect';
import ToggleSwitch from '../Atoms/ToggleSwitch';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import QuestionTooltip from '../Atoms/QuestionTooltip';
import { useStyles } from '../Templates/CommonStyle';
import { useStyles as dialogStyles } from '../Dialog/DialogStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import customStylesMd from '../../utils/customizeReactSelect/customStylesMd';
import createDropdownValues from '../../utils/remote/dropdownText';
import useRemoteFeatureLimitList from '../../hooks/useRemoteFeatureLimitList';
import { useAppDispatch, RootState } from '../../state/store';
import { remoteSetupErrorSlice } from '../../state/slice/remoteSetupErrorSlice';
import { ReactComponent as QuestionIcon } from '../../images/icon/Question_light.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';

type IProps = {
  control: any;
  setValue: any;
  handleApplyButtonDisable: any;
};

const RemoteSetupSecurityCard = ({
  control,
  setValue,
  handleApplyButtonDisable,
}: IProps) => {
  const [pinValue, setPinValue] = useState<string>('');
  const [translationHistoryToggle, setTranslationHistoryToggle] =
    useState<boolean>(false);
  const [pinCodeCheckbox, setPinCodeCheckbox] = useState<boolean>(false);
  const [autoLockScreenCheckbox, setAutoLockScreenCheckbox] =
    useState<boolean>(false);
  const [translationHistoryCheckbox, setTranslationHistoryCheckbox] =
    useState<boolean>(false);
  const [featureLimitCheckbox, setFeatureLimitCheckbox] =
    useState<boolean>(false);

  const classes = useStyles();
  const dialogClasses = dialogStyles();
  const { t } = useSwitchLocaleLanguage();
  const dispatch = useAppDispatch();

  const dropDownValues = createDropdownValues(t);
  const { featureLimitListData } = useRemoteFeatureLimitList();

  const featureLimitOpt = featureLimitListData?.map((value) => ({
    value: value.id,
    label: value.modeName,
  }));

  const resetState = useSelector(
    (state: RootState) => state.remoteSetupError.resetState,
  );

  const groupPinError = useSelector(
    (state: RootState) => state.remoteSetupError.errors.groupPin,
  );

  useEffect(() => {
    if (resetState) {
      setPinCodeCheckbox(false);
      setAutoLockScreenCheckbox(false);
      setTranslationHistoryCheckbox(false);
      setTranslationHistoryToggle(false);
      setFeatureLimitCheckbox(false);
      setPinValue('');
    }

    dispatch(remoteSetupErrorSlice.actions.setResetState(false));
    // eslint-disable-next-line
  }, [resetState]);

  /*eslint-disable*/
  const onClickPinCodeCheckboxData = () => {
    setValue('pinCodeEnable', !pinCodeCheckbox);
    setPinCodeCheckbox(!pinCodeCheckbox);

    dispatch(remoteSetupErrorSlice.actions.setSavePinEnable(!pinCodeCheckbox));

    handleApplyButtonDisable();
  };

  const onClickAutoLockScreenCheckboxData = () => {
    setValue('sleepTimeEnable', !autoLockScreenCheckbox);
    setAutoLockScreenCheckbox(!autoLockScreenCheckbox);
    handleApplyButtonDisable();
  };

  const onClickTranslationHistoryCheckboxData = () => {
    setValue('dataDeleteTimeEnable', !translationHistoryCheckbox);
    setTranslationHistoryCheckbox(!translationHistoryCheckbox);
    handleApplyButtonDisable();
  };

  const onClickTranslationHistoryData = () => {
    sendGAEvent('press_btn', 'btn_name', 'save_translation_history_select');
    sendGAEvent(
      'press_btn',
      'save_translation_history_value',
      translationHistoryToggle ? 'OFF' : 'ON',
    );
    if (translationHistoryCheckbox) {
      setValue('saveInLocalFlag', !translationHistoryToggle);
      setTranslationHistoryToggle(!translationHistoryToggle);
    }
  };

  const onClickFeatureLimitData = () => {
    setValue('remoteSettingModeEnable', !featureLimitCheckbox);
    setFeatureLimitCheckbox(!featureLimitCheckbox);
    handleApplyButtonDisable();
  };

  const validateInput = (value: string, rules: any) => {
    const fieldRules = rules;

    if (!fieldRules) return '';

    for (const ruleKey in fieldRules) {
      const rule = fieldRules[ruleKey];
      switch (ruleKey) {
        case 'required':
          if (rule.value && !value) return rule.message;
          break;
        case 'maxLength':
          if (value?.length > rule.value) return rule.message;
          break;
        case 'pattern':
          if (value && !RegExp(rule.value).test(value)) return rule.message;
          break;
        default:
          break;
      }
    }
    return '';
  };

  const onChangeTagInput = (value: string, rule: any) => {
    setPinValue(value);

    const errorMessage = validateInput(value, rule);
    if (errorMessage !== '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: 'groupPin',
          error: errorMessage,
          ignore: false,
        }),
      );
    } else {
      dispatch(remoteSetupErrorSlice.actions.removeError('groupPin'));
    }
  };

  return (
    <Box>
      {/* data protection start */}
      <Typography
        sx={{
          margin: '20px 0px 12px',
        }}
        className={dialogClasses.fieldTitle}
      >
        {t('remoteDevice.label.dataProtection')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '30px',
          rowGap: '20px',
          marginBottom: '20px',
        }}
        className="remove-device-setup-protection-input-form"
      >
        {/* pin code start */}
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Checkbox
            onClick={onClickPinCodeCheckboxData}
            checked={pinCodeCheckbox}
            tabIndex={-1}
            icon={<CheckBox className={classes.checkBoxIconStyle} />}
            checkedIcon={<CheckedCheckBox />}
            className={dialogClasses.bulkCheckBoxStyle}
          />
          <Box className={classes.inputBox}>
            <Typography
              className={classes.customInputLabel}
              sx={{ display: 'block !important', paddingTop: '1px' }}
            >
              {t('remoteDevice.label.pinCode')}
            </Typography>
            <Controller
              control={control}
              name="groupPin"
              rules={{
                pattern: {
                  value: /^(|[0-9]{6})$/,
                  message: 'PIN Code pattern',
                },
                maxLength: { value: 6, message: 'Max Length' },
              }}
              render={({ field: { onChange } }) => (
                <InputPinCode
                  value={pinValue}
                  onChange={(e) => {
                    onChange(e);
                    onChangeTagInput(e.toString(), {
                      pattern: {
                        value: /^(|[0-9]{6})$/,
                        message: 'PIN Code pattern',
                      },
                      maxLength: { value: 6, message: 'Max Length' },
                    });
                  }}
                  defaultValue="groupPin"
                  size="medium"
                  color={groupPinError && pinCodeCheckbox ? 'error' : 'primary'}
                  inputProps={{ maxLength: 6 }}
                  disabled={!pinCodeCheckbox}
                />
              )}
              /*eslint-disable*/
            />
            {groupPinError && pinCodeCheckbox && (
              <Box
                sx={{
                  // bottom: '45px !important',
                  marginBottom: '18px',
                }}
                className={classes.newWifiErrorCard}
              >
                <p style={{ padding: '0', margin: '0' }}>
                  {t('remoteDevice.message.pinValidation')}
                </p>
              </Box>
            )}
            <p className={classes.inputText} style={{ position: 'relative' }}>
              {t('remoteDevice.message.digit')}
            </p>
          </Box>
        </Box>
        {/* pin code end */}

        {/* Auto Lock Screen start */}
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Checkbox
            onClick={onClickAutoLockScreenCheckboxData}
            checked={autoLockScreenCheckbox}
            tabIndex={-1}
            icon={<CheckBox className={classes.checkBoxIconStyle} />}
            checkedIcon={<CheckedCheckBox />}
            className={dialogClasses.bulkCheckBoxStyle}
          />
          <Box sx={{ width: '100%' }}>
            <Typography
              className={classes.customInputLabel}
              sx={{ display: 'block !important', paddingTop: '1px' }}
            >
              {t('remoteDevice.label.autoLock')}
            </Typography>
            <CommonSelect
              ControllerName="sleepTime"
              control={control}
              options={dropDownValues.sleepTimeOpt}
              isSearchable={false}
              className={classes.reactSelect}
              classNamePrefix="react-select"
              styles={customStylesMd}
              isDisabled={!autoLockScreenCheckbox}
              onChangeValue={(value: string) => {
                sendGAEvent('select_list', 'txt_name', 'security');
                sendGAEvent(
                  'select_list',
                  'security_value',
                  `${value} Seconds`,
                );
              }}
            />
          </Box>
        </Box>
        {/* Auto Lock Screen end */}
      </Box>
      {/* data protection end */}

      {/* translation history start */}
      <Typography
        sx={{
          margin: '12px 0px 12px',
        }}
        className={dialogClasses.fieldTitle}
      >
        {t('remoteDevice.label.saveTranslationHistory')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '30px',
          rowGap: '20px',
          marginBottom: '20px',
        }}
        className="remove-device-setup-protection-input-form"
      >
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Checkbox
            onClick={onClickTranslationHistoryCheckboxData}
            checked={translationHistoryCheckbox}
            tabIndex={-1}
            icon={<CheckBox className={classes.checkBoxIconStyle} />}
            checkedIcon={<CheckedCheckBox />}
            className={dialogClasses.bulkCheckBoxStyle}
          />
          <Box sx={{ width: '100%' }}>
            <Typography
              className={classes.customInputLabel}
              sx={{
                display: 'flex !important',
                paddingTop: '1px',
                // alignItem: 'center',
              }}
            >
              {t('remoteDevice.label.saveTranslationHistory')}
              <Tooltip
                placement="top"
                title={
                  <>
                    <div>{t('remoteDevice.tooltip.saveHistoryLine1')}</div>
                    <div>{t('remoteDevice.tooltip.saveHistoryLine2')}</div>
                  </>
                }
                arrow
                sx={{ marginTop: 0 }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-7, -7],
                      },
                    },
                  ],
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: '365px',
                      padding: '9px',
                      textAlign: 'left',
                      fontWeight: 300,
                      fontSize: '11px',
                      backgroundColor: '#313133',
                      lineHeight: 1.35,
                    },
                  },
                }}
              >
                <Box sx={{ width: '16px', height: '16px', marginLeft: '6px' }}>
                  <QuestionIcon className={classes.tooltipIcon} />
                </Box>
              </Tooltip>
            </Typography>

            <Button
              sx={{ padding: '0px', minWidth: '0px', marginBottom: '12px' }}
              onClick={onClickTranslationHistoryData}
              disableRipple
            >
              <ToggleSwitch
                switchStatus={
                  translationHistoryToggle && translationHistoryCheckbox
                }
                isBig
              />
            </Button>
            <Typography
              className={classes.customInputLabel}
              sx={{ display: 'block !important', paddingTop: '1px' }}
            >
              {t('remoteDevice.label.autoDelete')}
            </Typography>
            <CommonSelect
              ControllerName="dataDeleteTime"
              control={control}
              options={dropDownValues.deleteScreenTime}
              isSearchable={false}
              className={classes.reactSelect}
              classNamePrefix="react-select"
              styles={customStylesMd}
              isDisabled={
                !translationHistoryCheckbox || !translationHistoryToggle
              }
              onChangeValue={(value: string) => {
                sendGAEvent(
                  'select_list',
                  'txt_name',
                  'auto_delete_translation_history',
                );
                sendGAEvent(
                  'select_list',
                  'auto_delete_translation_history_value',
                  `${value} Seconds`,
                );
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* translation history end */}

      {/* feature limit start */}
      <Typography
        sx={{
          margin: '12px 0px 12px',
          display: 'flex',
        }}
        className={dialogClasses.fieldTitle}
      >
        {t('remoteDevice.label.featureLimit')}
        <QuestionTooltip />
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '30px',
          rowGap: '20px',
          marginBottom: '20px',
        }}
        className="remove-device-setup-protection-input-form"
      >
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <Checkbox
            onClick={onClickFeatureLimitData}
            checked={featureLimitCheckbox}
            tabIndex={-1}
            icon={<CheckBox className={classes.checkBoxIconStyle} />}
            checkedIcon={<CheckedCheckBox />}
            className={dialogClasses.bulkCheckBoxStyle}
          />
          <Box sx={{ width: '100%' }}>
            <CommonSelect
              ControllerName="remoteSettingModeId"
              control={control}
              options={featureLimitOpt!}
              isSearchable={false}
              className={classes.reactSelect}
              classNamePrefix="react-select"
              styles={customStylesMd}
              isDisabled={!featureLimitCheckbox}
              onChangeValue={(value: string) => {
                sendGAEvent('select_list', 'txt_name', 'feature_limit');
                sendGAEvent('select_list', 'feature_limit_value', value);
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* feature limit end */}
    </Box>
  );
};

export default RemoteSetupSecurityCard;
