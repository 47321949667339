import React from 'react';
import { IconButton, Typography, Grid, Box } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as NextPageIcon } from '../../images/icon/next-page.svg';
import { ReactComponent as PreviousPageIcon } from '../../images/icon/previous-page.svg';
import { ReactComponent as FirstPageIcon } from '../../images/icon/first-page.svg';
import { ReactComponent as LastPageIcon } from '../../images/icon/last-page.svg';
import styles from './PaginationContainer.module.scss';

/**
 * Props received when calling this component (required)
 */
type Props = {
  currentPage: number;
  totalPages: number;
  clickPrevPageButton: () => void;
  clickNextPageButton: () => void;
  currentRowNumberPerPage: number;
  clickFirstPageButton?: () => void;
  clickLastPageButton?: () => void;
  containFirstAndLastButton?: boolean;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
  totalDataRows: number;
};

const BulkActionPaginationContainer = React.memo<Props>(
  (props): JSX.Element => {
    const classes = useStyles();

    const { t } = useSwitchLocaleLanguage();

    return (
      <div className={`${styles['pagination-button-wrapper']} table-footer`}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="table-footer-item"
        >
          <Grid item>
            <Typography>{t('pagination.display')}</Typography>
          </Grid>

          <Grid
            item
            sx={{
              paddingLeft: '10px !important',
            }}
          >
            <Box className={classes.bulkActionPaginationRowBtn}>
              <Typography sx={{ flexGrow: 2, lineHeight: 1 }}>
                {props.currentRowNumberPerPage}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          className="table-footer-item table-pagination"
        >
          <Grid item>
            <Typography className="pagination-page-info">
              {props.pageDataIndexFrom} - {props.pageDataIndexTo}/
              {props.totalDataRows}
            </Typography>
          </Grid>

          <Grid
            item
            sx={{ display: 'flex' }}
            className={styles['pagination-icon-container']}
          >
            {props.containFirstAndLastButton && (
              <div>
                <IconButton
                  onClick={props.clickFirstPageButton}
                  disabled={props.currentPage === 1}
                  sx={{
                    opacity: props.currentPage === 1 ? '0.5' : '1',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: '#009de1',
                    },
                  }}
                >
                  <FirstPageIcon />
                </IconButton>
              </div>
            )}

            <div>
              <IconButton
                onClick={props.clickPrevPageButton}
                disabled={props.currentPage === 1}
                sx={{
                  opacity: props.currentPage === 1 ? '0.5' : '1',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#009de1',
                  },
                }}
              >
                <PreviousPageIcon />
              </IconButton>
            </div>

            <div>
              <IconButton
                onClick={props.clickNextPageButton}
                disabled={props.currentPage === props.totalPages}
                sx={{
                  opacity: props.currentPage === props.totalPages ? '0.5' : '1',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <NextPageIcon />
              </IconButton>
            </div>

            {props.containFirstAndLastButton && (
              <div>
                <IconButton
                  onClick={props.clickLastPageButton}
                  disabled={props.currentPage === props.totalPages}
                  sx={{
                    opacity:
                      props.currentPage === props.totalPages ? '0.5' : '1',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: '#009de1',
                    },
                  }}
                >
                  <LastPageIcon />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  },
);

export default BulkActionPaginationContainer;
