import { useFieldArray } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import { useStyles as dialogStyles } from '../Dialog/DialogStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import RemoteSetupSecurityCard from './RemoteDeviceSetupSecurityCard';
import RemoteDeviceSetupWifiSettingsContainer from './RemoteDeviceSetupWifiSettingsContainer';

type IProps = {
  handleScroll: () => void;
  // eslint-disable-next-line
  control: any;
  // eslint-disable-next-line
  setValue: any;
  // eslint-disable-next-line
  handleApplyButtonDisable: any;
};

const RemoteDeviceSetupContainer = ({
  handleScroll,
  control,
  setValue,
  handleApplyButtonDisable,
}: IProps) => {
  const classes = useStyles();
  const dialogClasses = dialogStyles();
  const { t } = useSwitchLocaleLanguage();

  const { append, remove } = useFieldArray({
    // eslint-disable-next-line
    control,
    // eslint-disable-next-line
    name: 'wifiSettings',
  });

  return (
    <Box className={classes.bulkActionRemoteSettingsCard}>
      <Typography className={dialogClasses.remotDeviceSetupTitle}>
        {t('remoteDevice.label.title')}
      </Typography>
      <RemoteSetupSecurityCard
        // eslint-disable-next-line
        control={control}
        // eslint-disable-next-line
        setValue={setValue}
        // eslint-disable-next-line
        handleApplyButtonDisable={handleApplyButtonDisable}
      />
      <RemoteDeviceSetupWifiSettingsContainer
        // eslint-disable-next-line
        control={control}
        handleScroll={handleScroll}
        append={append}
        remove={remove}
        // eslint-disable-next-line
        setValue={setValue}
        // eslint-disable-next-line
        handleApplyButtonDisable={handleApplyButtonDisable}
      />
    </Box>
  );
};

export default RemoteDeviceSetupContainer;
