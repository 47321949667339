import * as React from 'react';
// import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseProps } from '@mui/material';

// icons
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';

// mui custom style
import { useStyles } from '../Templates/CommonStyle';

const InputPassword = (props: InputBaseProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const inputRef = React.useRef<any>(null);
  // custom styles
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  /*eslint-disable*/
  React.useEffect(() => {
    // Moving cursor to the end
    inputRef.current?.focus();
  }, [showPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      className={`${classes.passwordInput} ${classes.passwordInputTypo} ${
        props.size === 'medium'
          ? classes.passwordInputMd
          : classes.passwordInputSm
      } ${props.color === 'error' && classes.errorInput}`}
      type={showPassword ? 'text' : 'password'}
      defaultValue={props.value}
      onChange={props.onChange}
      inputProps={props.inputProps}
      endAdornment={
        <InputAdornment position="end" sx={{ zIndex: '1000' }}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            disableRipple
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
      disabled={props.disabled}
    />
  );
};

export default InputPassword;
