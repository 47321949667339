import React, { useState } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Box, InputBase } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useStyles } from '../Templates/CommonStyle';
import InputPassword from './InputPassword';
import InputPinCode from './InputPinCode';
import { useAppDispatch } from '../../state/store';
import { remoteSetupErrorSlice } from '../../state/slice/remoteSetupErrorSlice';

/*eslint-disable*/
type IProps = {
  control: Control<FieldValues, any>;
  classes: any;
  name: string;
  rules: any;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  defaultValue?: string | number;
  isError?: boolean;
  errorMargin: number;
  isPassword?: boolean;
  isPinCode?: boolean;
  isDisable?: boolean;
};

const TextFieldInput = React.memo<IProps>((props): JSX.Element => {
  const [isChange, setIsChange] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const validateInput = (value: string) => {
    const fieldRules = props.rules;

    if (!fieldRules) return '';

    for (const ruleKey in fieldRules) {
      const rule = fieldRules[ruleKey];
      switch (ruleKey) {
        case 'required':
          if (rule.value && !value) return rule.message;
          break;
        case 'minLength':
          if (value?.length < rule.value) return rule.message;
          break;
        case 'maxLength':
          if (value?.length > rule.value) return rule.message;
          break;
        case 'pattern':
          if (value && !RegExp(rule.value).test(value)) return rule.message;
          break;
        default:
          break;
      }
    }
    return '';
  };

  const handleInputError = (value: string) => {
    setIsChange(true);

    const errorMessage = validateInput(value);
    if (errorMessage !== '') {
      dispatch(
        remoteSetupErrorSlice.actions.addError({
          name: props.name,
          error: errorMessage,
          ignore: false,
        }),
      );
    } else {
      dispatch(remoteSetupErrorSlice.actions.removeError(props.name));
    }
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <>
          {!props.isPassword && !props.isPinCode ? (
            <InputBase
              key={props.name}
              inputProps={{
                minLength: props.minLength,
                maxLength: props.maxLength,
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'new-password',
                },
              }}
              value={value}
              onChange={(e) => {
                onChange(e);
                handleInputError(e.target.value);
              }}
              placeholder={props.placeholder}
              className={`${props.classes.customInputOutline} ${
                validateInput(value) !== '' &&
                isChange &&
                !props.isDisable &&
                props.classes.customInputOutlineError
              }`}
              disabled={props.isDisable}
            />
          ) : props.isPinCode ? (
            <InputPinCode
              // eslint-disable-next-linse
              value={value}
              onChange={(e) => {
                onChange(e);
                handleInputError(e.toString());
              }}
              defaultValue="groupPin"
              size="medium"
              color={
                validateInput(value) !== '' && isChange ? 'error' : 'primary'
              }
              inputProps={{
                maxLength: 6,
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'new-password',
                },
              }}
              disabled={props.isDisable}
            />
          ) : (
            <InputPassword
              key={props.name}
              type="text"
              inputProps={{
                minLength: props.minLength,
                maxLength: props.maxLength,
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'new-password',
                },
              }}
              value={value}
              onChange={(e) => {
                onChange(e);
                handleInputError(e.target.value);
              }}
              placeholder={props.placeholder}
              color={
                validateInput(value) !== '' && isChange && !props.isDisable
                  ? 'error'
                  : 'primary'
              }
              disabled={props.isDisable}
            />
          )}
          {validateInput(value) !== '' && isChange && !props.isDisable && (
            <Box
              sx={{
                // bottom: '45px !important',
                marginBottom: `${props.errorMargin}px`,
              }}
              className={classes.newWifiErrorCard}
            >
              <p style={{ padding: '0', margin: '0' }}>
                {validateInput(value)}
              </p>
            </Box>
          )}
        </>
      )}
    />
  );
});

export default TextFieldInput;
