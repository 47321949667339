/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
} from 'react-select';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Box,
  InputBase,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
// common styles
import { useStyles } from '../Templates/CommonStyle';
import InputPinCode from '../Atoms/InputPinCode';
import InputPassword from '../Atoms/InputPassword';
import DeleteDialog from '../Atoms/DeleteDialog';
// assets
import { ReactComponent as PlusIcon } from '../../images/icon/plus_blue.svg';
import { ReactComponent as QuestionIcon } from '../../images/icon/Question_light.svg';
import { ReactComponent as DeleteIcon } from '../../images/icon/delete.svg';
import { ReactComponent as CaretIcon } from '../../images/icon/caret-down.svg';
import { ReactComponent as CaretIconDown } from '../../images/icon/caret-down-original.svg';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as CheckIcon } from '../../images/icon/Right_blue.svg';
// import useRemoteFeatureLimitList from '../../hooks/useRemoteFeatureLimitList';
import useRemoteFeatureSetupPost from '../../hooks/useRemoteFeatureSetupPost';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import Loader from '../Atoms/Loader';
import {
  PROXY_NAME_VALIDATION,
  IP_ADDRESS_VALIDATION,
  IP_PORT_VALIDATION,
  NETWORK_PREFIX,
  BYPASS_PROXY_VALIDATION,
  SSID_VALIDATION,
  WIFI_PASSWORD_PATTERN,
  FEATURE_PERMISSION,
} from '../../constants/constants';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { remoteFeatureLimitList } from '../../types/remote/featureLimitList.d';
import useAccountInfo from '../../hooks/useAccountInfo';
import { LOCALE_CODE } from '../../hooks/useLocale';
import ToggleSwitch from '../Atoms/ToggleSwitch';

/*eslint-disable*/
interface ICategory {
  value: number;
  label: string;
}
type IDParams = {
  groupId: string;
};
type IProps = {
  onClose: () => void;
  formField: FormFieldType;
  featureLimitListData: remoteFeatureLimitList[] | null;
};
type Wifi_settings = {
  ssid: '';
  password: '';
  securityType: number;
  proxyType: number;
  proxyHostName: '' | undefined;
  proxyPort: '' | null;
  bypassProxy: '' | null;
  pacUrl: '';
  wifiIPSettingType: number;
  ipAddress: '';
  gateway: '' | null;
  networkPrefixLength: '' | null;
  dns1: '' | null;
  dns2: '' | null;
  advanceOpt?: boolean;
  settingType: string;
  deleteFlag: number;
  groupInfoId: number;
  id: number;
};
type FormFieldType = {
  groupPin: string;
  sleepTime: number;
  saveInLocalFlag: boolean;
  dataDeleteTime: number;
  remoteSettingModeId: number;
  wifiSettings: Wifi_settings[];
};
type FormFieldSubmitType = {
  group_info_id: number;
  group_pin: string;
  sleep_time: number;
  data_delete_time: number;
  remote_setting_mode_id: number;
  wifi_settings: {
    ssid: '';
    password: '';
    securityType: number;
    proxyType: number;
    proxyHostName: '' | undefined;
    proxyPort: '' | null;
    bypassProxy: '' | null;
    pacUrl: '';
    wifiIPSettingType: number;
    ipAddress: '';
    gateway: '' | null;
    networkPrefixLength: '' | null;
    dns1: '' | null;
    dns2: '' | null;
    advanceOpt?: boolean;
    settingType: string;
    deleteFlag: number;
    groupInfoId: number;
    id: number;
  }[];
};

const stringAppendHandler = (
  mainString: string | null | undefined,
  appendString: string | null | undefined,
): string => {
  mainString = mainString + (mainString !== '' ? ',' : '') + appendString;

  return mainString;
};
const NewWifi = ({
  formField,
  onClose,
  featureLimitListData,
}: IProps): JSX.Element => {
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteItems, setDeleteItems] = useState<Wifi_settings[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [noWifiProfile, setnoWifiProfile] = useState(false);
  const [translationHistoryToggle, setTranslationHistoryToggle] =
    useState<boolean>(formField?.saveInLocalFlag);

  const { t } = useSwitchLocaleLanguage();
  // const { featureLimitListData } = useRemoteFeatureLimitList();
  const { accountInfo } = useAccountInfo();

  const sleepTimeOpt = [
    { value: 0, label: t('remoteDevice.sleepTimeOpt.doNotSet') },
    { value: 15, label: t('remoteDevice.sleepTimeOpt.15Seconds') },
    { value: 30, label: t('remoteDevice.sleepTimeOpt.30Seconds') },
    { value: 60, label: t('remoteDevice.sleepTimeOpt.1Minute') },
    { value: 600, label: t('remoteDevice.sleepTimeOpt.10Minutes') },
  ];
  const deleteScreenTime = [
    { value: 0, label: t('remoteDevice.deleteScreenTime.off') },
    { value: 10, label: t('remoteDevice.deleteScreenTime.10Minutes') },
    { value: 30, label: t('remoteDevice.deleteScreenTime.30Minutes') },
    { value: 60, label: t('remoteDevice.deleteScreenTime.1Hour') },
    { value: 720, label: t('remoteDevice.deleteScreenTime.12Hours') },
    { value: 1440, label: t('remoteDevice.deleteScreenTime.24Hours') },
  ];
  const proxyMenuOpt = [
    { value: 0, label: t('remoteDevice.proxyMenuOpt.none') },
    { value: 1, label: t('remoteDevice.proxyMenuOpt.manual') },
    { value: 2, label: t('remoteDevice.proxyMenuOpt.proxyAutoConfig') },
  ];
  const ipAddressOpt = [
    { value: 0, label: t('remoteDevice.ipAddressOpt.dHCP') },
    { value: 1, label: t('remoteDevice.ipAddressOpt.static') },
  ];

  const securityOpt = [
    { value: 0, label: t('remoteDevice.securityOpt.none') },
    { value: 1, label: t('remoteDevice.securityOpt.wep') },
    { value: 2, label: t('remoteDevice.securityOpt.wpa') },
  ];

  const {
    isCallingRemoteFeatureSetupPostApi,
    featureSetupResult,
    onClickRemoteFeatureSetupPost,
  } = useRemoteFeatureSetupPost();
  const { groupId } = useParams<IDParams>();
  // common style
  const featureLimitOpt = featureLimitListData?.map((value) => ({
    value: value.id,
    label: value.modeName,
  }));
  const classes = useStyles();

  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      group_info_id: +groupId!,
      /*eslint-disable*/
      group_pin: formField?.groupPin !== null ? formField?.groupPin : '',
      sleep_time: formField?.sleepTime,
      data_delete_time: formField?.dataDeleteTime,
      remote_setting_mode_id:
        formField?.remoteSettingModeId !== null
          ? formField?.remoteSettingModeId
          : 1,
      wifi_settings:
        formField?.wifiSettings !== null ? formField?.wifiSettings : [],
    },
  });

  // value definitions
  const SecurityTypeDefinitions = ['None', 'WEP', 'WPA/WPA2 PSK'];
  const ProxyValueDefinitions = ['None', 'Manual', 'Proxy Auto-Config'];
  const IpSettingsValueDefinitions = ['DHCP', 'Static'];

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'wifi_settings', // unique name for your Field Array
  });

  const wifiFormValueCheck = watch(['wifi_settings']);
  const formMonitor = watch();
  /*eslint-disable*/
  const handleAppend = (value: any) => {
    setnoWifiProfile(true);
    append(value);
  };
  const onSubmit: SubmitHandler<FormFieldSubmitType> = (data) => {
    // const finaleDeleteArray = deleteItems.filter((item) => item.id > 90);
    const wifiData = data.wifi_settings.map((x) => {
      const id = 0;
      if (x.settingType === 'ADD') {
        return { ...x, id };
      }
      return x;
    });
    const payload = {
      groupId: +groupId!,
      commonSettings:
        (data.group_pin === ''
          ? formField.groupPin !== null
          : data.group_pin !== formField.groupPin) ||
        data.sleep_time !== formField.sleepTime ||
        translationHistoryToggle !== formField.saveInLocalFlag ||
        data.data_delete_time !== formField.dataDeleteTime ||
        (formField?.wifiSettings === null
          ? data.wifi_settings.length !== 0
          : !arraysEqual(
              wifiFormValueCheck[0]?.map(
                ({ advanceOpt, settingType, ...rest }) => rest,
              ),
              formField?.wifiSettings?.map(({ settingType, ...rest }) => rest),
            ))
          ? {
              groupPin: data.group_pin === '' ? null : data.group_pin,
              sleepTime: data.sleep_time,
              saveInLocalFlag: translationHistoryToggle,
              dataDeleteTime: data.data_delete_time,
              wifiSettings: wifiData.concat(deleteItems),
            }
          : null,

      remoteSettingModeId:
        data.remote_setting_mode_id === formField.remoteSettingModeId
          ? null
          : formField.remoteSettingModeId === null &&
            data.remote_setting_mode_id === 1
          ? null
          : data.remote_setting_mode_id,
    };
    // GA tag starts
    // this GA event will trigger only for 6 digit pincode
    if (payload.commonSettings?.groupPin?.toString().length === 6) {
      // GA tag
      sendGAEvent('edit_txt_num', 'txt_name', 'input_pin_code');
      sendGAEvent(
        'edit_txt_num',
        'input_pin_code_value',
        payload.commonSettings.groupPin.toString(),
      );
    }

    if (payload.commonSettings?.sleepTime) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'autolock_screen');
      sendGAEvent(
        'select_list',
        'autolock_screen_value',
        payload.commonSettings.sleepTime.toString(),
      );
    }

    if (payload.commonSettings?.dataDeleteTime) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'auto_delete_time');
      sendGAEvent(
        'select_list',
        'auto_delete_time_value',
        payload.commonSettings.dataDeleteTime.toString(),
      );
    }

    if (payload.remoteSettingModeId !== null && featureLimitListData) {
      // GA tag
      sendGAEvent('select_list', 'txt_name', 'feature_limit');
      sendGAEvent(
        'select_list',
        'feature_limit_value',
        featureLimitListData[payload.remoteSettingModeId]?.modeName,
      );
    }

    if (payload.commonSettings?.wifiSettings?.length) {
      const wifiSettings = payload.commonSettings.wifiSettings;

      let proxyHostNameString = '';
      let proxyPortString = '';
      let bypassProxyForString = '';
      let pacUrlString = '';
      let ipAddressString = '';
      let gateWayString = '';
      let networkPrefixLengthString = '';
      let dns1String = '';
      let dns2String = '';
      let securityString = '';
      let proxyTypeString = '';
      let ipSettingString = '';

      wifiSettings.forEach((obj) => {
        if (obj.proxyHostName !== '') {
          proxyHostNameString = stringAppendHandler(
            proxyHostNameString,
            obj.proxyHostName,
          );
        }

        if (obj.proxyPort !== null) {
          proxyPortString = stringAppendHandler(
            proxyPortString,
            obj.proxyPort.toString(),
          );
        }

        if (obj.bypassProxy !== '') {
          bypassProxyForString = stringAppendHandler(
            bypassProxyForString,
            obj.bypassProxy,
          );
        }

        if (obj.pacUrl !== '') {
          pacUrlString = stringAppendHandler(pacUrlString, obj.pacUrl);
        }

        if (obj.ipAddress !== '') {
          ipAddressString = stringAppendHandler(ipAddressString, obj.ipAddress);
        }

        if (obj.gateway !== '') {
          gateWayString = stringAppendHandler(gateWayString, obj.gateway);
        }

        if (obj.networkPrefixLength !== null) {
          networkPrefixLengthString = stringAppendHandler(
            networkPrefixLengthString,
            obj.networkPrefixLength.toString(),
          );
        }

        if (obj.dns1 !== '') {
          dns1String = stringAppendHandler(dns1String, obj.dns1);
        }
        if (obj.dns2 !== '') {
          dns2String = stringAppendHandler(dns2String, obj.dns2);
        }

        if (obj.securityType !== null) {
          securityString = stringAppendHandler(
            securityString,
            SecurityTypeDefinitions[obj.securityType],
          );
        }

        if (obj.proxyType !== null) {
          proxyTypeString = stringAppendHandler(
            proxyTypeString,
            ProxyValueDefinitions[obj.proxyType],
          );
        }

        if (obj.wifiIPSettingType !== null) {
          ipSettingString = stringAppendHandler(
            ipSettingString,
            IpSettingsValueDefinitions[obj.wifiIPSettingType],
          );
        }
      });

      if (proxyHostNameString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'proxy_host_name');
        sendGAEvent(
          'edit_txt_char',
          'proxy_host_name_value',
          proxyHostNameString,
        );
      }

      if (proxyPortString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'proxy_port');
        sendGAEvent('edit_txt_num', 'proxy_port_value', proxyPortString);
      }

      if (bypassProxyForString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'bypass_proxy_for');
        sendGAEvent(
          'edit_txt_num',
          'bypass_proxy_for_value',
          bypassProxyForString,
        );
      }

      if (pacUrlString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'pacurl');
        sendGAEvent('edit_txt_char', 'pacurl_value', pacUrlString);
      }

      if (ipAddressString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'ip_address');
        sendGAEvent('edit_txt_num', 'ip_address_value', ipAddressString);
      }

      if (gateWayString !== '') {
        // GA tag
        sendGAEvent('edit_txt_char', 'txt_name', 'gateway');
        sendGAEvent('edit_txt_char', 'gateway_value', gateWayString);
      }

      if (networkPrefixLengthString !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'network_prefix_length');
        sendGAEvent(
          'edit_txt_num',
          'network_prefix_length_value',
          networkPrefixLengthString,
        );
      }

      if (dns1String !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'dns1');
        sendGAEvent('edit_txt_num', 'dns2_value', dns1String);
      }
      if (dns2String !== '') {
        // GA tag
        sendGAEvent('edit_txt_num', 'txt_name', 'dns2');
        sendGAEvent('edit_txt_num', 'dns1_value', dns2String);
      }

      if (securityString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'security');
        sendGAEvent('select_list', 'security_value', securityString);
      }

      if (proxyTypeString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'proxy');
        sendGAEvent('select_list', 'proxy_value', proxyTypeString);
      }

      if (ipSettingString !== '') {
        // GA tag
        sendGAEvent('select_list', 'txt_name', 'ip_setting');
        sendGAEvent('select_list', 'ip_setting_value', ipSettingString);
      }
    }

    // GA tag ends

    // eslint-disable-next-line no-console
    // @ts-ignore:next-line
    onClickRemoteFeatureSetupPost(payload);
    // console.log(payload, 'payload');
  };

  useEffect(() => {
    if (!isCallingRemoteFeatureSetupPostApi && featureSetupResult === 'OK') {
      onClose();
    }
  }, [featureSetupResult]);
  // customize react-select
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown className={classes.selectArrow} />
      </components.DropdownIndicator>
    );
  };
  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <>
        <components.Option {...props}>
          {props.children}
          {props.isSelected && <CheckIcon />}
        </components.Option>
      </>
    );
  };
  // for react select medium start
  const customStylesMd = {
    control: (provided: any, state: { isFocused: any; isDisabled: any }) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#F8F8F8' : '#fff',
      minHeight: '38px',
      height: '38px',
      borderRadius: '6px',
      boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
      border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        borderColor: '#939598',
      },
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '38px',
      padding: '0 8px',
      marginTop: '-1px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '38px',
      marginTop: '-1px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '6px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      height: '37px',
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      // color: state.isSelected ? '#313133' : '#939598',
      color: '#313133',
      ':active': {
        backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      },
      ':first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      ':last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
    }),
  };
  // for react select medium end
  // for react select sm start
  const customStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      background: '#fff',
      minHeight: '34px',
      height: '34px',
      borderRadius: '3px',
      boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
      border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        borderColor: '#939598',
      },
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
      marginTop: '-1px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '34px',
      marginTop: '-1px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '3px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      color: '#313133',
      ':active': {
        backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      },
      ':first-child': {
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
      },
      ':last-child': {
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
      },
    }),
  };

  useEffect(() => {
    wifiFormValueCheck[0]?.map((item, i) => {
      if (item.settingType !== 'ADD') {
        setValue(`wifi_settings.${i}.settingType`, 'EDIT');
      }
    });
  }, [wifiFormValueCheck[0]?.length]);

  const deleteHandler = (index: number) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const deleteHandleMOdal = (index: number) => {
    let fieldValue2 = fields[index];
    let fieldValue = wifiFormValueCheck[0][index];
    // console.log(fieldValue, 'fieldValue', fieldValue2);
    setValue(`wifi_settings.${index}.settingType`, 'DELETE');
    setValue(`wifi_settings.${index}.deleteFlag`, 1);
    // setValue(`wifi_settings.${index}.id`, fieldValue2.id);
    setValue(`wifi_settings.${index}.id`, wifiFormValueCheck[0][index].id);
    if (fieldValue.id > 0) {
      fieldValue.settingType = 'DELETE';
      fieldValue.deleteFlag = 1;
      setDeleteItems([...deleteItems, wifiFormValueCheck[0][index]]);
    }
    remove(index);
    setDeleteDialogOpen(false);
  };
  // console.log(deleteItems, 'delete Array');

  // Comparing wifiSetting Changes with DB data
  const objectsEqual = (o1: any, o2: any) =>
    Object.keys(o1)?.length === Object.keys(o2)?.length &&
    Object.keys(o1)?.every((p) => o1[p] === o2[p]);
  const arraysEqual = (a1: any, a2: any) =>
    a1?.length === a2?.length &&
    a1?.every((o: any, idx: any) => objectsEqual(o, a2[idx]));

  const EditAccessForDataProtection =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION.EDIT,
    );

  const EditAccessForFeatureLimit =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLFEATURELIMIT?.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLFEATURELIMIT.EDIT,
    );

  const EditAccessForWifi =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.EDIT,
    );

  const onClickTranslationHistoryData = () => {
    setValue('data_delete_time', formField?.dataDeleteTime);
    setTranslationHistoryToggle(!translationHistoryToggle);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Remote Feature Items */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: '30px',
            rowGap: '20px',
            marginBottom: '20px',
          }}
          className="remove-device-setup-protection-input-form"
        >
          {/* Group Pin Code */}
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION
              .VIEWDETAILS,
          ) && (
            <>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography className={classes.customInputLabel}>
                  {t('remoteDevice.label.pinCode')}
                </Typography>
                <Controller
                  control={control}
                  name="group_pin"
                  rules={{
                    pattern: {
                      value: /^(|[0-9]{6})$/,
                      message: 'PIN Code pattern',
                    },
                    maxLength: { value: 6, message: 'Max Length' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputPinCode
                      value={value}
                      onChange={onChange}
                      defaultValue="group_pin"
                      size="medium"
                      color={errors.group_pin ? 'error' : 'primary'}
                      inputProps={{ maxLength: 6 }}
                      disabled={!EditAccessForDataProtection}
                    />
                  )}

                  /*eslint-disable*/
                />
                {errors?.group_pin && (
                  <Box
                    sx={{
                      bottom: '45px !important',
                    }}
                    className={classes.newWifiErrorCard}
                  >
                    <p style={{ padding: '0', margin: '0' }}>
                      {t('remoteDevice.message.pinValidation')}
                    </p>
                  </Box>
                )}
                <p
                  style={{
                    fontWeight: 'light',
                    fontSize: '11px',
                    color: '#939598',
                    padding: '0',
                    margin: '0',
                    position: 'absolute',
                  }}
                >
                  {t('remoteDevice.message.digit')}
                </p>
              </Box>
              {/* Sleep Time Field */}
              <Box>
                <label
                  style={{
                    marginTop:
                      accountInfo.locale === LOCALE_CODE.FR ? '-15px' : '',
                  }}
                  className={classes.customInputLabel}
                >
                  {t('remoteDevice.label.autoLock')}
                </label>
                <Controller
                  name="sleep_time"
                  render={({ field: { onChange, value, name } }) => (
                    <Select
                      value={sleepTimeOpt.find((c) => c.value === value)}
                      name={name}
                      // @ts-ignore:next-line
                      onChange={(selectedOption: ICategory) => {
                        onChange(selectedOption.value);
                      }}
                      options={sleepTimeOpt}
                      isSearchable={false}
                      components={{ DropdownIndicator, Option }}
                      className={classes.reactSelect}
                      classNamePrefix="react-select"
                      styles={customStylesMd}
                      isDisabled={!EditAccessForDataProtection}
                    />
                  )}
                  control={control}
                />
              </Box>
              {/* Save Translation History Toggle */}
              <Box>
                <Typography
                  sx={{
                    margin: '12px 0px 12px',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    fontSize: '14px',
                    color: '#313133',
                    letterSpacing: '0.09px',
                  }}
                >
                  {t('remoteDevice.label.saveTranslationHistory')}
                </Typography>
                <Typography
                  className={classes.customInputLabel}
                  sx={{
                    display: 'flex !important',
                    paddingTop: '1px',
                    alignItem: 'center !important',
                    gap: '6px',
                  }}
                >
                  {t('remoteDevice.label.saveTranslationHistory')}
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        <div>{t('remoteDevice.tooltip.saveHistoryLine1')}</div>
                        <div>{t('remoteDevice.tooltip.saveHistoryLine2')}</div>
                      </>
                    }
                    arrow
                    sx={{ marginTop: 0 }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-7, -7],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '365px',
                          padding: '9px',
                          textAlign: 'left',
                          fontWeight: 300,
                          fontSize: '11px',
                          backgroundColor: '#313133',
                          lineHeight: 1.35,
                        },
                      },
                    }}
                  >
                    <Box sx={{ width: '16px', height: '16px' }}>
                      <QuestionIcon className={classes.tooltipIcon} />
                    </Box>
                  </Tooltip>
                </Typography>
                <Button
                  sx={{ padding: '0px', minWidth: '0px', marginBottom: '12px' }}
                  onClick={onClickTranslationHistoryData}
                  disableRipple
                >
                  <ToggleSwitch switchStatus={translationHistoryToggle} isBig />
                </Button>
              </Box>
              {/* Auto Delete Field */}
              <Box sx={{ alignContent: 'end' }}>
                <label
                  style={{
                    marginTop:
                      accountInfo.locale === LOCALE_CODE.DE ||
                      accountInfo.locale === LOCALE_CODE.FR
                        ? '-15px'
                        : '',
                  }}
                  className={classes.customInputLabel}
                >
                  {t('remoteDevice.label.autoDelete')}
                </label>
                <Controller
                  name="data_delete_time"
                  render={({ field: { onChange, value, name } }) => (
                    <Select
                      value={deleteScreenTime.find((c) => c.value === value)}
                      name={name}
                      // @ts-ignore:next-line
                      onChange={(selectedOption: ICategory) => {
                        onChange(selectedOption.value);
                      }}
                      options={deleteScreenTime}
                      isSearchable={false}
                      components={{ DropdownIndicator, Option }}
                      className={classes.reactSelect}
                      classNamePrefix="react-select"
                      styles={customStylesMd}
                      isDisabled={
                        !EditAccessForDataProtection ||
                        !translationHistoryToggle
                      }
                    />
                  )}
                  control={control}
                />
              </Box>
            </>
          )}
          {/* Feature Limit Field */}
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLFEATURELIMIT?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLFEATURELIMIT
              .VIEWDETAILS,
          ) && (
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  lineHeight: '20px',
                  fontSize: '14px',
                  color: '#313133',
                  letterSpacing: '0.09px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '12px',
                }}
              >
                {t('remoteDevice.label.featureLimit')}
                <Tooltip
                  placement="top"
                  title={t('remoteDevice.tooltip.feature')}
                  arrow
                  sx={{ marginTop: 0 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-7, -7],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        minWidth: '126px',
                        height: '26px',
                        textAlign: 'center',
                        fontWeight: 300,
                        fontSize: '12px',
                        backgroundColor: '#313133',
                        lineHeight: 1.35,
                      },
                    },
                  }}
                >
                  <a
                    href="https://www.pocketalk.com/pocketalk-faq/#reamaze#0#/kb/pocketalk-ventana/feature-limit"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: '0',
                      marginLeft: '7px',
                      display: 'flex',
                    }}
                  >
                    <QuestionIcon className={classes.tooltipIcon} />
                  </a>
                </Tooltip>
              </Typography>

              <Controller
                name="remote_setting_mode_id"
                render={({ field: { onChange, value, name } }) => (
                  <Select
                    value={featureLimitOpt?.find((c) => c.value === value)}
                    name={name}
                    // @ts-ignore:next-line
                    onChange={(selectedOption: ICategory) => {
                      onChange(selectedOption.value);
                    }}
                    options={featureLimitOpt}
                    isSearchable={false}
                    components={{ DropdownIndicator, Option }}
                    className={classes.reactSelect}
                    classNamePrefix="react-select"
                    styles={customStylesMd}
                    isDisabled={!EditAccessForFeatureLimit}
                  />
                )}
                control={control}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            gap: '5px',
          }}
        >
          {/* Wi-Fi Settings Text */}
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.VIEWDETAILS,
          ) && (
            <Typography
              sx={{
                fontWeight: 'bold',
                lineHeight: '20px',
                fontSize: '14px',
                color: '#313133',
              }}
            >
              {t('remoteDevice.label.wifiSet')}
            </Typography>
          )}
          {/* Add wifi link */}
          {EditAccessForWifi && (
            <IconButton
              type="button"
              aria-label="add wifi"
              sx={{
                p: '0px',
                borderRadius: '30%',
                color: '#009DE1',
                ':hover': {
                  backgroundColor: 'transparent',
                  color: '#007EB4',
                  '& svg': {
                    '& line': {
                      stroke: '#007EB4',
                    },
                  },
                },
              }}
              disabled={!EditAccessForWifi}
              disableRipple
              onClick={() => {
                handleAppend({
                  ssid: '',
                  password: '',
                  securityType: 0,
                  proxyType: 0,
                  proxyHostName: '',
                  proxyPort: '',
                  bypassProxy: '',
                  pacUrl: '',
                  wifiIPSettingType: 0,
                  ipAddress: '',
                  gateway: '',
                  networkPrefixLength: '',
                  dns1: '',
                  dns2: '',
                  advanceOpt: false,
                  settingType: 'ADD',
                  deleteFlag: 0,
                  groupInfoId: +groupId!,
                  id: 0,
                });

                // GA tag
                sendGAEvent('press_btn', 'btn_name', 'add_new_wifi');
              }}
            >
              <PlusIcon className={classes.addIcon} />
              <Typography
                fontSize="14px"
                lineHeight="20px"
                height="20px"
                pl="3px"
                sx={{
                  letterSpacing: '0.09px',
                }}
              >
                {t('remoteDevice.button.newWifi')}
              </Typography>
            </IconButton>
          )}
        </Box>
        {/* Wifi Setting Box Start */}
        <Box className={classes.wifiBoxWrapper}>
          {!fields.length ? (
            // No wifi setting
            <Box className={`${classes.noWifiBox} ${'no-wifi-box'}`}>
              {/* middle add wifi button */}
              <Typography
                sx={{
                  fontWeight: 'medium',
                  lineHeight: '24px',
                  fontSize: '16px',
                  color: '#313133',
                  marginBottom: '16px',
                  textAlign: 'center',
                }}
              >
                {t('remoteDevice.label.noWifi')}
              </Typography>
              {EditAccessForWifi && (
                <Button
                  aria-label="Update"
                  onClick={() => {
                    handleAppend({
                      ssid: '',
                      password: '',
                      securityType: 0,
                      proxyType: 0,
                      proxyHostName: '',
                      proxyPort: '',
                      bypassProxy: '',
                      pacUrl: '',
                      wifiIPSettingType: 0,
                      ipAddress: '',
                      gateway: '',
                      networkPrefixLength: '',
                      dns1: '',
                      dns2: '',
                      advanceOpt: false,
                      settingType: 'ADD',
                      deleteFlag: 0,
                      groupInfoId: +groupId!,
                      id: 0,
                    });
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'add_wifi');
                  }}
                  sx={{
                    p: '9px 16px',
                    height: '38px',
                    background: '#009DE1',
                    borderRadius: '6px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#007EB4',
                    },
                    '&.Mui-disabled': {
                      color: 'white',
                      background: '#009DE1',
                      opacity: 0.5,
                    },
                  }}
                  disableRipple
                  disabled={!EditAccessForWifi}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    {t('remoteDevice.button.addWifi')}
                  </Typography>
                </Button>
              )}
            </Box>
          ) : (
            accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI?.includes(
              FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.VIEWDETAILS,
            ) && (
              <div style={{ margin: '0 0 20px' }}>
                {/* Wifi Setting Array Start */}
                {fields.map((field, index) => {
                  return (
                    <div
                      key={field.id + index}
                      className={`${
                        classes.newWifiBox
                      } ${'remove-device-setup-new-wifi-box'}`}
                    >
                      <Box
                        className="remove-device-setup-new-wifi-sub"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-beetween',
                        }}
                      >
                        <Box
                          className={`${classes.remoteDialogInnerWrapper} ${
                            classes.gridCol3
                          } ${'remove-device-setup-new-wifi-inner'}`}
                        >
                          {/* SSID */}
                          <Box
                            className="remove-device-setup-wifi-setting"
                            sx={{ width: '200px', position: 'relative' }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <label className={classes.customInputLabel}>
                                {t('remoteDevice.label.netSSD')}
                              </label>

                              <Typography
                                className={classes.loginCardLevelMarker}
                              >
                                *
                              </Typography>
                            </Box>
                            <InputBase
                              key={field.id}
                              {...register(
                                `wifi_settings.${index}.ssid` as const,
                                {
                                  required: {
                                    value: true,
                                    message: t(
                                      'common.validationError.required',
                                    ),
                                  },
                                  maxLength: {
                                    value: 32,
                                    message: t('remoteDevice.message.maxChar'),
                                  },
                                  pattern: {
                                    value: SSID_VALIDATION,
                                    message: t('remoteDevice.message.minChar'),
                                  },
                                  // onBlur: (e) => {
                                  //   setValue(
                                  //     `wifi_settings.${index}.ssid`,
                                  //     e.target.value.trim(),
                                  //   );
                                  //   e.target.value = e.target.value.trim();
                                  // },
                                },
                              )}
                              inputProps={{
                                maxLength: 33,
                              }}
                              defaultValue={field.ssid}
                              placeholder={t(
                                'remoteDevice.placeholder.netName',
                              )}
                              className={`${classes.customInputOutline} ${
                                errors.wifi_settings?.[index]?.ssid &&
                                classes.customInputOutlineError
                              }`}
                              disabled={!EditAccessForWifi}
                            />
                            {errors.wifi_settings?.[index]?.ssid && (
                              <Box className={classes.newWifiErrorCard}>
                                <p style={{ padding: '0', margin: '0' }}>
                                  {/* Network Name/SSID Required. */}
                                  {
                                    errors.wifi_settings?.[index]?.ssid
                                      ?.message!
                                  }
                                </p>
                              </Box>
                            )}
                          </Box>
                          {/* Password */}
                          <Box
                            className="remove-device-setup-wifi-setting"
                            sx={{ width: '200px', position: 'relative' }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <label className={classes.customInputLabel}>
                                {t('remoteDevice.label.password')}
                              </label>
                              <Typography
                                className={classes.loginCardLevelMarker}
                              >
                                *
                              </Typography>
                            </Box>
                            <Controller
                              control={control}
                              name={`wifi_settings.${index}.password`}
                              defaultValue={field.password}
                              rules={{
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                minLength: {
                                  value: 8,
                                  message: t(
                                    'remoteDevice.message.leastPassChar',
                                  ),
                                },
                                maxLength: {
                                  value: 64,
                                  message: t('remoteDevice.message.maxChar64'),
                                },
                                pattern: {
                                  value: WIFI_PASSWORD_PATTERN,
                                  message: t(
                                    'remoteDevice.message.validPassword',
                                  ),
                                },
                              }}
                              render={({ field: { onChange, value } }) => (
                                <InputPassword
                                  type="text"
                                  {...field}
                                  inputProps={{
                                    maxLength: 65,
                                  }}
                                  value={value}
                                  onChange={onChange}
                                  placeholder={t('remoteDevice.label.password')}
                                  color={
                                    errors.wifi_settings?.[index]?.password
                                      ? 'error'
                                      : 'primary'
                                  }
                                  disabled={!EditAccessForWifi}
                                />
                              )}
                            />
                            {errors.wifi_settings?.[index]?.password && (
                              <Box className={classes.newWifiErrorCard}>
                                <p style={{ padding: '0', margin: '0' }}>
                                  {
                                    errors.wifi_settings?.[index]?.password
                                      ?.message!
                                  }
                                </p>
                              </Box>
                            )}
                          </Box>
                          {/* ID */}
                          <Box
                            sx={{
                              width: '20px',
                              position: 'relative',
                              display: 'none',
                            }}
                          >
                            <Controller
                              control={control}
                              name={`wifi_settings.${index}.id`}
                              defaultValue={field.id ? field.id : 0}
                              render={({ field: { onChange, value } }) => (
                                <InputPassword
                                  type="text"
                                  {...field}
                                  value={value}
                                  onChange={onChange}
                                  disabled={!EditAccessForWifi}
                                />
                              )}
                            />
                          </Box>
                          {/* Security */}
                          <Box
                            className="remove-device-setup-wifi-setting"
                            sx={{ width: '200px' }}
                          >
                            <label
                              className={classes.customInputLabel}
                              style={{ display: 'flex' }}
                            >
                              {t('remoteDevice.label.security')}
                            </label>
                            <Controller
                              name={`wifi_settings.${index}.securityType`}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Select
                                  {...field}
                                  value={securityOpt.find(
                                    (c) => c.value === value,
                                  )}
                                  name={name}
                                  // @ts-ignore:next-line
                                  onChange={(selectedOption: ICategory) => {
                                    onChange(selectedOption.value);
                                  }}
                                  options={securityOpt}
                                  isSearchable={false}
                                  components={{ DropdownIndicator, Option }}
                                  className={classes.reactSelect}
                                  classNamePrefix="react-select"
                                  styles={customStyles}
                                  isDisabled={!EditAccessForWifi}
                                />
                              )}
                              control={control}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            minWidth: '54px',
                            paddingLeft: '15px',
                            alignSelf: 'end',
                            paddingBottom: '5px',
                          }}
                        >
                          {/* Delete Button */}
                          <Tooltip
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -9],
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  minWidth: '58px',
                                  height: '26px',
                                  textAlign: 'center',
                                  fontWeight: 300,
                                  fontSize: '12px',
                                },
                              },
                            }}
                            /*eslint-disable*/
                            title={t('remoteDevice.tooltip.delete') as string}
                            placement="top"
                            arrow
                          >
                            <DeleteIcon
                              className={classes.deleteIcon}
                              onClick={() => {
                                // console.log(index, 'index');
                                EditAccessForWifi && deleteHandler(index);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                      {/* advance Option */}
                      <Box>
                        <Button
                          variant="text"
                          className={classes.advancedOptionsBtn}
                          disableRipple
                          onClick={() => {
                            setValue(
                              `wifi_settings.${index}.advanceOpt`,
                              !wifiFormValueCheck[0][index]?.advanceOpt,
                            );

                            // GA tag
                            sendGAEvent(
                              'press_btn_link',
                              'txt_name',
                              'advanced_option',
                            );
                          }}
                        >
                          {wifiFormValueCheck[0][index]?.advanceOpt ? (
                            <CaretIconDown
                              {...register(`wifi_settings.${index}.advanceOpt`)}
                            />
                          ) : (
                            <CaretIcon
                              {...register(`wifi_settings.${index}.advanceOpt`)}
                            />
                          )}
                          <Typography sx={{ ml: '7px' }}>
                            {t('remoteDevice.button.advancedOpt')}
                          </Typography>
                        </Button>
                        {/* advanced options inputs */}
                        {wifiFormValueCheck[0][index]?.advanceOpt && (
                          <Box sx={{ marginTop: '22px' }}>
                            {/* proxy section start  */}
                            <Box sx={{ marginBottom: '13px' }}>
                              <Box
                                className="remove-device-setup-wifi-setting"
                                sx={{
                                  width:
                                    accountInfo.locale === LOCALE_CODE.FR
                                      ? '300px'
                                      : accountInfo.locale === LOCALE_CODE.DE
                                      ? '280px'
                                      : '200px',
                                }}
                              >
                                <label className={classes.customInputLabel}>
                                  {t('remoteDevice.label.proxy')}
                                </label>
                                <Controller
                                  name={`wifi_settings.${index}.proxyType`}
                                  render={({
                                    field: { onChange, value, name },
                                  }) => (
                                    <Select
                                      {...field}
                                      value={proxyMenuOpt.find(
                                        (c) => c.value === value,
                                      )}
                                      name={name}
                                      options={proxyMenuOpt}
                                      // @ts-ignore:next-line
                                      onChange={(selectedOption: ICategory) => {
                                        onChange(selectedOption.value);
                                      }}
                                      isSearchable={false}
                                      components={{ DropdownIndicator, Option }}
                                      className={classes.reactSelect}
                                      classNamePrefix="react-select"
                                      styles={customStyles}
                                      isDisabled={!EditAccessForWifi}
                                    />
                                  )}
                                  control={control}
                                />
                              </Box>
                              {/* Proxy Manual Mode */}
                              {wifiFormValueCheck[0][index]?.proxyType ===
                                1 && (
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      color: '#939598',
                                      marginTop: '6px',
                                    }}
                                  >
                                    {t('remoteDevice.label.proxyUsage')}
                                  </Typography>
                                  <Box
                                    sx={{ marginTop: '13px' }}
                                    className={`${
                                      classes.remoteDialogInnerWrapper
                                    } ${
                                      classes.gridCol3
                                    } ${'remove-device-setup-grid-col-1'}`}
                                  >
                                    {/* Proxy HostName */}
                                    <Box
                                      className="remove-device-setup-wifi-setting"
                                      sx={{
                                        position: 'relative',
                                        width: '200px',
                                      }}
                                    >
                                      <Box sx={{ display: 'flex' }}>
                                        <label
                                          className={classes.customInputLabel}
                                        >
                                          {t('remoteDevice.label.proxyHost')}
                                        </label>
                                        <Typography
                                          className={
                                            classes.loginCardLevelMarker
                                          }
                                        >
                                          *
                                        </Typography>
                                      </Box>
                                      <InputBase
                                        {...register(
                                          `wifi_settings.${index}.proxyHostName`,
                                          {
                                            required: {
                                              value: true,
                                              message: t(
                                                'common.validationError.required',
                                              ),
                                            },
                                            maxLength: {
                                              value: 256,
                                              message: t(
                                                'remoteDevice.message.maxChar256',
                                              ),
                                            },
                                            pattern: {
                                              value: PROXY_NAME_VALIDATION,
                                              message: t(
                                                'remoteDevice.message.validHostname',
                                              ),
                                            },
                                          },
                                        )}
                                        inputProps={{
                                          maxLength: 257,
                                        }}
                                        defaultValue={field.proxyHostName}
                                        placeholder={t(
                                          'remoteDevice.placeholder.proxy',
                                        )}
                                        className={`${
                                          classes.customInputOutline
                                        } ${
                                          errors.wifi_settings?.[index]
                                            ?.proxyHostName &&
                                          classes.customInputOutlineError
                                        }`}
                                        disabled={!EditAccessForWifi}
                                      />
                                      {errors.wifi_settings?.[index]
                                        ?.proxyHostName && (
                                        <Box
                                          className={classes.newWifiErrorCard}
                                        >
                                          <p
                                            style={{
                                              padding: '0',
                                              margin: '0',
                                            }}
                                          >
                                            {
                                              errors.wifi_settings?.[index]
                                                ?.proxyHostName?.message!
                                            }
                                          </p>
                                        </Box>
                                      )}
                                    </Box>
                                    {/* Proxy Port */}
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '200px',
                                      }}
                                      className="remove-device-setup-wifi-setting"
                                    >
                                      <Box sx={{ display: 'flex' }}>
                                        <label
                                          className={classes.customInputLabel}
                                        >
                                          {t('remoteDevice.label.proxyPort')}
                                        </label>
                                        <Typography
                                          className={
                                            classes.loginCardLevelMarker
                                          }
                                        >
                                          *
                                        </Typography>
                                      </Box>
                                      <InputBase
                                        {...register(
                                          `wifi_settings.${index}.proxyPort`,
                                          {
                                            required: {
                                              value: true,
                                              message: t(
                                                'common.validationError.required',
                                              ),
                                            },
                                            pattern: {
                                              value: IP_PORT_VALIDATION,
                                              message: t(
                                                'remoteDevice.message.validPort',
                                              ),
                                            },
                                          },
                                        )}
                                        inputProps={{
                                          minLength: 1,
                                          maxLength: 5,
                                        }}
                                        // {...field}
                                        defaultValue={field.proxyPort}
                                        placeholder="8080"
                                        className={`${
                                          classes.customInputOutline
                                        } ${
                                          errors.wifi_settings?.[index]
                                            ?.proxyPort &&
                                          classes.customInputOutlineError
                                        }`}
                                        disabled={!EditAccessForWifi}
                                      />
                                      {errors.wifi_settings?.[index]
                                        ?.proxyPort && (
                                        <Box
                                          className={classes.newWifiErrorCard}
                                        >
                                          <p
                                            style={{
                                              padding: '0',
                                              margin: '0',
                                            }}
                                          >
                                            {
                                              errors.wifi_settings?.[index]
                                                ?.proxyPort?.message!
                                            }
                                          </p>
                                        </Box>
                                      )}
                                    </Box>
                                    {/* ByPass Proxy */}
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '200px',
                                      }}
                                      className="remove-device-setup-wifi-setting"
                                    >
                                      <Box sx={{ display: 'flex' }}>
                                        <label
                                          className={classes.customInputLabel}
                                        >
                                          {t('remoteDevice.label.bypassProxy')}
                                        </label>
                                        <Typography
                                          className={
                                            classes.loginCardLevelMarker
                                          }
                                        >
                                          *
                                        </Typography>
                                      </Box>
                                      <InputBase
                                        {...register(
                                          `wifi_settings.${index}.bypassProxy`,
                                          {
                                            required: {
                                              value: true,
                                              message: t(
                                                'common.validationError.required',
                                              ),
                                            },
                                            maxLength: {
                                              value: 256,
                                              message: t(
                                                'remoteDevice.message.maxChar256',
                                              ),
                                            },
                                            pattern: {
                                              value: BYPASS_PROXY_VALIDATION,
                                              message: t(
                                                'remoteDevice.message.ValidBypass',
                                              ),
                                            },
                                          },
                                        )}
                                        inputProps={{
                                          maxLength: 257,
                                        }}
                                        defaultValue={field.bypassProxy}
                                        placeholder={t(
                                          'remoteDevice.placeholder.myComp',
                                        )}
                                        className={`${
                                          classes.customInputOutline
                                        } ${
                                          errors.wifi_settings?.[index]
                                            ?.bypassProxy &&
                                          classes.customInputOutlineError
                                        } ${'remove-device-setup-custom-placeholder'}`}
                                        disabled={!EditAccessForWifi}
                                      />
                                      {errors.wifi_settings?.[index]
                                        ?.bypassProxy && (
                                        <Box
                                          className={classes.newWifiErrorCard}
                                        >
                                          <p
                                            style={{
                                              padding: '0',
                                              margin: '0',
                                            }}
                                          >
                                            {
                                              errors.wifi_settings?.[index]
                                                ?.bypassProxy?.message!
                                            }
                                          </p>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                              {wifiFormValueCheck[0][index]?.proxyType ===
                                2 && (
                                // PacURL
                                <Box
                                  sx={{
                                    position: 'relative',
                                    width: '200px',
                                    marginTop: '13px',
                                  }}
                                  className="remove-device-setup-wifi-setting"
                                >
                                  <Box sx={{ display: 'flex' }}>
                                    <label className={classes.customInputLabel}>
                                      {t('remoteDevice.label.pacURL')}
                                    </label>
                                    <Typography
                                      className={classes.loginCardLevelMarker}
                                    >
                                      *
                                    </Typography>
                                  </Box>
                                  <InputBase
                                    {...register(
                                      `wifi_settings.${index}.pacUrl`,
                                      {
                                        required: {
                                          value: true,
                                          message: t(
                                            'common.validationError.required',
                                          ),
                                        },
                                        maxLength: {
                                          value: 256,
                                          message: t(
                                            'remoteDevice.message.maxLength',
                                          ),
                                        },
                                        pattern: {
                                          value: PROXY_NAME_VALIDATION,
                                          message: t(
                                            'remoteDevice.message.validUrl',
                                          ),
                                        },
                                      },
                                    )}
                                    inputProps={{
                                      maxLength: 257,
                                    }}
                                    defaultValue={field.pacUrl}
                                    placeholder={t(
                                      'remoteDevice.placeholder.proxy',
                                    )}
                                    className={`${classes.customInputOutline} ${
                                      errors.wifi_settings?.[index]?.pacUrl &&
                                      classes.customInputOutlineError
                                    }`}
                                    disabled={!EditAccessForWifi}
                                  />
                                  {errors.wifi_settings?.[index]?.pacUrl && (
                                    <Box className={classes.newWifiErrorCard}>
                                      <p style={{ padding: '0', margin: '0' }}>
                                        {
                                          errors.wifi_settings?.[index]?.pacUrl
                                            ?.message!
                                        }
                                      </p>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                            {/* proxy section end  */}

                            {/* IP Setting */}
                            <Box
                              className="remove-device-setup-wifi-setting"
                              sx={{ width: '200px', marginBottom: '13px' }}
                            >
                              <label className={classes.customInputLabel}>
                                {' '}
                                {t('remoteDevice.label.ipSetting')}{' '}
                              </label>
                              <Controller
                                render={({
                                  field: { onChange, value, name },
                                }) => (
                                  <Select
                                    {...field}
                                    value={ipAddressOpt.find(
                                      (c) => c.value === value,
                                    )}
                                    name={name}
                                    options={ipAddressOpt}
                                    // @ts-ignore:next-line
                                    onChange={(selectedOption: ICategory) => {
                                      onChange(selectedOption.value);
                                    }}
                                    isSearchable={false}
                                    components={{ DropdownIndicator, Option }}
                                    className={classes.reactSelect}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    isDisabled={!EditAccessForWifi}
                                  />
                                )}
                                name={`wifi_settings.${index}.wifiIPSettingType`}
                                control={control}
                              />
                              {/* <InputSelect /> */}
                            </Box>
                            {/* ip settings hidden elements  */}
                            {wifiFormValueCheck[0][index]?.wifiIPSettingType ===
                              1 && (
                              <Box>
                                <Box
                                  sx={{ marginBottom: '13px' }}
                                  className={`${
                                    classes.remoteDialogInnerWrapper
                                  } ${
                                    classes.gridCol3
                                  } ${'remove-device-setup-grid-col-1'}`}
                                >
                                  {/* IP Address */}
                                  <Box
                                    sx={{
                                      width: '200px',
                                      position: 'relative',
                                    }}
                                    className="remove-device-setup-wifi-setting"
                                  >
                                    <Box sx={{ display: 'flex' }}>
                                      <label
                                        className={classes.customInputLabel}
                                      >
                                        {t('remoteDevice.label.ipAddress')}
                                      </label>
                                      <Typography
                                        className={classes.loginCardLevelMarker}
                                      >
                                        *
                                      </Typography>
                                    </Box>
                                    <InputBase
                                      {...register(
                                        `wifi_settings.${index}.ipAddress`,
                                        {
                                          required: {
                                            value: true,
                                            message: t(
                                              'common.validationError.required',
                                            ),
                                          },
                                          pattern: {
                                            value: IP_ADDRESS_VALIDATION,
                                            message: t(
                                              'remoteDevice.message.invalidIpAdd',
                                            ),
                                          },
                                        },
                                      )}
                                      inputProps={{
                                        maxLength: 16,
                                      }}
                                      defaultValue={field.ipAddress}
                                      placeholder="192.168.1.128"
                                      className={`${
                                        classes.customInputOutline
                                      } ${
                                        errors.wifi_settings?.[index]
                                          ?.ipAddress &&
                                        classes.customInputOutlineError
                                      }`}
                                      disabled={!EditAccessForWifi}
                                    />
                                    {errors.wifi_settings?.[index]
                                      ?.ipAddress && (
                                      <Box className={classes.newWifiErrorCard}>
                                        <p
                                          style={{ padding: '0', margin: '0' }}
                                        >
                                          {
                                            errors.wifi_settings?.[index]
                                              ?.ipAddress?.message!
                                          }
                                        </p>
                                      </Box>
                                    )}
                                  </Box>
                                  {/* GateWay */}
                                  <Box
                                    sx={{
                                      width: '200px',
                                      position: 'relative',
                                    }}
                                    className="remove-device-setup-wifi-setting"
                                  >
                                    <Box sx={{ display: 'flex' }}>
                                      <label
                                        className={classes.customInputLabel}
                                      >
                                        {t('remoteDevice.label.gateWay')}
                                      </label>
                                      <Typography
                                        className={classes.loginCardLevelMarker}
                                      >
                                        *
                                      </Typography>
                                    </Box>
                                    <InputBase
                                      {...register(
                                        `wifi_settings.${index}.gateway`,
                                        {
                                          required: {
                                            value: true,
                                            message: t(
                                              'common.validationError.required',
                                            ),
                                          },
                                          pattern: {
                                            value: IP_ADDRESS_VALIDATION,
                                            message: t(
                                              'remoteDevice.message.invalidGatway',
                                            ),
                                          },
                                        },
                                      )}
                                      inputProps={{
                                        maxLength: 16,
                                      }}
                                      defaultValue={field.gateway}
                                      placeholder="192.268.1.1"
                                      className={`${
                                        classes.customInputOutline
                                      } ${
                                        errors.wifi_settings?.[index]
                                          ?.gateway &&
                                        classes.customInputOutlineError
                                      }`}
                                      disabled={!EditAccessForWifi}
                                    />
                                    {errors.wifi_settings?.[index]?.gateway && (
                                      <Box className={classes.newWifiErrorCard}>
                                        <p
                                          style={{ padding: '0', margin: '0' }}
                                        >
                                          {
                                            errors.wifi_settings?.[index]
                                              ?.gateway?.message!
                                          }
                                        </p>
                                      </Box>
                                    )}
                                  </Box>
                                  {/* Prefix Length */}
                                  <Box
                                    sx={{
                                      width:
                                        accountInfo.locale === LOCALE_CODE.JA
                                          ? '207px'
                                          : '200px',
                                      position: 'relative',
                                    }}
                                    className="remove-device-setup-wifi-setting"
                                  >
                                    <Box sx={{ display: 'flex' }}>
                                      <label
                                        className={classes.customInputLabel}
                                      >
                                        {t('remoteDevice.label.prefixLen')}
                                      </label>
                                      <Typography
                                        className={classes.loginCardLevelMarker}
                                      >
                                        *
                                      </Typography>
                                    </Box>
                                    <InputBase
                                      {...register(
                                        `wifi_settings.${index}.networkPrefixLength`,
                                        {
                                          required: {
                                            value: true,
                                            message: t(
                                              'common.validationError.required',
                                            ),
                                          },
                                          pattern: {
                                            value: NETWORK_PREFIX,
                                            message: t(
                                              'remoteDevice.message.invlalidPrefixLen',
                                            ),
                                          },
                                        },
                                      )}
                                      inputProps={{
                                        maxLength: 3,
                                      }}
                                      placeholder="24"
                                      className={`${
                                        classes.customInputOutline
                                      } ${classes.customInputOutlineSmRight} ${
                                        errors.wifi_settings?.[index]
                                          ?.networkPrefixLength &&
                                        classes.customInputOutlineError
                                      }  ${'remove-device-setup-custom-input'}`}
                                      defaultValue={field.networkPrefixLength}
                                      name={`wifi_settings.${index}.networkPrefixLength`}
                                      disabled={!EditAccessForWifi}
                                    />
                                    {errors.wifi_settings?.[index]
                                      ?.networkPrefixLength && (
                                      <Box
                                        className={
                                          classes.newWifiErrorCardPrefixLen
                                        }
                                      >
                                        <p
                                          style={{ padding: '0', margin: '0' }}
                                        >
                                          {
                                            errors.wifi_settings?.[index]
                                              ?.networkPrefixLength?.message!
                                          }
                                        </p>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>

                                <Box
                                  sx={{ marginBottom: '15px' }}
                                  className={`${
                                    classes.remoteDialogInnerWrapper
                                  } ${
                                    classes.gridCol3
                                  } ${'remove-device-setup-grid-col-1'}`}
                                >
                                  {/* DNS 1 */}
                                  <Box
                                    sx={{
                                      width: '200px',
                                      position: 'relative',
                                    }}
                                    className="remove-device-setup-wifi-setting"
                                  >
                                    <Box sx={{ display: 'flex' }}>
                                      <label
                                        className={classes.customInputLabel}
                                      >
                                        {t('remoteDevice.label.dns1')}
                                      </label>
                                      <Typography
                                        className={classes.loginCardLevelMarker}
                                      >
                                        *
                                      </Typography>
                                    </Box>
                                    <InputBase
                                      {...register(
                                        `wifi_settings.${index}.dns1`,
                                        {
                                          required: {
                                            value: true,
                                            message: t(
                                              'common.validationError.required',
                                            ),
                                          },
                                          pattern: {
                                            value: IP_ADDRESS_VALIDATION,
                                            message: t(
                                              'remoteDevice.message.invalidDNS',
                                            ),
                                          },
                                        },
                                      )}
                                      inputProps={{
                                        maxLength: 16,
                                      }}
                                      placeholder="8.8.8.8"
                                      className={`${
                                        classes.customInputOutline
                                      } ${
                                        errors.wifi_settings?.[index]?.dns1 &&
                                        classes.customInputOutlineError
                                      }`}
                                      defaultValue={field.dns1}
                                      name={`wifi_settings.${index}.dns1`}
                                      disabled={!EditAccessForWifi}
                                    />
                                    {errors.wifi_settings?.[index]?.dns1 && (
                                      <Box className={classes.newWifiErrorCard}>
                                        <p
                                          style={{ padding: '0', margin: '0' }}
                                        >
                                          {
                                            errors.wifi_settings?.[index]?.dns1
                                              ?.message!
                                          }
                                        </p>
                                      </Box>
                                    )}
                                  </Box>
                                  {/* DNS 2 */}
                                  <Box
                                    sx={{
                                      width: '200px',
                                      position: 'relative',
                                    }}
                                    className="remove-device-setup-wifi-setting"
                                  >
                                    <Box sx={{ display: 'flex' }}>
                                      <label
                                        className={classes.customInputLabel}
                                      >
                                        {t('remoteDevice.label.dns2')}
                                      </label>
                                      <Typography
                                        className={classes.loginCardLevelMarker}
                                      >
                                        *
                                      </Typography>
                                    </Box>
                                    <InputBase
                                      {...register(
                                        `wifi_settings.${index}.dns2`,
                                        {
                                          required: {
                                            value: true,
                                            message: t(
                                              'common.validationError.required',
                                            ),
                                          },
                                          pattern: {
                                            value: IP_ADDRESS_VALIDATION,
                                            message: t(
                                              'remoteDevice.message.invalidDNS',
                                            ),
                                          },
                                        },
                                      )}
                                      inputProps={{
                                        maxLength: 16,
                                      }}
                                      placeholder="8.8.4.4"
                                      className={`${
                                        classes.customInputOutline
                                      } ${
                                        errors.wifi_settings?.[index]?.dns2 &&
                                        classes.customInputOutlineError
                                      }`}
                                      defaultValue={field.dns2}
                                      name={`wifi_settings.${index}.dns2`}
                                      disabled={!EditAccessForWifi}
                                    />
                                    {errors.wifi_settings?.[index]?.dns2 && (
                                      <Box className={classes.newWifiErrorCard}>
                                        <p
                                          style={{ padding: '0', margin: '0' }}
                                        >
                                          {
                                            errors.wifi_settings?.[index]?.dns2
                                              ?.message!
                                          }
                                        </p>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                            {/* ip settings hidden elements  */}
                          </Box>
                        )}
                        {/* advanced options inputs */}
                      </Box>
                    </div>
                  );
                })}
                <DeleteDialog
                  open={deleteDialogOpen}
                  onClose={() => {
                    setDeleteDialogOpen(false);
                    // console.log('CLOSEE');
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'cancel');
                  }}
                  onSubmit={() => {
                    deleteHandleMOdal(deleteIndex);
                    // GA tag
                    sendGAEvent('press_btn', 'btn_name', 'delete');
                    sendGAEvent('press_btn', 'txt_name', groupId?.toString());
                    sendGAEvent(
                      'press_btn',
                      'txt_name',
                      deleteIndex.toString(),
                    );
                  }}
                  icon={'helllos'}
                  title={t('remoteDevice.message.deleteConfirmation')}
                  subTitle={t('groupDetail.label.deleteCaution')}
                  btnBgColor="#E1341E"
                  btnBgColorHover="#CC240F"
                  btnTextNegative={t('groupDetail.label.cancelBtn')}
                  btnTextPositive={t('groupDetail.label.deleteBtn')}
                />
              </div>
            )
          )}
        </Box>
        {/* submit btn */}
        <Box
          className="remove-device-setup-submit-btn"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            borderTop: '1px solid #D9D9D9',
            pt: '12px',
            width: '744px',
            marginTop: '18px',
          }}
        >
          <Button
            aria-label="Cancel"
            onClick={() => {
              // GA Tag
              sendGAEvent('press_btn', 'btn_name', 'cancel');
              onClose();
            }}
            // disabled
            sx={{
              p: '10px 40px',
              // width: '131px',
              height: '44px',
              background: '#FFFFFF',
              border: '1px solid #D9D9D9',
              borderRadius: '6px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: ' rgba(0, 0, 0, 0.04)',
              },
              '&.Mui-disabled': {
                color: 'white',
                background: '#FFFFFF',
                opacity: 0.5,
              },
            }}
            disableRipple
          >
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#313133',
                fontWeight: 'medium',
              }}
            >
              {t('remoteDevice.button.cancel')}
            </Typography>
          </Button>

          <Button
            aria-label="Update"
            type="submit"
            disabled={
              !(
                formMonitor.group_pin !==
                  (formField?.groupPin !== null ? formField?.groupPin : '') ||
                translationHistoryToggle !== formField?.saveInLocalFlag ||
                formMonitor.data_delete_time !== formField?.dataDeleteTime ||
                formMonitor.sleep_time !== formField?.sleepTime ||
                formMonitor.remote_setting_mode_id !==
                  (formField?.remoteSettingModeId === null
                    ? 1
                    : formField?.remoteSettingModeId) ||
                !(formField?.wifiSettings === null
                  ? formMonitor.wifi_settings?.length === 0
                  : arraysEqual(
                      wifiFormValueCheck[0]?.map(
                        ({ advanceOpt, settingType, ...rest }) => rest,
                      ),
                      formField?.wifiSettings?.map(
                        ({ settingType, ...rest }) => rest,
                      ),
                    ))
              ) || isCallingRemoteFeatureSetupPostApi
            }
            sx={{
              p: '10px 40px',
              height: '44px',
              background: '#009DE1',
              borderRadius: '6px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#007EB4',
              },
              '&.Mui-disabled': {
                color: 'white',
                background: '#009DE1',
                opacity: 0.5,
              },
            }}
            disableRipple
            onClick={() => {
              // GA Tag
              sendGAEvent('press_btn', 'btn_name', 'save');
            }}
          >
            {isCallingRemoteFeatureSetupPostApi ? (
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#FFFFFF',
                  fontWeight: 'medium',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {t('remoteDevice.label.saving')}
                <Loader colorCode="#fff" />
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#FFFFFF',
                  fontWeight: 'medium',
                }}
              >
                {t('remoteDevice.button.save')}
              </Typography>
            )}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default NewWifi;
