import * as React from 'react';
// import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseProps } from '@mui/material';
import { sendGAEvent } from '../../hooks/useGAEvent';

// icons
import { ReactComponent as Visibility } from '../../images/icon/eye-open.svg';
import { ReactComponent as VisibilityOff } from '../../images/icon/eye-close.svg';

// mui custom style
import { useStyles } from '../Templates/CommonStyle';

const InputPinCode = (props: InputBaseProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const inputRef = React.useRef<any>(null);
  // custom styles
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  /*eslint-disable*/
  React.useEffect(() => {
    // Moving cursor to the end
    inputRef.current?.focus();
  }, [showPassword]);

  const handleClickShowPassword = () => {
    if (!props.disabled) {
      setShowPassword(!showPassword);
      // GA tag
      sendGAEvent('edit_name', 'txt_name', 'pin_code');
      sendGAEvent('edit_name', 'pin_code_value', props.value as string);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  /*eslint-disable*/
  const handleChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == '' || regex.test(e.target.value)) {
      // @ts-ignore:next-line
      props.onChange(e.target.value);
    }
  };

  return (
    <OutlinedInput
      // sx={{ width: props.width ? props.width : '200px' }}
      autoComplete="off"
      className={`${classes.passwordInput} ${
        props.size === 'medium'
          ? classes.passwordInputMd
          : classes.passwordInputSm
      } ${props.color === 'error' && classes.errorInput}`}
      type={showPassword ? 'text' : 'password'}
      value={props.value}
      onChange={handleChange}
      inputProps={props.inputProps}
      disabled={props.disabled}
      endAdornment={
        <InputAdornment position="end" sx={{ zIndex: '1000' }}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            disableRipple
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default InputPinCode;
