import { Tooltip } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as QuestionIcon } from '../../images/icon/Question_light.svg';

const QuestionTooltip = () => {
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();

  return (
    <Tooltip
      placement="top"
      title={t('remoteDevice.tooltip.feature')}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: '126px',
            height: '26px',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: '12px',
            backgroundColor: '#313133',
            lineHeight: 1.35,
          },
        },
      }}
    >
      <a
        href="https://www.pocketalk.com/pocketalk-faq/#reamaze#0#/kb/pocketalk-ventana/feature-limit"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          padding: '0',
          marginLeft: '7px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <QuestionIcon className={classes.tooltipIcon} />
      </a>
    </Tooltip>
  );
};

export default QuestionTooltip;
