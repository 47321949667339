/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles';

const manageUserDialogHeight = 'calc(100vh -  100px)';
const cardHeight = `calc(${manageUserDialogHeight} - 276px)`;
const remotDeviceSetupDialogHeight = 'calc(100vh -  50px)';
const remotDeviceSetupDialogScrollHeight = `calc(${remotDeviceSetupDialogHeight} -  20px)`;

export const useStyles = makeStyles(() => ({
  // start view user dialog
  userDialog: {
    '& .MuiDialog-paper': {
      padding: '30px 0px 40px 32px',
      // border: '1px solid #707070',
      borderRadius: '12px',
      width: '460px',
      maxHeight: '600px',
      minHeight: '600px',
    },
  },
  customCheckbox: {
    '& .MuiCheckbox': {
      color: '#009DE1',
      '&$checked': {
        color: '#009DE1',
      },
    },
  },
  CloseIcon: {
    position: 'absolute',
    right: -8,
    top: -7,
    zIndex: 1,
    color: '#939598',
    cursor: 'pointer',
  },
  DialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    lineHeight: '26px',
    fontSize: '18px',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  UserList: {
    marginTop: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    // height: '366px',
    // width: '428px',
    // overflowY: 'scroll',
    '& .MuiListItemText-root': {
      margin: '0px',
      height: '40px',
    },
    '& .MuiListItem-root': {
      padding: '0px',
      width: '396px',
    },
    '& .MuiListItem-root:not(:first-child)': {
      paddingTop: '12px',
    },
    '& .MuiListItem-root:not(:last-child)': {
      paddingBottom: '12px',
    },
    '& .MuiListItemSecondaryAction-root': {
      right: '0px',
    },
  },
  CustomScrollBar: {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '22px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E8E8E8',
      borderRadius: '16px',
      border: '8px solid #ffffff',
      backgroundClip: 'content-box',
    },
  },
  ListAvatar: {
    width: '40px',
    height: '40px',
    fontSize: 14,
  },
  // end view user dialog
  // manage dialog
  tooltipStyle: {
    width: '240px',
    padding: '8px 15px 9px',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '12px',
    backgroundColor: '#313133',
    lineHeight: '17px',
    whiteSpace: 'pre-wrap',
  },
  manageDialog: {
    '& .MuiDialog-paper': {
      padding: '30px 32px',
      borderRadius: '12px',
      maxWidth: '808px',
      minWidth: '808px',
      maxHeight: '752px',
      minHeight: '752px',
      '@media screen and (max-height:800px)': {
        maxHeight: manageUserDialogHeight,
        minHeight: manageUserDialogHeight,
      },
    },
  },
  manageDialogSwapBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '0.5px solid #D9D9D9',
    marginRight: '15px',
    marginBottom: '12px',
  },
  manageSwapBtn: {
    textTransform: 'none',
    padding: '0px 21px 11px 21px',
  },
  manageSwapBtnText: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
  },
  activeBar: {
    position: 'absolute',
    height: '2px',
    background: '#009DE1',
    margin: '0px',
    opacity: 1,
    borderTop: '0px',
    bottom: '-1px',
  },
  closeIconBtn: { padding: '7px', marginRight: '-27px', marginTop: '-32px' },
  manageDialogFooterBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    borderTop: '1px solid #D9D9D9',
    paddingTop: '12px',
  },
  manageDialogCancelBtn: {
    padding: '10px 40px',
    height: '44px',
    background: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: ' rgba(0, 0, 0, 0.04)',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#FFFFFF',
      opacity: 0.5,
    },
  },
  manageDialogCancelBtnText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#313133',
  },
  manageDialogUpdateBtn: {
    padding: '10px 40px',
    height: '44px',
    background: '#009DE1',
    borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  manageDialogUpdateBtnText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    fontWeight: 'medium',
  },
  manageDialogUpdateBtnTextUpdating: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    fontWeight: 'medium',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  manageGroupErrorCard: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '744px',
    height: '38px',
    background: '#E1341E',
    borderRadius: '3px',
    top: '-4px',
    padding: '0px 8px',
  },
  manageGroupErrorCardBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  manageGroupErrorCardBoxText: { fontSize: '14px', color: '#FFFFFF' },
  manageGroupDividerBox: {
    display: 'flex',
    width: '100%',
    gap: '12px',
    marginBottom: '8px',
  },
  manageGroupCountBlock: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  manageGroupCountBlockText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '26px',
    height: '24px',
    color: '#313133',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  manageGroupCountBlockBtn: {
    width: '30px',
    height: '30px',
    padding: '0px',
    border: '1px solid #C5C5C5',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: ' rgba(0, 0, 0, 0.04)',
    },
  },
  allSelectCardBtn: {
    padding: '0px !important',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  allSelectCardAddBtn: {
    height: '30px',
    padding: '4px 11px 4px 9px',
    background: '#FFFFFF',
    border: '1px solid #009DE1',
    borderRadius: '4px',
    textTransform: 'none',
    marginRight: '-16px',
    color: '#009DE1',
    '&:hover': {
      border: '1px solid #007EB4',
      color: '#007EB4',
      '& svg': {
        '& path': {
          stroke: '#007EB4',
        },
      },
    },
    '&.Mui-disabled': {
      color: ' #009DE1',
      background: '#FFFFFF',
      opacity: 0.5,
    },
  },
  allSelectCardBtnRemove: {
    height: '30px',
    padding: '4px 11px 4px 9px',
    background: '#FFFFFF',
    border: '1px solid #E1341E',
    borderRadius: '4px',
    textTransform: 'none',
    marginRight: '-16px',
    color: '#E1341E',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #CC240F',
      color: '#CC240F',
      '& svg': {
        '& path': {
          stroke: '#CC240F',
        },
      },
    },
    '&.Mui-disabled': {
      color: '#E1341E',
      background: '#FFFFFF',
      opacity: 0.5,
    },
  },
  allSelectCardBtnText: {
    fontSize: '12px',
    lineHeight: '14px',
    marginLeft: '4px',
  },
  allSelectCardText: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#313133',
    maxWidth: '156px',
    '@media screen and (max-width:899px)': {
      maxWidth: 'unset',
    },
    '@media screen and (max-width:560px)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  checkBoxStyle: {
    padding: '0px',
    marginRight: '16px',
  },
  checkBoxIconStyle: {
    marginTop: '2px',
    marginRight: '-2px',
  },
  bulkCheckBoxStyle: {
    marginRight: '8px',
    padding: '0px',
  },
  listItemTextBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  NoDataBoxWithDivider: {
    display: 'flex',
    width: '364px',
    height: '476px',
    '@media screen and (max-height:800px)': {
      height: cardHeight,
    },
  },
  NoDataBox: {
    width: '350px',
    height: '476px',
    '@media screen and (max-height:800px)': {
      height: cardHeight,
    },
    border: '1px solid #D9D9D9',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataMessage: { fontSize: '14px', lineHeight: '20px', color: '#313133' },
  NoDataSkeleletonBox: {
    width: '350px',
    height: '465px',
    '@media screen and (max-height:800px)': {
      height: cardHeight,
    },
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  noGroupDeviceIcon: {
    width: '53.55px',
    height: '76.58px',
    marginBottom: '14px',
  },
  // start manage user dialog
  listImageStyle: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '27px',
    fontSize: '18px',
  },
  allSelectCard: {
    padding: '12px 16px',
    marginTop: '12px',
    width: '350px',
    height: '50px',
    border: '1px solid #C5C5C5',
    borderRadius: '8px',
    backgroundColor: '#F8F8F8',
    // '&:hover': {
    //   backgroundColor: '#F8F8F8',
    // },
  },
  card: {
    height: '476px',
    '@media screen and (max-height:800px)': {
      height: cardHeight,
    },
  },
  listItemButton: {
    padding: '0px',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
    width: '350px',
    // height: '76px',
    border: '1px solid #C5C5C5',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#FFFFFF',
      opacity: 0.5,
      pointerEvents: 'auto',
    },
  },
  listItemButtonActive: {
    backgroundColor: '#F3FBFF',
    border: '1px solid #4DC9FF',
    '&:hover': {
      backgroundColor: '#F3FBFF',
    },
  },

  divider: {
    color: '#E8E8E8',
    backgroundColor: '#E8E8E8',
    opacity: 0.6,
    marginLeft: '8px',
    width: '6px',
    borderRadius: '6px',
    minHeight: '476px',
    '@media screen and (max-height:800px)': {
      minHeight: cardHeight,
    },
    borderStyle: 'none',
    borderColor: 'none',
  },
  searchInputFilter: {
    backgroundColor: '#fff',
    width: '350px',
    height: '30px',
    padding: '0px 3.37px 0px 16px',
    // border: '1px solid #D9D9D9',
    borderRadius: '6px',
    boxShadow: 'none',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#939598',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '0',
      color: '#313133',
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #939598',
      },
    },
  },
  // end manage user dialog

  // start remote device setup dialog
  remotDeviceSetupDialog: {
    '& .MuiDialog-paper': {
      padding: '10px 0',
      borderRadius: '12px',
      maxWidth: '808px',
      minWidth: '808px',
      maxHeight: '734px',
      minHeight: '734px',
      '@media screen and (max-height:820px)': {
        maxHeight: remotDeviceSetupDialogHeight,
        minHeight: remotDeviceSetupDialogHeight,
        marginTop: '25px',
      },
      margin: '100px 0 0',
      alignSelf: 'start',
    },
  },
  remotDeviceSetupDialogScroll: {
    padding: '13px 32px 20px 32px',
    // borderRadius: '12px',
    width: '100%',
    maxHeight: '714px',
    '@media screen and (max-height:820px)': {
      maxHeight: remotDeviceSetupDialogScrollHeight,
      minHeight: remotDeviceSetupDialogScrollHeight,
    },
    // margin: '100px 0 0',
    // alignSelf: 'start',
  },
  remotDeviceSetupTitle: {
    fontWeight: 'bold',
    lineHeight: '26px',
    fontSize: '18px',
  },
  fieldTitle: {
    fontWeight: 'bold',
    lineHeight: '20px',
    fontSize: '14px',
    color: '#313133',
    letterSpacing: '0.09px',
  },
  tooltipIcon: {
    width: '15px',
    height: '15px',
    '& svg': {
      width: '15px',
      height: '15px',
    },
  },
  addIconStyle: {
    p: '0px',
    borderRadius: '30%',
    color: '#009DE1',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#007EB4',
      '& svg': {
        '& line': {
          stroke: '#007EB4',
        },
      },
    },
    '&.Mui-disabled': {
      color: '#009DE1',
      opacity: 0.5,
    },
  },
  addIcon: {
    width: '9px',
    height: '9px',
    '& svg': {
      width: '9px',
      height: '9px',
    },
  },
  addButton: {
    padding: '9px 16px',
    marginTop: '14px',
    height: '38px',
    background: '#009DE1',
    borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  screenMinBtn: {
    border: '1px solid #d9d9d9',
    width: '200px',
    height: '38px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px 10px 10px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid #939598',
    },
    '&:focus': {
      border: '1px solid #939598',
    },
  },
  screenMinMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 20px #00000029',
    // width: '202.23px',
    width: '202px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    zIndex: 1300,
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '12px',
      width: '12px',
      height: '12px',
      backgroundColor: '#fff',
      // borderRadius: '1px',
      // transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '8.5px 10px 10.5px',
        width: '200px',
        height: '36px',
        fontSize: '13px',
        justifyContent: 'start',
        '&:hover': {
          backgroundColor: '#f8f8f8',
          color: '#313131',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
      '& .MuiListItem-root:last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
      '& .MuiListItemSecondaryAction-root': {
        right: '10px',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px -3px 6px #00000029',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },

  featureLimitBtn: {
    border: '1px solid #d9d9d9',
    width: '230px',
    height: '38px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px 10px 10px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid #939598',
    },
    '&:focus': {
      border: '1px solid #939598',
    },
  },
  featureLimitMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 20px #00000029',
    width: '232px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    zIndex: 1300,
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '12px',
      width: '12px',
      height: '12px',
      backgroundColor: '#fff',
      // borderRadius: '1px',
      // transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '8.5px 10px 10.5px',
        width: '230px',
        height: '36px',
        fontSize: '13px',
        justifyContent: 'start',
        '&:hover': {
          backgroundColor: '#f8f8f8',
          color: '#313131',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
      '& .MuiListItem-root:last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
      '& .MuiListItemSecondaryAction-root': {
        right: '10px',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px -3px 6px #00000029',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },

  autoDeleteField: {
    backgroundColor: '#fff',
    width: '100px',
    height: '38px',
    padding: '8px 10px 10px 10px',
    // border: '1px solid #D9D9D9',
    borderRadius: '6px',
    boxShadow: 'none',
    '& .MuiOutlinedInput-input': {
      textAlign: 'right',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#939598',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '0',
      color: '#313133',
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #939598',
      },
    },
  },
  noWifiBox: {
    margin: '12px 0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '744px',
    height: '286px',
    border: '1px solid #D9D9D9',
    '@media screen and (max-height:820px)': {
      height: `calc(${remotDeviceSetupDialogHeight} - 448px)`,
    },
  },
  selectArrow: {
    marginTop: '2px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5,
    },
  },
  textEllipse: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  textBreak: {
    wordBreak: 'break-all',
    //  overflowWrap: 'break-word',
  },

  textBreakTwoLines: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  listWindowContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // for firefox scrollbar disable
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },

  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'visible ',
  },

  // end remote device setup dialog
  // startAgrement Update
  userAgrementUpdateDialog: {
    '& .MuiDialog-container': {
      marginTop: '180px',
      height: '231px',
    },
    '& .MuiDialog-paper': {
      padding: '24px',
      borderRadius: '12px',
      maxWidth: '460px',
      minWidth: '460px',
      maxHeight: 'unset',
      minHeight: '231px',
      '@media screen and (max-width:600px)': {
        maxWidth: '460px',
        minWidth: 'unset',
        maxHeight: 'unset',
        minHeight: 'unset',
      },
    },
  },
  userAgrementUpdateDialogTitle: {
    lineHeight: '26px',
    textAlign: 'left',
    fontSize: '18px',
    color: '#313133',
    fontWeight: 'bold',
  },
  userAgrementUpdateDialogText: {
    fontSize: '14px',
    color: '#313133',
    fontWeight: '300',
    marginTop: '10px',
  },

  fullWidthButton: {
    marginTop: '20px',
    backgroundColor: '#009DE1',
    height: '44px',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 22px',
    color: '#fff',
    position: 'relative',
    textTransform: 'none',
    borderRadius: '6px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  userAgrementUpdateReLoginDialog: {
    '& .MuiDialog-container': {
      marginTop: '180px',
      height: '231px',
    },
    '& .MuiDialog-paper': {
      padding: '24px',
      borderRadius: '12px',
      maxWidth: '460px',
      minWidth: '460px',
      maxHeight: 'unset',
      minHeight: '190px',
      '@media screen and (max-width:600px)': {
        maxWidth: '460px',
        minWidth: 'unset',
        maxHeight: 'unset',
        minHeight: 'unset',
      },
    },
  },
  // End Agrement Update

  // welcome dialog
  welcomeDialog: {
    '& .MuiDialog-container': {
      marginTop: '190px',
      height: '295px',
    },
    '& .MuiDialog-paper': {
      padding: '7.6px 20px 16px',
      borderRadius: '8px',
      maxWidth: '600px',
      minWidth: '600px',
      maxHeight: 'unset',
      minHeight: '295px',
      '@media screen and (max-width:800px)': {
        maxWidth: '95%',
        minWidth: '95%',
        maxHeight: 'unset',
        minHeight: '315px',
      },
      '@media screen and (max-width:400px)': {
        minHeight: '390px',
      },
    },
  },
  dialogContainer: {
    borderBottom: '1px solid #E5E5E5',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  welcomeLogo: {
    height: '25px',
    width: '108px',
    marginBottom: '29px',
    marginTop: '22.4px',
  },
  welcomeText: {
    fontSize: '16px',
    color: '#313133',
  },
  welcomeTextSecondary: {
    fontSize: '14px',
    color: '#939598',
    marginLeft: '2.5px',
    marginRight: '2.5px',
    lineHeight: '20px',
    position: 'absolute',
    top: '120px',
    '@media screen and (max-width:600px)': {
      top: '135px',
    },
    '@media screen and (max-width:400px)': {
      top: '140px',
    },
  },
}));
