import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';

type Props = {
  id: string;
  className: string;
  applyButtonDisable: boolean;
  text: string;
  onClick: () => void;
};

const ApplyButton = React.memo<Props>((props): JSX.Element => {
  const [fieldErrors, setFieldErrors] = useState<boolean>(false);

  const remoteSetupErrors = useSelector(
    (state: RootState) => state.remoteSetupError,
  );

  useEffect(() => {
    const { savePinEnable, saveWifiSettings } = remoteSetupErrors;

    if (Object.keys(remoteSetupErrors).length === 0) {
      setFieldErrors(false);

      return;
    }

    let hasPinCodeError = false;
    let hasWifiSettingsError = false;

    Object.entries(remoteSetupErrors.errors).forEach(([key]) => {
      if (key.startsWith('groupPin') && savePinEnable) {
        hasPinCodeError = true;
      }

      if (key.startsWith('wifiSettings') && saveWifiSettings) {
        hasWifiSettingsError = true;
      }
    });

    let flag = false;
    Object.entries(remoteSetupErrors.errors).forEach(([key, error]) => {
      if (remoteSetupErrors.errors[key] && !error.ignore) {
        flag = true;
      }

      if (
        (key.startsWith('groupPin') &&
          !savePinEnable &&
          !hasWifiSettingsError) ||
        (key.startsWith('wifiSettings') &&
          !saveWifiSettings &&
          !hasPinCodeError)
      ) {
        flag = false;
      }
    });
    setFieldErrors(flag);
  }, [
    remoteSetupErrors,
    remoteSetupErrors.saveWifiSettings,
    props.applyButtonDisable,
  ]);

  return (
    <Button
      id={props.id}
      type="submit"
      variant="contained"
      className={props.className}
      disabled={props.applyButtonDisable || fieldErrors}
      onClick={() => {
        if (!props.applyButtonDisable && !fieldErrors) {
          props.onClick();
        }
      }}
    >
      {props.text}
    </Button>
  );
});

export default ApplyButton;
