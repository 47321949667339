/* eslint-disable no-nested-ternary */
import React, { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/de'; // Import the German locale
import SimpleBarReact from 'simplebar-react';
import { Tooltip, Box } from '@mui/material';
import { TFunction } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as DataProtectionGroupListIcon } from '../../images/icon/grouptable-data-protection.svg';
import { ReactComponent as DataProtectionGroupListSelectedIcon } from '../../images/icon/grouptable-data-protection-selected.svg';
import { ReactComponent as FeatureLimitGroupListIcon } from '../../images/icon/grouptable-featurelimit.svg';
import { ReactComponent as FeatureLimitGroupListSelectedIcon } from '../../images/icon/grouptable-featurelimit-selected.svg';
import { ReactComponent as WifiSettingGroupListIcon } from '../../images/icon/grouptable-wi-fi-setting.svg';
import { ReactComponent as WifiSettingGroupListSelectedIcon } from '../../images/icon/grouptable-wi-fi-setting-selected.svg';
import { ReactComponent as SaveHistoryIcon } from '../../images/icon/table-save-history.svg';
import { ReactComponent as SaveHistorySelectedIcon } from '../../images/icon/table-save-history-selected.svg';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import { useStyles } from '../Templates/CommonStyle';

import {
  TableRowData,
  GROUP_LIST_TABLE_DATA_KEY,
  TableHeaderData,
} from '../../types/datatable/dataTable.d';

import styles from './TableBodyContainer.module.scss';
import IconWithTooltip from './iconWithTooltip';

dayjs.extend(utc);

const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const os = ['Windows', 'Linux', 'Mac']; // add your OS values
const osName = os.find((v) => navigator.appVersion.indexOf(v) >= 0);

const { userAgent } = window.navigator;
let browserName;

if (/chrome|chromium|crios/i.exec(userAgent)) {
  browserName = 'chrome';
} else if (/firefox|fxios/i.exec(userAgent)) {
  browserName = 'firefox';
} else if (/safari/i.exec(userAgent)) {
  browserName = 'safari';
} else {
  browserName = 'No browser detection';
}
const isFirefox = browserName === 'firefox';

type Props = {
  rows: TableRowData[];
  columnLength: number;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  columnsWidthSum?: number;
  columns?: TableHeaderData[];
  dataShow: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
  handleSelectClick: (id: string | number, device: number) => void;
  selectedGroupIds: (number | string)[];
};

type TooltipProps = {
  title: string;
  children: React.ReactElement;
};

const createTdData = (
  t: TFunction,
  localeLanguage: string,
  key: string,
  rowData: TableRowData,
  tooltipEnabled: boolean,
  setTooltipEnabled: (val: boolean) => void,
) => {
  const handleShouldShowTooltip = (event: MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Clone the element
    const clone = element.cloneNode(true) as HTMLElement;

    // Set styles for the clone
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';

    // Append the clone to the DOM
    element.parentNode?.appendChild(clone);

    // Set the element's overflow to visible
    element.style.overflow = 'visible';

    // Get the scroll width of the clone
    const scrollWidth = clone.getBoundingClientRect().width;

    // Remove the clone from the DOM
    element.parentNode?.removeChild(clone);

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';

    if (scrollWidth > clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  };

  const StyledTooltip = React.memo<TooltipProps>(
    (props): JSX.Element => (
      <Tooltip
        placement="top"
        title={props.title}
        onMouseEnter={handleShouldShowTooltip}
        disableHoverListener={!tooltipEnabled}
        arrow
        sx={{ marginTop: 0 }}
        PopperProps={{
          onClick: handleTooltipClick,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-7, -7],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: '240px',
              padding: '8px 15px 9px',
              textAlign: 'center',
              fontWeight: 300,
              fontSize: '12px',
              backgroundColor: '#313133',
              lineHeight: '17px',
              whiteSpace: 'pre-wrap',
            },
          },
        }}
      >
        {props.children}
      </Tooltip>
    ),
  );

  switch (key) {
    case GROUP_LIST_TABLE_DATA_KEY.GROUP_NAME:
      return (
        <StyledTooltip title={rowData.data.group_name as string}>
          <div
            className={styles['link-style-deviceList']}
            style={{
              height: '20px',
            }}
          >
            <span>{rowData.data.group_name}</span>
          </div>
        </StyledTooltip>
      );
    case GROUP_LIST_TABLE_DATA_KEY.LAST_UPDATED:
      return (
        /*eslint-disable*/
        <Box
          sx={{
            paddingRight: isSafari
              ? '20px'
              : isFirefox && osName !== 'Linux'
              ? '12px'
              : '8px',
          }}
        >
          {rowData.data[key] === 'Invalid Date'
            ? '-'
            : localeLanguage === LOCALE_CODE.JA
            ? dayjs(rowData.data[key]).format('YYYY年MM月DD日')
            : dayjs(rowData.data[key])
                .locale(localeLanguage)
                .format('MMM DD, YYYY')}
        </Box>
      );

    case GROUP_LIST_TABLE_DATA_KEY.STATUS_OF_REMOTE_SETTINGS:
      const remoteSettings: {
        dataProtection: number;
        featureLimit: number;
        wifiSetting: number;
        saveTransHistory: number;
      } = rowData.data.statusOfRemoteSettings;

      return (
        <Box
          className={
            isSafari
              ? styles['status-of-remote-settings-isSafari']
              : styles['status-of-remote-settings']
          }
        >
          <IconWithTooltip
            Icon={
              remoteSettings.dataProtection === 1
                ? DataProtectionGroupListSelectedIcon
                : DataProtectionGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.dataProtection')}
          />
          <IconWithTooltip
            Icon={
              remoteSettings.saveTransHistory === 1
                ? SaveHistorySelectedIcon
                : SaveHistoryIcon
            }
            tooltipTitle={t('groupList.tooltip.saveTranslationHistory')}
          />
          <IconWithTooltip
            Icon={
              remoteSettings.featureLimit === 1
                ? FeatureLimitGroupListSelectedIcon
                : FeatureLimitGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.featureLimit')}
          />
          <IconWithTooltip
            Icon={
              remoteSettings.wifiSetting === 1
                ? WifiSettingGroupListSelectedIcon
                : WifiSettingGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.wifiSet')}
          />
        </Box>
      );

    default:
      return rowData.data[key];
  }
};

const BulkActionTableBodyContainer = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const { t } = useSwitchLocaleLanguage();
  const { localeLanguage } = useLocale();

  const groupListWidth: { [key: string | number]: string | number } = {
    select: 6,
    groupName: 12,
    numberOfAccount: 16,
    numberOfDevice: 20,
    statusOfRemoteSettings: 23,
    modifiedDt: 15,
  };

  const navigate = useNavigate();

  return (
    <SimpleBarReact
      // className={`${'table-body-simplebar '}`}
      style={{
        padding: '0px',
        maxHeight: '604px',
      }}
      ref={props.simpleBarRef}
    >
      <div className={styles.tableRowContainer}>
        {props.rows.map((rowData: TableRowData, i) => {
          return (
            <tr
              key={rowData.id}
              className={`${styles.bulkActionGroupTableDataRow} ${styles.tableDataRow}`}
            >
              <td
                style={{
                  width: `${groupListWidth.select}%`,
                  opacity: props.isCallingApi && !props.dataShow ? '.25' : '',
                }}
              >
                <Checkbox
                  onClick={() => {
                    props.handleSelectClick(
                      rowData.id,
                      rowData.data.number_of_device,
                    );
                  }}
                  checked={props.selectedGroupIds.includes(rowData.id)}
                  tabIndex={-1}
                  icon={<CheckBox className={classes.checkBoxIconStyle} />}
                  checkedIcon={<CheckedCheckBox />}
                  className={classes.checkBoxStyle}
                />
              </td>
              {Object.keys(rowData.data).map((key, index) => {
                const indexStr = String(index);

                return (
                  <td
                    key={`${rowData.data[key]}${indexStr}`}
                    style={{
                      width: `${groupListWidth[key]}%`,
                      opacity:
                        props.isCallingApi && !props.dataShow ? '.25' : '',
                    }}
                  >
                    <Box>
                      {createTdData(
                        t,
                        localeLanguage,
                        key,
                        rowData,
                        tooltipEnabled,
                        setTooltipEnabled,
                      )}
                    </Box>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </div>
    </SimpleBarReact>
  );
});

export default BulkActionTableBodyContainer;
