import {
  IP_ADDRESS_VALIDATION,
  IP_PORT_VALIDATION,
  NETWORK_PREFIX,
  PROXY_NAME_VALIDATION,
  SSID_VALIDATION,
  WIFI_PASSWORD_PATTERN,
} from '../../constants/constants';

/*eslint-disable*/
export const createValidationRules = (t: any) => {
  return {
    proxyHostNameRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      maxLength: {
        value: 256,
        message: t('remoteDevice.message.maxChar256'),
      },
      pattern: {
        value: PROXY_NAME_VALIDATION,
        message: t('remoteDevice.message.validHostname'),
      },
    },
    proxyPortRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      pattern: {
        value: IP_PORT_VALIDATION,
        message: t('remoteDevice.message.validPort'),
      },
    },
    bypassProxyRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      maxLength: {
        value: 256,
        message: t('remoteDevice.message.maxChar256'),
      },
      pattern: {
        value: PROXY_NAME_VALIDATION,
        message: t('remoteDevice.message.validHostname'),
      },
    },
    pacUrlRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      maxLength: {
        value: 256,
        message: t('remoteDevice.message.maxChar256'),
      },
      pattern: {
        value: PROXY_NAME_VALIDATION,
        message: t('remoteDevice.message.validUrl'),
      },
    },
    ipAddressRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      pattern: {
        value: IP_ADDRESS_VALIDATION,
        message: t('remoteDevice.message.invalidIpAdd'),
      },
    },
    gatewayRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      pattern: {
        value: IP_ADDRESS_VALIDATION,
        message: t('remoteDevice.message.invalidGatway'),
      },
    },
    networkPrefixLengthRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      pattern: {
        value: NETWORK_PREFIX,
        message: t('remoteDevice.message.invlalidPrefixLen'),
      },
    },
    dnsRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      pattern: {
        value: IP_ADDRESS_VALIDATION,
        message: t('remoteDevice.message.invalidDNS'),
      },
    },
    ssidRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      maxLength: {
        value: 32,
        message: t('remoteDevice.message.maxChar'),
      },
      pattern: {
        value: SSID_VALIDATION,
        message: t('remoteDevice.message.minChar'),
      },
    },
    passwordRules: {
      required: {
        value: true,
        message: t('common.validationError.required'),
      },
      minLength: {
        value: 8,
        message: t('remoteDevice.message.leastPassChar'),
      },
      maxLength: {
        value: 64,
        message: t('remoteDevice.message.maxChar64'),
      },
      pattern: {
        value: WIFI_PASSWORD_PATTERN,
        message: t('remoteDevice.message.validPassword'),
      },
    },
  };
};
