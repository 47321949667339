import callApiUtil from '../utils/callApiUtil';
import type { GroupListApiResponse } from '../types/apis/groupListApi';
import { API_URL, REQUEST_HEADER_KEY } from '../constants/constants';

export type TableDataPayload = {
  searchText: string;
  sortDirection: string;
  colName: string;
  rowLimitPerPage: number;
  currentPageNumber: number;
};

export type TableDataBulkActionPayload = {
  searchText: string;
  sortDirection: string;
  colName: string;
  rowLimitPerPage: number;
  currentPageNumber: number;
  selectedGroupIds: (number | string)[];
};

/**
 * User List Acquisition API Result Code
 */
export const GROUP_LIST_API_RESULT_CODE = {
  SUCCESSFULLY_FETCHED: 'SUCCESSFULLY_FETCHED',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  INFO_NO_DATA: 'INFO_NO_DATA',
  INFO_NO_USER: 'INFO_NO_USER',
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // Unknown error
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

/**
 * User list acquisition error type
 */
export type GROUP_LIST_API_ERROR_TYPE =
  (typeof GROUP_LIST_API_RESULT_CODE)[keyof typeof GROUP_LIST_API_RESULT_CODE];

/**
 * User list acquisition API call
 *
 * @param accessKey access key
 * @returns Response from the user list acquisition API
 */
const callGroupListApi = (
  // accessKey: string,
  tableDataPayload: TableDataPayload,
): Promise<GroupListApiResponse> => {
  // It is an error if you do not pass an empty request parameter
  // const jsonParam = {};

  // Send request (GET)
  const {
    searchText,
    sortDirection,
    colName,
    rowLimitPerPage,
    currentPageNumber,
  } = tableDataPayload;

  const sortD = sortDirection === 'none' ? '' : sortDirection;
  /* eslint-disable */

  const response = callApiUtil
    .get<GroupListApiResponse>(
      `${
        API_URL.GROUP_LIST_API
      }?searchText=${searchText}&sortDirection=${sortD.toLowerCase()}&colName=${colName}&rowLimitPerPage=${rowLimitPerPage}&currentPageNumber=${
        currentPageNumber - 1
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          // [`${REQUEST_HEADER_KEY.ACCESS_KEY}`]: accessKey,
        },
      },
    )
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return { message: error.message };
    });

  return response;
};

export default callGroupListApi;
