// 単体テストで確認するので無効化しても問題ない
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable */

import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  API_REQUEST_CONFIG,
  SERVER_MESSAGE,
} from '../constants/constants';
import appWrapper from './appWrapper';
import { decryptText } from './utility';
/** API呼び出し Util */

/**
 * 対象がobjectか返す
 *
 * @param target 調査対象
 * @returns true：object / false: null or object以外
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const isObject = (target: any): target is object =>
  typeof target === 'object' && target !== null;

/**
 * 参考：https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
 *
 * @param str スネークケースをキャメルケースに変更する
 * @returns スネークケースをキャメルケースに変換したもの
 */
const convertSnakeCaseToCamelCase = (str: string | undefined): string => {
  if (str === undefined) {
    return '';
  }
  const camelCaseStr = str
    .replace(/(_.)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/_/g, '');

  return camelCaseStr;
};

/**
 * キーの値をスネークケースからキャメルケースに変換して返す
 *
 * @param data 変換対象のobject
 * @returns キーの値をスネークケースからキャメルケースに変換して返す
 */
const mapKeysToCamelCase = (data: unknown): Record<string, unknown> => {
  const obj = data as Record<string, unknown>;

  const keyValues = Object.keys(obj).map((key) => {
    const newKey = convertSnakeCaseToCamelCase(key) || key;

    return { [newKey]: obj[key] };
  });

  const newObject = keyValues.reduce((result, current) => {
    const keys = Object.keys(current);
    if (keys.length === 0) {
      return result;
    }
    const key = keys[0];
    result[key] = current[key];

    return result;
  }, {});

  return newObject;
};

/**
 *
 * @param data
 * @param callback
 * @returns
 */
const mapValues = (data: unknown, callback: any): Record<string, unknown> => {
  const obj = data as Record<string, unknown>;
  const keys = Object.keys(obj);
  const result: Record<string, unknown> = {};
  for (let i = 0, len = keys.length; i < len; i += 1) {
    result[keys[i]] = callback(obj[keys[i]], keys[i]);
  }

  return result;
};

/**
 *
 * @param data
 * @param callback
 * @returns
 */
const responseMapKeysDeep = (data: unknown, callback: any): unknown => {
  if (Array.isArray(data)) {
    return data.map((innerData) => responseMapKeysDeep(innerData, callback));
  }
  if (isObject(data)) {
    return mapValues(mapKeysToCamelCase(data), (val: unknown) =>
      responseMapKeysDeep(val, callback),
    );
  }

  return data;
};

/**
 *
 * @param data
 * @returns
 */
const mapKeysCamelCase = (data: unknown): unknown =>
  responseMapKeysDeep(data, (_value: unknown, key: string | undefined) =>
    convertSnakeCaseToCamelCase(key),
  );

/**
 *
 */
const baseURL = `${
  process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'
}`;


const cookies = new Cookies();

/* eslint-disable */

axios.defaults.headers.common.authorization = localStorage.getItem(
  'persist:root',
)
  ? `Console-Token ${decryptText(
      JSON.parse(
        JSON.parse(localStorage.getItem('persist:root')!).iotAccessKey,
      )!.iotAccessKey,
    )}`
  : '';

const callApiUtil = axios.create({
  withCredentials: true,
  baseURL,
  timeout: API_REQUEST_CONFIG.DEFAULT_TIMEOUT,
});
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.xsrfCookieName = 'csrfToken';

// callApiUtil.interceptors.request.use(
//   (config) => {
//     if (config && config.headers) {
//       config.headers.authorization = cookies.get('authToken')
//         ? `Console-Token ${cookies.get('authToken')}`
//         : '';
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

callApiUtil.interceptors.response.use(
  (response) => {
    const { data } = response;
    const convertedData = mapKeysCamelCase(data);
    if (response !== undefined) {
      const oldToken = cookies.get('oldAuthToken');
      if (
        oldToken &&
        'Console-Token ' + oldToken ===
          response.config.headers?.authorization &&
        response.data.message === SERVER_MESSAGE.INVALID_TOKEN
      ) {
        appWrapper.setResp({ message: 'SKIP' });
      } else {
        appWrapper.setResp({
          message: response?.data?.message,
          url: response?.config?.url,
        });
      }
    }
    return { ...response, data: convertedData };
    // }
  },
  (error) => {
    // console.dir(error);
    if (error.response !== undefined) {
      const oldToken = cookies.get('oldAuthToken');
      if (
        oldToken &&
        'Console-Token ' + oldToken === error?.config?.headers?.authorization &&
        error.response.data.message === SERVER_MESSAGE.INVALID_TOKEN
      ) {
        appWrapper.setResp({ message: 'SKIP' });
      } else {
        appWrapper.setResp({
          message: error.response?.data.message,
          url: error?.response?.config?.url,
        });
        appWrapper.setValue(error.response?.data);
      }
    } else {
      appWrapper.setResp({ message: error.message });

      return Promise.reject({ message: SERVER_MESSAGE.NO_INTERNET });
    }
    return error.response;
  },
);

export default callApiUtil;
