import { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import BulkActionTemplate from '../Templates/BulkActionTemplate';
import GroupListBulkActionsSelectTarget from '../Modecules/GroupListBulkActionsSelectTarget';
import GroupListBulkActionsSetAction from '../Modecules/GroupListBulkActionsSetAction';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useGetUpdateUserInfo from '../../hooks/useGetUpdateUserInfo';
import { sendGAEvent } from '../../hooks/useGAEvent';

const GroupListBulkActionsPage = (): JSX.Element => {
  // update user info hook
  const { callGetUpdateUserInfo } = useGetUpdateUserInfo();
  const { t } = useSwitchLocaleLanguage();
  const [isSelectTargetCard, setIsSelectTargetCard] = useState<boolean>(true);
  const [selectedGroupIds, setSelectedGroupIds] = useState<(number | string)[]>(
    [],
  );
  const [selectedDeviceCount, setSelectedDeviceCount] = useState<number>(0);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isSomeSelected, setIsSomeSelected] = useState<boolean>(false);
  const [isBulkActionSuccess, setIsBulkActionSuccess] =
    useState<boolean>(false);
  const onClickNextButton = () => {
    sendGAEvent('press_btn', 'btn_name', 'next');
    setIsSelectTargetCard(false);
    setIsBulkActionSuccess(false);
    callGetUpdateUserInfo();
  };
  const onClickBackButton = () => {
    sendGAEvent('press_btn', 'btn_name', 'back');
    setIsSelectTargetCard(true);
  };

  useEffect(() => {
    if (isBulkActionSuccess) {
      setIsSelectTargetCard(true);
      setSelectedGroupIds([]);
      setSelectedDeviceCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkActionSuccess]);

  return (
    <BulkActionTemplate titleText={t('bulkActionPage.bulkActions')}>
      <Container
        style={{
          paddingRight: '0',
          paddingLeft: '0',
          maxWidth: '100%',
        }}
      >
        {isSelectTargetCard && (
          <GroupListBulkActionsSelectTarget
            onClickNextButton={onClickNextButton}
            setIsAllSelected={setIsAllSelected}
            setIsSomeSelected={setIsSomeSelected}
            setSelectedGroupIds={setSelectedGroupIds}
            selectedGroupIds={selectedGroupIds}
            isAllSelected={isAllSelected}
            isSomeSelected={isSomeSelected}
            setSelectedDeviceCount={setSelectedDeviceCount}
            selectedDeviceCount={selectedDeviceCount}
          />
        )}
        <Box sx={{ display: isSelectTargetCard ? 'none' : '' }}>
          <GroupListBulkActionsSetAction
            onClickBackButton={onClickBackButton}
            selectedDeviceCount={selectedDeviceCount}
            selectedGroupIds={selectedGroupIds}
            setIsBulkActionSuccess={setIsBulkActionSuccess}
            isBulkActionSuccess={isBulkActionSuccess}
          />
        </Box>
      </Container>
    </BulkActionTemplate>
  );
};

export default GroupListBulkActionsPage;
