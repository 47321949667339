import React from 'react';
import {
  Box,
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as WarningOrangeIcon } from '../../images/icon/warning-orange.svg';
import Loader from './Loader';
import { sendGAEvent } from '../../hooks/useGAEvent';

type Props = {
  open: boolean;
  onClose: () => void;
  onClickOverwriteBtn: () => void;
  isCallingApi: boolean;
};

const BulkActionOverwriteDialog = React.memo<Props>((props): JSX.Element => {
  const { open, isCallingApi, onClose, onClickOverwriteBtn } = props;

  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();

  return (
    <Dialog open={open} className={classes.bulkActionOverwriteDialog}>
      <Box className={`${classes.dialogBox} res-dialog`}>
        <Box className={`${classes.dialogIcon} res-dialog-icon`}>
          <WarningOrangeIcon />
        </Box>
        <Box sx={{ width: '100%' }}>
          <DialogTitle className="res-dialog-title">
            <Box sx={{ maxWidth: '244px' }} className="res-dialog-title-text">
              {t('bulkActionConfirmationModal.heading')}
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                sendGAEvent('press_btn', 'btn_name', 'exit');
                onClose();
              }}
              className={classes.bulkActionOverwriteDialogCloseIcon}
              disabled={isCallingApi}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogActions
            sx={{ marginTop: '16px !important' }}
            className={`${classes.dialogActionsCommon} res-dialog-btns`}
          >
            <Button
              className={classes.btnAction}
              disabled={isCallingApi}
              disableRipple
              sx={{ minWidth: '82px', padding: '10px 23.5px !important' }}
              onClick={() => {
                sendGAEvent('press_btn', 'btn_name', 'cancel');
                onClose();
              }}
            >
              {t('bulkActionConfirmationModal.cancel')}
            </Button>
            <Button
              autoFocus
              className={`${classes.btnAction} ${classes.btnActionPrimary}`}
              onClick={onClickOverwriteBtn}
              disabled={isCallingApi}
              disableRipple
              sx={{ minWidth: '84px' }}
            >
              {isCallingApi ? (
                <Loader colorCode="#fff" />
              ) : (
                t('bulkActionConfirmationModal.overwrite')
              )}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
});

export default BulkActionOverwriteDialog;
