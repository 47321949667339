/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { BulkActionSetupApiRequestParam } from '../types/apis/remoteFeatureSetupApi.d';
import callApiUtil from '../utils/callApiUtil';
import useSnackbar from './useSnackbar';
import { API_URL, SERVER_MESSAGE } from '../constants/constants';
import useRedirectDialog from './useRedirectDialog';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

export type ProgressDataProps = {
  percentage: number;
  groupCount: number;
  deviceCount: number;
};

type useBulkActionSetup = {
  onClickBulkActionOverwrite: (
    requestParams: BulkActionSetupApiRequestParam,
  ) => void;
  setIsBulkProcessApiCalling: Dispatch<SetStateAction<boolean>>;
  setOpenBulkProgressDialog: Dispatch<SetStateAction<boolean>>;
  setprogressEventMessage: Dispatch<SetStateAction<string>>;
  setResponseMessage: Dispatch<SetStateAction<string>>;
  setProgressErrorMessage: Dispatch<SetStateAction<string>>;
  openBulkProgressDialog: boolean;
  isBulkProcessApiCalling: boolean;
  progressData: ProgressDataProps;
  progressErrorMessage: string;
  processStartTime: string;
  timeDifference: number;
  setProgressData: Dispatch<SetStateAction<ProgressDataProps>>;
  setTimeDifference: Dispatch<SetStateAction<number>>;
};
type Props = {
  setIsBulkActionSuccess: Dispatch<SetStateAction<boolean>>;
};

const useBulkActionSetup = ({
  setIsBulkActionSuccess,
}: Props): useBulkActionSetup => {
  const { t } = useSwitchLocaleLanguage();
  const { displayRedirectDialog } = useRedirectDialog();
  const { displaySnackbar } = useSnackbar();

  const [initialTime, setIntialTime] = useState<Date | null>(null);
  const [processStartTime, setProcessStartTime] = useState<string>('');
  const [timeDifference, setTimeDifference] = useState(0);

  const [progressEventMessage, setprogressEventMessage] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [progressData, setProgressData] = useState<ProgressDataProps>({
    percentage: 0,
    groupCount: 0,
    deviceCount: 0,
  });
  const [progressErrorMessage, setProgressErrorMessage] = useState<string>('');
  const [isBulkProcessApiCalling, setIsBulkProcessApiCalling] =
    useState<boolean>(false);

  const [openBulkProgressDialog, setOpenBulkProgressDialog] = useState(false);

  const onClickBulkActionOverwrite = (
    requestParams: BulkActionSetupApiRequestParam,
  ): void => {
    setIsBulkProcessApiCalling(true);

    setIntialTime(new Date());
    const hours = String(new Date().getUTCHours()).padStart(2, '0');
    const minutes = String(new Date().getUTCMinutes()).padStart(2, '0');
    setProcessStartTime(`${hours}:${minutes}`);

    callApiUtil
      .post(API_URL.PROCESS_BULK_ACTION, requestParams, {
        onDownloadProgress: (progressEvent) => {
          const inputString = progressEvent.target.response;
          const parts = inputString.split(',');
          const secondLastPart = parts[parts.length - 2];
          const lastPart = parts[parts.length - 1];

          if (
            lastPart !== '[SUCCESS]' &&
            lastPart !== '[FAILED]' &&
            lastPart !== '[FAILED_TIMEOUT]'
          ) {
            const matches = lastPart.match(
              /\[PERCENTAGE=(\d+)&GROUP_COUNT=(\d+)&DEVICE_COUNT=(\d+)\]/,
            );
            if (matches) {
              const percentage = Number(matches[1]);
              const groupCount = Number(matches[2]);
              const deviceCount = Number(matches[3]);
              setProgressData({ percentage, groupCount, deviceCount });
            }
          } else if (
            lastPart === '[SUCCESS]' ||
            lastPart === '[FAILED]' ||
            lastPart === '[FAILED_TIMEOUT]'
          ) {
            setprogressEventMessage(lastPart as string);
            if (lastPart === '[SUCCESS]') {
              const matches = secondLastPart.match(
                /\[PERCENTAGE=(\d+)&GROUP_COUNT=(\d+)&DEVICE_COUNT=(\d+)\]/,
              );
              if (matches) {
                const percentage = Number(matches[1]);
                const groupCount = Number(matches[2]);
                const deviceCount = Number(matches[3]);
                setProgressData({ percentage, groupCount, deviceCount });
              }
            }
          }
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.message !== undefined) {
          setResponseMessage(data.message as string);
        } else {
          setResponseMessage('undefined');
        }
      })
      .catch((error) => {
        console.error(error);
        setResponseMessage(error.message as string);
      })
      .finally(() => {
        setIntialTime(null);
        setIsBulkProcessApiCalling(false);
      });
  };

  useEffect(() => {
    if (!openBulkProgressDialog) {
      return;
    }
    if (progressEventMessage) {
      if (progressEventMessage === '[SUCCESS]') {
        setTimeout(() => {
          setIsBulkActionSuccess(true);
          displaySnackbar({
            message: t('bulkActionProgressApiResponse.success'),
          });
        }, 1000);
      } else if (progressEventMessage === '[FAILED]') {
        setProgressErrorMessage(t('bulkActionProgressApiResponse.failed'));
      } else {
        setProgressErrorMessage(t('bulkActionProgressApiResponse.timeout'));
      }
    } else if (responseMessage.length) {
      if (
        responseMessage !== SERVER_MESSAGE.USER_ROLE_CHANGED &&
        responseMessage !== SERVER_MESSAGE.REFRESH_SUCCESS &&
        responseMessage !== SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED &&
        responseMessage !== SERVER_MESSAGE.USER_CONSENT_REQUIRED &&
        responseMessage !== SERVER_MESSAGE.INVALID_TOKEN &&
        responseMessage !== SERVER_MESSAGE.EXPIRED_TOKEN
      )
        if (responseMessage === SERVER_MESSAGE.INFO_NO_ACCOUNT) {
          displayRedirectDialog({
            open: true,
            title: t('common.error.notAuthorized'),
            message: t('common.error.unauthorizedAccess'),
          });
        } else if (responseMessage === SERVER_MESSAGE.WARN_INPUT_PARAM) {
          setProgressErrorMessage(t('bulkActionProgressApiResponse.failed'));
        } else {
          setProgressErrorMessage(
            t('bulkActionProgressApiResponse.serverStopped'),
          );
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressEventMessage, responseMessage]);

  useEffect(() => {
    if (!initialTime) return;

    const interval = setInterval(() => {
      const now = new Date();
      if (initialTime instanceof Date) {
        const diffInSeconds = Math.floor(
          (now.getTime() - initialTime.getTime()) / 1000,
        );
        setTimeDifference(diffInSeconds);
      }
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [initialTime]);

  return {
    onClickBulkActionOverwrite,
    setIsBulkProcessApiCalling,
    setOpenBulkProgressDialog,
    setprogressEventMessage,
    setResponseMessage,
    setProgressErrorMessage,
    openBulkProgressDialog,
    isBulkProcessApiCalling,
    progressData,
    progressErrorMessage,
    processStartTime,
    timeDifference,
    setProgressData,
    setTimeDifference,
  };
};

export default useBulkActionSetup;
