/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useAccountInfo from './useAccountInfo';
import { URL_PARAMETER_KEY } from '../constants/constants';

/**
 * 本カスタムフックからの返却値
 */
export type UseLocaleValue = {
  // 現在の言語
  localeLanguage: string;
  browserLanguage: string;
};

/**
 * ロケール(言語)コード
 * ※サーバ側と仕様を合わせる
 */
export const LOCALE_CODE = {
  // 日本語
  JA: 'ja',
  // 英語
  EN: 'en',
  // ENUS: 'en-US',
  // ENGB: 'en-GB',
  // German
  DE: 'de',
  // French
  FR: 'fr',
} as const;

const LOCALE_CODE_ARRAY = [
  'ja',
  'en',
  // 'en-US',
  // 'en-GB',
  'de',
  'de-DE',
  'fr-FR',
  'fr',
];
/**
 * ロケール(言語)切り替え hooks
 *
 * @returns
 */
const useLocale = (): UseLocaleValue => {
  // Acquired LOCALE stored from web browser's (for granting URL parameters when screen transition)
  const browserLanguage = LOCALE_CODE_ARRAY.includes(navigator.language)
    ? navigator.language === 'de-DE'
      ? 'de'
      : navigator.language === 'fr-FR'
      ? 'fr'
      : navigator.language
    : LOCALE_CODE.EN;

  const location = useLocation();

  // Store(Redux)に保存されているアカウント情報を取得
  const { accountInfo } = useAccountInfo();

  const localeLanguage = useMemo(() => {
    // Store(Redux)に言語が保存されている場合はそのまま返却
    const savedLocaleFromStore: string = accountInfo.locale;
    if (savedLocaleFromStore) {
      return savedLocaleFromStore;
    }

    // 保存されていない場合は、URLパラメータから言語を取得して返却
    const urlSearchParams = new URLSearchParams(location.search);
    const localeValueFromUrlParam: string | null = urlSearchParams.get(
      URL_PARAMETER_KEY.locale,
    );
    if (localeValueFromUrlParam) {
      return localeValueFromUrlParam;
    }

    // If no locale is found in the Redux store or URL parameters, use the browser's language
    return browserLanguage;
  }, [accountInfo.locale, location.search, browserLanguage]);

  return {
    localeLanguage,
    browserLanguage,
  };
};

export default useLocale;
