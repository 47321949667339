/* eslint-disable import/prefer-default-export */
import callGroupListApi, {
  // GROUP_LIST_API_ERROR_TYPE,
  GROUP_LIST_API_RESULT_CODE,
  TableDataPayload,
} from '../../apis/callGroupListApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../../constants/constants';
import { isErrorType } from '../../types/apis/apiErrorTypeGuard';
// import { isGroupListErrorType } from '../../types/apis/apiErrorTypeGuard';
import { GroupListApiResponse } from '../../types/apis/groupListApi.d';
import { GroupInfo, GroupListResult } from '../../types/Group/groupList.d';

export const fetchGroupList = async (
  // accessKey: string,
  tableDataPayload: TableDataPayload,
): Promise<GroupListResult> => {
  // User list acquisition API call
  try {
    const apiResponse: GroupListApiResponse = await callGroupListApi(
      // accessKey,
      tableDataPayload,
    );
    const groupsListFormatted: GroupInfo[] = apiResponse?.details?.groups?.map(
      (group) => {
        const newObject = {
          groupName: group.groupName,
          groupId: group.groupId,
          corpId: group.corpId,
          groupCode: group.groupCode,
          numberOfAccount: group.numberOfAccount,
          numberOfDevice: group.numberOfDevice,
          modifiedPerson: group.modifiedPerson,
          modifiedDt: group.modifiedDt,
          statusOfRemoteSettings: {
            wifiSetting: group.wifiSetting,
            featureLimit: group.featureLimit,
            dataProtection: group.dataProtection,
            saveTransHistory: group.saveTransHistory,
          },
        };

        return newObject;
      },
    );
    switch (apiResponse.message) {
      // success
      case GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED:
        return {
          resultCode: GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED,
          groupList: groupsListFormatted,
          totalCount: apiResponse.details.totalCount,
        };
      // Failure
      case GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA:
        throw GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA;
      case GROUP_LIST_API_RESULT_CODE.AUTH_NOT_ALLOWED:
        throw GROUP_LIST_API_RESULT_CODE.AUTH_NOT_ALLOWED;
      case GROUP_LIST_API_RESULT_CODE.WARN_INVALID_AUTH:
        throw GROUP_LIST_API_RESULT_CODE.WARN_INVALID_AUTH;
      case SERVER_MESSAGE.INVALID_TOKEN:
        throw SERVER_MESSAGE.INVALID_TOKEN;
      case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
        throw SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED;
      case SERVER_MESSAGE.USER_ROLE_CHANGED:
        throw SERVER_MESSAGE.USER_ROLE_CHANGED;
      case SERVER_MESSAGE.NO_INTERNET:
        throw SERVER_MESSAGE.NO_INTERNET;
      default:
        throw GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN; // WARN_NO_AUTH_LIST, treats everything as ERR_UNKNOWN except for login authentication errors
    }
  } catch (error: ALL_ERROR_TYPE | unknown) {
    let resultCode: ALL_ERROR_TYPE = GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN;
    if (isErrorType(error)) {
      resultCode = error;
    }

    return {
      resultCode,
      groupList: [],
      totalCount: 0,
    };
  }
};
